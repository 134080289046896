const VideoPlayer = ({ link }) => {
  return (
    <div>
      <video
        controls
        width="100%"
        autoPlay
        poster="https://ikids.uz/defaultVideo.png"
        src={link}
      />
    </div>
  );
};

export default VideoPlayer;
