import types from './verificationTypes';

export const getVerificationRequestsStart = () => ({
  type: types.GET_VERIFICATION_REQUESTS_START,
});

export const getVerificationRequestsSuccess = (data = {}) => ({
  type: types.GET_VERIFICATION_REQUESTS_SUCCESS,
  payload: data,
});

export const getVerificationRequestsError = (error = '') => ({
  type: types.GET_VERIFICATION_REQUESTS_ERROR,
  payload: error,
});

export const getScreensForApproveStart = () => ({
  type: types.GET_SCREENS_FOR_APPROVE_START,
});

export const getScreensForApproveSuccess = (data = {}) => ({
  type: types.GET_SCREENS_FOR_APPROVE_SUCCESS,
  payload: data,
});

export const getScreensForApproveError = (error = '') => ({
  type: types.GET_SCREENS_FOR_APPROVE_ERROR,
  payload: error,
});

export const getScreensForApproveByIdStart = () => ({
  type: types.GET_SCREENS_FOR_APPROVE_BY_ID_START,
});

export const getScreensForApproveByIdSuccess = (data = {}) => ({
  type: types.GET_SCREENS_FOR_APPROVE_BY_ID_SUCCESS,
  payload: data,
});

export const getScreensForApproveByIdError = (error = '') => ({
  type: types.GET_SCREENS_FOR_APPROVE_BY_ID_ERROR,
  payload: error,
});

export const addDataToVerificationFormObject = data => ({
  type: types.ADD_DATA_TO_VERIFICATION_FORM_OBJECT,
  payload: data,
});

export const sendVerificationFormStart = data => ({
  type: types.SEND_VERIFICATION_FORM_START,
  payload: data,
});

export const sendVerificationFormSuccess = data => ({
  type: types.SEND_VERIFICATION_FORM_SUCCESS,
  payload: data,
});

export const sendVerificationFormError = data => ({
  type: types.SEND_VERIFICATION_FORM_ERROR,
  payload: data,
});

export const rejectVerificationFormStart = data => ({
  type: types.REJECT_VERIFICATION_FORM_START,
});

export const rejectVerificationFormSuccess = data => ({
  type: types.REJECT_VERIFICATION_FORM_SUCCESS,
});

export const rejectVerificationFormError = data => ({
  type: types.REJECT_VERIFICATION_FORM_ERROR,
});

export const sendVerifyRequestStatusStart = () => ({
  type: types.SEND_VERIFY_REQUEST_STATUS_START,
});

export const sendVerifyRequestStatusSuccess = data => ({
  type: types.SEND_VERIFY_REQUEST_STATUS_SUCCESS,
  payload: data,
});

export const sendVerifyRequestStatusError = error => ({
  type: types.SEND_VERIFY_REQUEST_STATUS_ERROR,
  payload: error,
});

export const changeVerificationFormStatus = status => ({
  type: types.CHANGE_VERIFICATION_FORM_STATUS,
  payload: status,
});

export const clearVerificationFormData = () => ({
  type: types.CLEAR_VERIFICATION_FORM_DATA,
});
