import {
  editModeration,
  getListModerators,
  getUser,
  registrationModeration,
  removeModeration,
} from '../../../api/createModerator-servise/createModerator-servise';
import {
  dataFormat,
  normalizeDataInfoUser,
} from '../../../utilites/globalUtils';
import {
  saveModeratorItem,
  saveModeratorList,
  setErrorModeratorItem,
} from './moderatorAction';
import { errorHandler } from '../notify/notifyOperations';
import { setNotifyStatus } from '../notify/notifyActions';
import { changeConfirmStatus } from '../confirm/confirmActions';
import {
  changeButtonLoaderStatus,
  changeGlobalLoaderStatus,
} from '../loader/loaderActions';

export const getModerators =
  (limit = 10, offset = 0, sort = '') =>
  dispatch => {
    dispatch(changeGlobalLoaderStatus(true));
    getListModerators(limit, offset, sort)
      .then(({ items, count }) => {
        const buffer = items.map(
          ({ firstName, lastName, createdAt, email, _id }) => {
            return {
              view: {
                firstName,
                lastName,
                email,
                createdAt: dataFormat(createdAt),
              },
              id: _id,
            };
          },
        );
        dispatch(saveModeratorList({ items: buffer, count }));
      })
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  };
export const getModeratorItem = id => dispatch => {
  dispatch(changeGlobalLoaderStatus(true));
  getUser(id)
    .then(r => {
      dispatch(setErrorModeratorItem(false));
      dispatch(saveModeratorItem(normalizeDataInfoUser(r)));
    })
    .catch(() => {
      dispatch(setErrorModeratorItem(true));
    })
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};
export const removeModerator = id => dispatch => {
  removeModeration(id)
    .then(() => {
      dispatch(getModerators());
      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Remove moderator`,
        }),
      );
    })
    .catch(error => {
      dispatch(errorHandler(error.message));
    });
};
export const addedNewModerator = body => dispatch => {
  dispatch(changeButtonLoaderStatus(true));
  registrationModeration({ ...body, role: 'MODERATOR' })
    .then(() => {
      dispatch(getModerators());
      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Created new moderator successfully`,
        }),
      );
      dispatch(changeConfirmStatus({ isVisible: false, type: null }));
    })
    .catch(error => {
      dispatch(errorHandler(error.message));
    })
    .finally(() => dispatch(changeButtonLoaderStatus(false)));
};
export const moderatorEdit = (id, body) => dispatch => {
  dispatch(changeButtonLoaderStatus(true));
  editModeration(id, body)
    .then(() => {
      dispatch(getModerators());
      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Edited moderator successfully `,
        }),
      );
      dispatch(changeConfirmStatus({ isVisible: false, type: null }));
    })
    .catch(error => {
      dispatch(errorHandler(error.message));
    })
    .finally(() => dispatch(changeButtonLoaderStatus(false)));
};
