import React   from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { publicRoutes } from './config';
import routeBook from '../../constants/routeBook';
import { useGetUserRole } from '../../customHooks/getUserRole';

const Navigation = () => {
  const userRole = useGetUserRole();
  const checkingAccessUser = (role) => (
    role.some((item) => item === userRole)
  );
  return <Switch>
    {publicRoutes.map(({ path, component, isExact, role }) => (
      checkingAccessUser(role) ? <Route key={path} path={path} exact={isExact} component={component} /> : null
    ))}

    <Redirect to={routeBook.notFound} />
  </Switch>;
};

export default Navigation;
