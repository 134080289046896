import { Button, Box, Container } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import BloggerCard from '../../../components/BloggerCard/BloggerCard';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';
import React from 'react';

const AccountStatistics = ({ history, classes }) => {
  return (
    <Container maxWidth="lg"  style={{overflow:'auto'}}>
      <Box p={4}>
        <Box className={classes.innerContainer}>
          <Button
            onClick={() => history.goBack()}
            color="primary"
            size="medium"
          >
            <KeyboardBackspaceIcon style={{ margin: '0 10px 0 0' }} /> Return to
            ad review
          </Button>
        </Box>
        <Box display="flex" justifyContent="center">
          <BloggerCard />
        </Box>
      </Box>
    </Container>
  );
};

export default withStyles(styles)(AccountStatistics);
