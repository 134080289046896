export const infoStatistiksItem = {
  countOfInstagramVerifyAccountAndStatistics: {
    pushTo: '/verification',
    name: 'Count of Instagram verify account and statistics',
  },
  countOfNewRequestForContentApprove: {
    pushTo: '/item/0',
    name: 'Count of new request for content approval',
  },
  countOfRequestForWithdrawalMoney: {
    pushTo: '/transactions',
    name: 'Count of request for withdrawal money',
  },
  countOfRequestOffersForReview: {
    pushTo: '/item/2',
    name: 'Count of request offers for review',
  },
};