import React from 'react';
import { useHistory } from 'react-router-dom';


const ComponentInfoUser = ({ list, error }) => {
  const history = useHistory();
  return (
    <div className='advertiser-page'>
      {error ? <p className={'error_page_connect'}>Page not found </p> : <>
        <div className='advertiser-page-head'>
          <button className='advertiser-page-back' onClick={() => {
            history.goBack();
          }}>
            <svg className='MuiSvgIcon-root ContentVerification-buttonIcon-49' focusable='false' viewBox='0 0 24 24'
                 aria-hidden='true'>
              <path d='M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z' />
            </svg>
            Return to Tables
          </button>

        </div>
        <div className='advertiser-page__container'>
          {list?.map(({text,value,chaining},index) => (
            <div className='advertiser-page__item' key={chaining+index}>
              <p className='advertiser-page__item-description'>{text}</p>
              <p className='advertiser-page__item-value'>{value}</p>
            </div>
          ))}
        </div>
      </>}
    </div>
  );
};
export default ComponentInfoUser;