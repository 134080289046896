const styles = {
  buttonIcon: {
    marginRight: 10,
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '50px',
    marginTop: '50px',
  },
};

export default styles;
