const types = {
  GET_CAMPAIGNS_LIST_START: 'campaigns/GET_CAMPAIGNS_LIST_START',
  GET_CAMPAIGNS_LIST_SUCCESS: 'campaigns/GET_CAMPAIGNS_LIST_SUCCESS',
  GET_CAMPAIGNS_LIST_ERROR: 'campaigns/GET_CAMPAIGNS_LIST_ERROR',

  SEND_VERIFICATION_LIST_START: 'campaigns/SEND_VERIFICATION_FORM_START',
  SEND_VERIFICATION_LIST_SUCCESS: 'campaigns/SEND_VERIFICATION_FORM_SUCCESS',
  SEND_VERIFICATION_LIST_ERROR: 'campaigns/SEND_VERIFICATION_FORM_ERROR',

  GET_ADS_FOR_REVIEW_START:'campaigns/GET_ADS_FOR_REVIEW_START',
  GET_ADS_FOR_REVIEW_SUCCESS:'campaigns/GET_ADS_FOR_REVIEW_SUCCESS',
  GET_ADS_FOR_REVIEW_FORM_ERROR:'campaigns/GET_ADS_FOR_REVIEW_FORM_ERROR',

  GET_VERIFICATION_ITEM_START:'campaigns/GET_VERIFICATION_ITEM_SUCCESS',
  GET_VERIFICATION_ITEM_SUCCESS:'campaigns/GET_VERIFICATION_ITEM_SUCCESS',
  GET_VERIFICATION_ITEM_ERROR:'campaigns/GET_VERIFICATION_ITEM_SUCCESS',

  GET_REVIEW_ITEM_START:'campaigns/GET_REVIEW_ITEM_START',
  GET_REVIEW_ITEM_SUCCESS:'campaigns/GET_REVIEW_ITEM_SUCCESS',
  GET_REVIEW_ITEM_ERROR:'campaigns/GET_REVIEW_ITEM_ERROR',

  GET_ADS_FOR_OFFER_SUCCESS:'GET_ADS_FOR_OFFER_SUCCESS'
};

export default types;
