import types from '../blog/blogTypes';

export const writeBlogList = (value) => ({
  type: types.GET_LIST_BLOG,
  payload: value,
});
export const writeBlog=(value)=>({
  type:types.GET_ITEM_BLOG,
  payload:value
})
