import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';

import { overallFormSchema } from '../../../../../schema/verificationForms';

import { addDataToVerificationFormObject } from '../../../../../redux/store/verification/verificationOperations';
import { verificationFormSelector } from '../../../../../redux/selectors/verification';

import Input from '../../../../ui-core/Input/Input';

import styles from './styles';

const OverallForm = ({
                       classes,
                       addDataToVerificationFormObject,
                       defaultData,
                       goToNextStep
                     }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultData,
    resolver: yupResolver(overallFormSchema),
  });

  const onSubmit = data => {
    addDataToVerificationFormObject(data);
    goToNextStep(1)
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box style={{ padding: 16, backgroundColor: '#fff' }}>
        <Box m={2} item='true'>
          <Input
            type='number'
            control={control}
            name='audienceReach'
            label='Audience reach'
            defaultValue={''}
            error={errors?.audienceReach?.message}
          />
        </Box>
        <Box m={2} item='true'>
          <Input
            type='number'
            control={control}
            name='accountQuality'
            label='Account quality'
            defaultValue={''}
            error={errors?.accountQuality?.message}
          />
        </Box>
        <Box m={2} item='true'>
          <Input
            type='number'
            control={control}
            name='recommendedPrice'
            label='Recommended price'
            defaultValue={''}
            error={errors?.recommendedPrice?.message}
          />
        </Box>
        <Box m={2} item='true'>
          <Input
            type='number'
            control={control}
            name='storiesViews'
            label='Stories views'
            defaultValue={''}
            error={errors?.storiesViews?.message}
          />
        </Box>
        <Box
          m={2}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          item='true'
        >
          <Tooltip title='You must fill in all the fields to save'>
            <span>
              <Button
                disabled={!isValid}
                size='large'
                type='submit'
                variant='contained'
                color='primary'
              >
                <AddIcon />
                submit
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </form>
  );
};

const mapStateToProps = state => ({
  defaultData: verificationFormSelector(state),
});

const mapDispatchToProps = {
  addDataToVerificationFormObject,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(OverallForm));
