export const moderatorInfoTemplate = [
  {
    text: 'First name:',
    chaining: 'firstName',
    value: '',
  },
  {
    text: 'Last name:',
    chaining: 'lastName',
    value: '',
  },
  {
    text: 'Created date:',
    chaining: 'createdAt',
    value: '',
  },
  {
    text: 'Updated date:',
    chaining: 'createdAt',
    value: '',
  },
  {
    text: 'Email:',
    chaining: 'email',
    value: '',
  },
];
export const advertiserInfoItem=()=>[
  {
    text: 'First name:',
    chaining: 'firstName',
    value: '',
  },
  {
    text: 'Last name:',
    chaining: 'lastName',
    value: '',
  },
  {
    text: 'Email:',
    chaining: 'email',
    value: '',
  },
  {
    text: 'Favorite bloggers:',
    chaining: 'favoriteInstagramAccount',
    value: '',
  },
  {
    text: 'Is confirmed:',
    chaining: 'isConfirmed',
    value: '',
  },
  {
    text: 'Active advertisements:',
    chaining: 'activeCount',
    value: '',
  },
  {
    text: 'All advertisements:',
    chaining: 'count',
    value: '',
  },
  {
    text: 'Balance:',
    chaining: 'budget',
    value: '',
  },
  {
    text: 'Registration date:',
    chaining: 'createdAt',
    value: '',
  },
  {
    text: 'Update date:',
    chaining: 'updatedAt',
    value: '',
  },
]