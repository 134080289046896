import types from './moderatorTypes';

const initialState = {
  body: {
    items: [],
    count: 0,
  },
  moderationItem: [],
  errorItem:false,
};

const moderatorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_MODERATORS_LIST:
      return { ...state, body: payload };
    case types.GET_MODERATOR_ITEM:
      return { ...state, moderationItem: payload };
    case types.CLEAR_MODERATOR_ITEM:
      return { ...state, moderationItem: [] };
    case type.ERROR_INFO_ITEM:
      return {...state,errorItem:payload}
    default:
      return state;
  }
};

export default moderatorReducer;
