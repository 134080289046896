import types from './campaignsTypes';

export const getCampaignListSuccess = (data = {}) => ({
  type: types.GET_CAMPAIGNS_LIST_SUCCESS,
  payload: data,
});
export const getVerificationListSuccess = (data = {}) => ({
  type: types.SEND_VERIFICATION_LIST_SUCCESS,
  payload: data,
});
export const getAddForReviewListSuccess = (data = {}) => ({
  type: types.GET_ADS_FOR_REVIEW_SUCCESS,
  payload: data,
});
export const getAddForOfferListSuccess = (data = {}) => ({
  type: types.GET_ADS_FOR_OFFER_SUCCESS,
  payload: data,
});
export const getVerificationItemSuccess = (data = {}) => ({
  type: types.GET_VERIFICATION_ITEM_SUCCESS,
  payload: data,
});
export const setReviewItem=(data)=>({
  type:types.GET_REVIEW_ITEM_SUCCESS,
  payload:data,
})