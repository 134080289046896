import moment from 'moment';
import { moderatorInfoTemplate } from '../views/components/componentInfoUser/template';

export const dataFormat = (data) => {
  return moment(new Date(data)).format('lll');
};
export const onlyDataFormat = (data) => {
  return moment(new Date(data)).format('ll');
};
export const timeFormat = (data) => {
  return moment(new Date(data)).format('h:mm');
};

export const croppingText = (text, size = 15) => {
  if (text) {
    return text.length > size ? text.slice(0, size) + '...' : text;
  }
};
export const normalizeDataInfoUser = (data) => {
  return moderatorInfoTemplate.map((item) => {
    return { ...item, value: item.chaining==='createdAt'||item.chaining==='createdAt'?dataFormat(data[item.chaining]):data[item.chaining] };
  });
};