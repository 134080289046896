import { confirmationKeys } from '../../../constants/confirmationKeys';

import {
  sendVerifyRequestStatus,
  rejectVerificationForm,
} from '../../../redux/store/verification/verificationOperations';
import { changeConfirmStatus } from '../../../redux/store/confirm/confirmActions';
import { editStatus, verificationApprove, verificationReject } from '../../../redux/store/company/campaignsOperations';
import { removeBlog } from '../../../redux/store/blog/blogOperations';
import { removeModerator } from '../../../redux/store/moderator/moderatorOperation';
import { setPercent } from '../../../api/authentication-servise/authentication';
import { errorHandler } from '../../../redux/store/notify/notifyOperations';
import { setNotifyStatus } from '../../../redux/store/notify/notifyActions';

const initialStatus = { isVisible: false, type: null };

export const confirmationTypes = {
  [confirmationKeys.verificationAccountDeclined]: {
    action: (dispatch, params, valueText, history) => {
      dispatch(sendVerifyRequestStatus(...params));
      dispatch(changeConfirmStatus(initialStatus));
    },
    content: 'Do you really want to decline this account?',
    type: confirmationKeys.verificationAccountDeclined,
  },
  [confirmationKeys.verificationAccountVerified]: {
    action: (dispatch, params, valueText, history) => {
      dispatch(sendVerifyRequestStatus(...params));
      dispatch(changeConfirmStatus(initialStatus));
    },
    content: 'Do you really want to verify this account?',
    type: confirmationKeys.verificationAccountVerified,
  },
  [confirmationKeys.statisticsAccountDeclined]: {
    action: (dispatch, params, valueText, history) => {
      dispatch(rejectVerificationForm(...params));
      dispatch(changeConfirmStatus(initialStatus));
    },
    confirm: () => {
    },
    content: 'Do you really want to reject this verification request?',
    type: confirmationKeys.statisticsAccountDeclined,
  },
  [confirmationKeys.cancellationOfAdRequest]: {
    action: (dispatch, params, text, history, getError) => {
      if (text.length > 1) {
        dispatch(verificationReject(params.id, text));
        dispatch(changeConfirmStatus({ isVisible: false, type: null }));
        history.push(`/item/0`);
      }
      getError('must be reason want to cancel');
    },
    confirm: (params) => {
    },
    content: 'Do you really want to cancel this ad?',
    type: confirmationKeys.cancellationOfAdRequest,
  },
  [confirmationKeys.AdRequestVerified]: {
    action: (dispatch, params, text, history) => {
      dispatch(verificationApprove(params.id));
      dispatch(changeConfirmStatus({ isVisible: false, type: null }));
      history.push(`/item/0`);
    },
    confirm: () => {
    },
    content: 'Do you really want to verify this add?',
    type: confirmationKeys.AdRequestVerified,
  },
  [confirmationKeys.AdsReviewVerified]: {
    action: (dispatch, params, text, history) => {
      dispatch(editStatus(params.id, {
        status: 'COMPLETED',
        storyViews: parseInt(params.value.storyViews),
        linkFollowUps: parseInt(params.value.linkFollowUps),
      }));
      history.push(`/item/2`);
      dispatch(changeConfirmStatus({ isVisible: false, type: null }));

    },
    confirm: () => {
    },
    content: 'Do you really want to verify this review request?',
    type: confirmationKeys.AdRequestVerified,
  },
  [confirmationKeys.cancellationOfAdReviewVerified]: {
    action: (dispatch, params, text, history, getError) => {
      if (text.length > 1) {
        const body = text
          ? {
            status: 'UNRESOLVED', comment: text, storyViews: parseInt(params.value.storyViews),
            linkFollowUps: parseInt(params.value.linkFollowUps),
          }
          : { status: 'UNRESOLVED' };
        dispatch(editStatus(params.id, body));
        history.push(`/item/2`);
        dispatch(changeConfirmStatus({ isVisible: false, type: null }));
      }
      getError('must be reason want to cancel');
    },
    confirm: () => {
    },
    content: 'Do you realy want to decline this review request?',
    type: confirmationKeys.cancellationOfAdReviewVerified,
  },


  [confirmationKeys.deleteBlog]: {
    action: (dispatch, params, valueText, history) => {
      dispatch(removeBlog(params[0]));
      dispatch(changeConfirmStatus({ isVisible: false, type: null }));

    },
    content: 'Do you really want to remove this blog?',
    type: confirmationKeys.verificationAccountDeclined,
  },
  [confirmationKeys.infoBlogger]: {
    type: confirmationKeys.infoBlogger,
  },

  [confirmationKeys.advertiserBan]: {
    action: (dispatch, params, valueText, history) => {


    },
    content: 'Do you really want to ban this advertiser?',
    type: confirmationKeys.verificationAccountDeclined,
  },

  [confirmationKeys.setPercent]: {
    action: (dispatch, params, valueText, history) => {
      setPercent(params).then(e => {
        params.updateData()
        dispatch(
          setNotifyStatus({
            type: 'success',
            content: `Update percent success`,
          }),
        );
      }).catch(({ message }) => {
        dispatch(errorHandler(message));
      });
      dispatch(changeConfirmStatus({ isVisible: false, type: null }));
    },
    content: 'Do you really want to update percent ?',
    type: confirmationKeys.verificationAccountDeclined,
  },

  [confirmationKeys.advertiserUnbanned]: {
    action: (dispatch, params, valueText, history) => {


    },
    content: 'Do you really want to unbanned this advertiser?',
    type: confirmationKeys.verificationAccountDeclined,
  },
  [confirmationKeys.approveWithdrawal]: {
    action: (dispatch, params, valueText, history) => {
      params.approveWithdrawal(params.id);
      dispatch(changeConfirmStatus({ isVisible: false, type: null }));

    },
    content: 'Do you really want to approve withdrawal ?',
    type: confirmationKeys.approveWithdrawal,
  },
  [confirmationKeys.rejectedWithdrawal]: {
    action: (dispatch, params, valueText, history) => {
      params.rejectedWithdrawal(params.id);
      dispatch(changeConfirmStatus({ isVisible: false, type: null }));

    },
    content: 'Do you really want to rejected withdrawal ?',
    type: confirmationKeys.rejectedWithdrawal,
  },
  [confirmationKeys.removeModerator]: {
    action: (dispatch, params, valueText, history) => {
      dispatch(removeModerator(params.id));
      dispatch(changeConfirmStatus({ isVisible: false, type: null }));

    },
    content: 'Do you really want to remove moderator?',
    type: confirmationKeys.rejectedWithdrawal,
  },
  [confirmationKeys.createModerator]: {
    type: confirmationKeys.createModerator,
  },
};

export const listOfConfirmationTypes = [
  confirmationKeys.cancellationOfAdReviewVerified,
  confirmationKeys.cancellationOfAdRequest,
];
