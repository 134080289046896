import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';

import { notifyStatusSelector } from '../../../redux/selectors/selectors';

import { handleNotificationRedirect } from '../../../helpers/firebase/handleNotificationRedirect';

function Notistack() {
  const status = useSelector(notifyStatusSelector);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    const changeSnackbarStatus = information => {
      const { type, content, notification } = information;

      if (type === 'success') {
        return enqueueSnackbar(`${content}`, { variant: 'success' }, {});
      }

      if (type === 'error') {
        return enqueueSnackbar(`${content}`, { variant: 'error' });
      }

      if (type === 'warning') {
        return enqueueSnackbar(`${content}`, { variant: 'warning' });
      }

      if (type === 'info') {
        return enqueueSnackbar(`${notification?.data?.title}`, {
          variant: 'info',
          action: (
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={() =>
                closeSnackbar(
                  handleNotificationRedirect(
                    notification?.data?.title,
                    history,
                  ),
                )
              }
            >
              Open Page
            </Button>
          ),
        });
      }
    };
    changeSnackbarStatus(status);
  }, [status, enqueueSnackbar, closeSnackbar, history]);

  return <></>;
}
export default function IntegrationNotistack(props) {
  const status = useSelector(notifyStatusSelector);

  const useStyles = makeStyles(() => ({
    contained: {
      backgroundColor: '#332256',
      color: 'white',
    },
  }));
  const classes = useStyles();

  const [imageURL, setImageURL] = useState('');

  useEffect(() => {
    const changeSnackbarStatus = information => {
      const { notification } = information;
      setImageURL(notification?.data?.imageURL);
    };
    changeSnackbarStatus(status);
  }, [status, imageURL]);

  return (
    <SnackbarProvider
      classes={{
        variantInfo: classes.contained,
      }}
      iconVariant={{
        info: (
          <img
            style={{ marginRight: '8px', width: '40px', height: '40px' }}
            src={imageURL}
            alt="notification"
          />
        ),
      }}
      anchorOrigin={{
        vertical: 'right',
        horizontal: 'bottom',
      }}
      maxSnack={6}
    >
      <Notistack />
    </SnackbarProvider>
  );
}
