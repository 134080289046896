import routeBook from '../../constants/routeBook';
import { notificationsMessages } from './notificationsMessages';

export const handleNotificationRedirect = (title, history) => {
  if (title === notificationsMessages[0]) {
    history.push(routeBook.verification);
  } else if (title === notificationsMessages[1]) {
    history.push(routeBook.verification);
  } else if (title === notificationsMessages[2]) {
    history.push('/item/0');
  } else if (title === notificationsMessages[3]) {
    history.push('/item/2');
  } else if (title === notificationsMessages[4]) {
    history.push(routeBook.withdrawal);
  }
};
