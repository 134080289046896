import {store} from '../redux/store/store';
import { logoutUser } from '../redux/store/authentication/authenticationActions';

const jsonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const formDataHeaders = {
  accept: '*/*',
};

const defautCustomOptions = {
  includeAuthHeader: true,
  includeCredentials: true,
};

export async function post(
  path,
  body = {},
  headers = {},
  options = {},
  customOptions = defautCustomOptions,
) {
  const request = await fetchEnriched(
    'POST',
    path,
    JSON.stringify(body),
    getHeaders(jsonHeaders, headers),
    options,
    customOptions,
  );
  checkingStatus(request.status);
  try {
    if (!request.ok) {
      const err = await request.json();
      throw Error(err.error);
    }

    return request.json();
  } catch (err) {
    throw err;
  }
}

export async function postFormData(
  path,
  body = {},
  headers = {},
  options = {},
  customOptions = defautCustomOptions,
) {
  const request = await fetchEnriched(
    'POST',
    path,
    body,
    getHeaders(formDataHeaders, headers),
    options,
    customOptions,
  );
  checkingStatus(request.status);
  try {
    if (!request.ok) {
      const err = await request.json();
      throw Error(err.error);
    }
    return request.json();
  } catch (err) {
    throw err;
  }
}

export async function putFormData(
  path,
  body = {},
  headers = {},
  options = {},
  customOptions = defautCustomOptions,
) {
  const request = await fetchEnriched(
    'PUT',
    path,
    body,
    getHeaders(formDataHeaders, headers),
    options,
    customOptions,
  );
  checkingStatus(request.status);

  try {
    if (!request.ok) {
      const err = await request.json();
      throw Error(err.error);
    } else {
      if (typeof request === 'string') {
        return request.json();
      } else {
        return request.json();
      }
    }
  } catch (err) {
    throw err;
  }
}

export async function get(
  path,
  body = {},
  headers = {},
  options = {},
  customOptions = defautCustomOptions,
) {
  const request = await fetchEnriched(
    'GET',
    path,
    null,
    getHeaders(jsonHeaders, headers),
    options,
    customOptions,
  );
  checkingStatus(request.status);
  try {
    if (!request.ok) {
      const err = await request.json();
      throw Error(err.error);
    }
    return request.json();
  } catch (err) {
    throw err;
  }
}

export async function patch(
  path,
  body = {},
  headers = {},
  options = {},
  customOptions = defautCustomOptions,
) {
  const request = await fetchEnriched(
    'PATCH',
    path,
    JSON.stringify(body),
    getHeaders(jsonHeaders, headers),
    options,
    customOptions,
  );
  checkingStatus(request.status);
  try {
    if (!request.ok) {
      const err = await request.json();
      throw Error(err.error);
    }
    return request.json();
  } catch (err) {
    throw err;
  }
}

export async function put(
  path,
  body = {},
  headers = {},
  options = {},
  customOptions = defautCustomOptions,
) {
  const request = await fetchEnriched(
    'PUT',
    path,
    JSON.stringify(body),
    getHeaders(jsonHeaders, headers),
    options,
    customOptions,
  );
  checkingStatus(request.status);

  try {
    if (!request.ok) {
      const err = await request.json();
      throw Error(err.error);
    }
    return request.json();
  } catch (err) {
    throw err;
  }
}

export async function remove(
  path,
  body = {},
  headers = {},
  options = {},
  customOptions = defautCustomOptions,
) {
  const request = await fetchEnriched(
    'DELETE',
    path,
    null,
    getHeaders(jsonHeaders, headers),
    options,
    customOptions,
  );
  checkingStatus(request.status);

  try {
    if (!request.ok) {
      const err = await request.json();
      throw Error(err.error);
    }
    return true;
  } catch (err) {
    throw err;
  }
}

function fetchEnriched(method, path, body, headers, options) {
  const endpointUrl = getApiUrl(path);
  addAuthHeader(headers);
  return fetch(endpointUrl, {
    method: method,
    headers,
    body: body,
    ...options,
  });
}
export function getApiUrlImage(path) {
  console.log("XYT")
  const environment = process.env.NODE_ENV;
  const developmentLink = 'https://backend-dev.bloggerly.lampawork.com';
  const productionLink = 'https://backend-dev.bloggerly.lampawork.com';
  const baseLink =
    environment === 'production' ? productionLink : developmentLink;

  return baseLink+ path;
}
function getApiUrl(path) {
  const environment = process.env.REACT_APP_ENV;
  const developmentLink = 'https://backend-dev.bloggerly.lampawork.com/api/v1';
  const productionLink = 'https://backend.bloggerly.io/api/v1';

  //const developmentLink = 'http://localhost:3011/api/v1'
  //const productionLink = 'http://localhost:3011/api/v1'
  const baseLink =
    environment === 'production' ? productionLink : developmentLink;

  return baseLink+ path;
}

function getHeaders(defaultHeaders, headers) {
  return {
    ...defaultHeaders,
    ...headers,
  };
}

function addAuthHeader(headers) {
  const { authentication } =store.getState()
  headers['Authorization'] =`Bearer ${authentication.token}`
}


const checkingStatus = (status) => {
  if (status === 401) {
    localStorage.removeItem('userInfo');
    store.dispatch(logoutUser());
  }
};