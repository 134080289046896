const styles = {
  input: {
    display: 'none',
  },
  button: {
    margin: 10,
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

export default styles;
