export const HEAD_CELLS = {
  CAMPAIGNS_LIST: [
    {
      id: 'dateOfCreation',
      numeric: false,
      disablePadding: true,
      label: 'Start date',
      isActiveSort: true,
      sortBy: '&sortBy=createDate',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    }, {
      id: 'dateOfCreation',
      numeric: false,
      disablePadding: true,
      label: 'Name',

      isActiveSort: true,
      sortBy: '&sortBy=name',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,

    }, {
      id: 'dateOfCreation',
      numeric: false,
      disablePadding: true,
      label: 'Email',

      isActiveSort: true,
      sortBy: '&sortBy=email',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'dateOfCreation',
      numeric: false,
      disablePadding: true,
      label: 'Id',
      isActiveSort: true,
      sortBy: '&sortBy=publicId',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },

  ],

  VERIFICATION_LIST: [
    {
      id: 'dateOfCreation',
      numeric: false,
      disablePadding: true,
      label: 'Date of creation',
      isActiveSort: true,
      sortBy: '&sortBy=createDate',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    }, {
      id: 'dateOfCreation',
      numeric: false,
      disablePadding: true,
      label: 'Date of update',
      isActiveSort: true,
      sortBy: '&sortBy=updateDate',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    }, {
      id: 'dateOfCreation',
      numeric: false,
      disablePadding: true,
      label: 'Name',

      isActiveSort: true,
      sortBy: '&sortBy=name',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'dateOfCreation',
      numeric: false,
      disablePadding: true,
      label: 'Id',
      isActiveSort: true,
      sortBy: '&sortBy=publicId',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    
    {
      id: 'dateOfCreation',
      numeric: false,
      disablePadding: true,
      label: 'Email',
      isActiveSort: true,
      sortBy: '&sortBy=email',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
  ],
  ADS_REVIEW_LIST: [
    {
      id: 'dateOfApprove',
      numeric: false,
      disablePadding: true,
      label: 'End date',
      isActiveSort: true,
      sortBy: '&sortBy=startDate',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    }, {
      id: 'ad_id',
      numeric: false,
      disablePadding: true,
      label: 'Ad Id',
      isActiveSort: true,
      sortBy: '&sortBy=publicId',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,

    },
    {
      id: 'title',
      numeric: false,
      disablePadding: true,
      label: 'Title',
      isActiveSort: true,
      sortBy: '&sortBy=title',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'statusNumber',
      numeric: false,
      disablePadding: false,
      label: () => (
        <b>
          <span style={{ color: '#000' }}>Active</span>
        </b>),

      isActiveSort: true,
      sortBy: '&sortBy=offerActive',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,

    },
    {
      id: 'statusNumber',
      numeric: false,
      disablePadding: false,
      label: () => (
        <b>
          <span style={{ color: '#008000' }}>On moderation</span>
        </b>),

      isActiveSort: true,
      sortBy: '&sortBy=offerModeration',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,

    },
    {
      id: 'statusNumber',
      numeric: false,
      disablePadding: false,
      label: () => (
        <b>
          <span style={{ color: '#ffac12' }}> Unresolved</span>
        </b>),

      isActiveSort: true,
      sortBy: '&sortBy=offerUnresolved',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,

    },
    {
      id: 'statusNumber',
      numeric: false,
      disablePadding: false,
      label: () => (
        <b>
          <span style={{ color: '#f11' }}> Failed</span>
        </b>),
      isActiveSort: true,
      sortBy: '&sortBy=offerFailed',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,


    },
    {
      id: 'statusNumber',
      numeric: false,
      disablePadding: false,
      label: () => (
        <b>
          <span style={{ color: '#000' }}>Completed</span>
        </b>),

      isActiveSort: true,
      sortBy: '&sortBy=offerComplete',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,

    },

  ],
  ADS_REVIEW_COMPANY_LIST: [
    {
      id: 'userEmail',
      numeric: false,
      disablePadding: true,
      label: 'User',
    }, {
      id: 'blogger',
      numeric: false,
      disablePadding: true,
      label: 'Blogger (Instagram)',
    },
    {
      id: 'oferId',
      numeric: false,
      disablePadding: true,
      label: 'Offer ID',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
    },
  ],
  ACCOUNTS_VERIFICATION: [
    {
      id: 'verifiedCode',
      numeric: true,
      disablePadding: false,
      label: 'Verification code',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      isActiveSort: true,
      sortBy: '&sortBy=name',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'dateOfCreation',
      numeric: false,
      disablePadding: true,
      label: 'Service Type',
      isActiveSort: true,
      sortBy: '&sortBy=serviceType',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'dateOfCreation',
      numeric: false,
      disablePadding: true,
      label: 'Date of update',
      isActiveSort: true,
      sortBy: '&sortBy=updatedAt',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
  ],
  STATISTICS_VERIFICATION: [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      isActiveSort: true,
      sortBy: '&sortBy=userName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'dateOfCreation',
      numeric: false,
      disablePadding: true,
      label: 'Date of update',
      isActiveSort: true,
      sortBy: '&sortBy=updatedAt',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
  ],

  DEFAULT: [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Dessert (100g serving)',
    },
    { id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
    { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
    { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
    {
      id: 'protein',
      numeric: true,
      disablePadding: false,
      label: 'Protein (g)',
    },
  ],
  ADVERTISERS_TABLE: [
    {
      id: 'first_name',
      numeric: false,
      disablePadding: true,
      label: 'First Name',
      isActiveSort: true,
      sortBy: '&sortBy=firstName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'last_name',
      numeric: true,
      disablePadding: false,
      label: 'Last Name',
      isActiveSort: true,
      sortBy: '&sortBy=lastName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'date_crate',
      numeric: true,
      disablePadding: false,
      label: 'Date crate',
      isActiveSort: true,
      sortBy: '&sortBy=createdAt',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: 'Email',
      isActiveSort: true,
      sortBy: '&sortBy=email',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },

  ],
  CPAOFFER_LIST: [
    {
      id: 'title',
      numeric: false,
      disablePadding: true,
      label: 'title',
      isActiveSort: true,
      sortBy: '&sortBy=title',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'link',
      numeric: false,
      disablePadding: false,
      label: 'link',
      isActiveSort: true,
      sortBy: '&sortBy=link',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'price',
      numeric: true,
      disablePadding: false,
      label: 'price',
      isActiveSort: true,
      sortBy: '&sortBy=price',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'adminProfit',
      numeric: true,
      disablePadding: false,
      label: 'adminProfit',
      isActiveSort: true,
      sortBy: '&sortBy=adminProfit',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    
    {
      id: 'technicalDescription',
      numeric: false,
      disablePadding: false,
      label: 'technicalDescription',
      isActiveSort: true,
      sortBy: '&sortBy=technicalDescription',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },

  ],
  BLOG_LIST: [
    {
      id: 'date',
      numeric: false,
      disablePadding: true,
      label: 'Date',

      isActiveSort: true,
      sortBy: '&sortBy=date',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'title',
      numeric: true,
      disablePadding: false,
      label: 'Title',
      isActiveSort: true,
      sortBy: '&sortBy=title',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Category',
      numeric: true,
      disablePadding: false,
      label: 'Category',
      isActiveSort: true,
      sortBy: '&sortBy=category',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Short description',
      numeric: true,
      disablePadding: false,
      label: 'Short description',
      isActiveSort: true,
      sortBy: '&sortBy=description',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
  ],

  BLOGGERS_LIST: [
    {
      id: 'First name',
      numeric: true,
      disablePadding: false,
      label: 'First name',

      isActiveSort: true,
      sortBy: '&sortBy=firstName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Last name',
      numeric: true,
      disablePadding: false,
      label: 'Last name',

      isActiveSort: true,
      sortBy: '&sortBy=lastName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Email',
      numeric: true,
      disablePadding: false,
      label: 'Email',
      isActiveSort: true,
      sortBy: '&sortBy=email',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Number of Instagram accounts',
      numeric: true,
      disablePadding: false,
      label: 'Number of Instagram accounts',
    },
  ],
  INSTAGRAM_LIST: [
    {
      id: '22w',
      numeric: true,
      disablePadding: false,
      label: 'Instagram account',
    },
  ],

  TRANSACTION_TABLE: [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      isActiveSort: true,
      sortBy: '&sortBy=senderName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },

    {
      id: 'amount',
      numeric: false,
      disablePadding: true,
      label: 'Amount',
      isActiveSort: true,
      sortBy: '&sortBy=amount',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Date',
      numeric: false,
      disablePadding: true,
      label: 'Date',
      isActiveSort: true,
      sortBy: '&sortBy=createDate',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Type transaction',
      numeric: false,
      disablePadding: true,
      label: 'Type transaction',
    },
  ],
  TRANSACTION_TABLE_USER: [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      isActiveSort: true,
      sortBy: '&sortBy=senderName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },

    {
      id: 'amount',
      numeric: false,
      disablePadding: true,
      label: 'Amount',
      isActiveSort: true,
      sortBy: '&sortBy=amount',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Date',
      numeric: false,
      disablePadding: true,
      label: 'Date',
      isActiveSort: true,
      sortBy: '&sortBy=createDate',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Type transaction',
      numeric: false,
      disablePadding: true,
      label: 'Type transaction',
    },
  ],
  WITHDRAWAL_TABLE: [
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: 'First name',
      isActiveSort: true,
      sortBy: '&sortBy=firstName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: true,
      label: 'Last name',
      isActiveSort: true,
      sortBy: '&sortBy=lastName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email',
      isActiveSort: true,
      sortBy: '&sortBy=email',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: true,
      label: 'Date created',
      isActiveSort: true,
      sortBy: '&sortBy=date',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Sum',
      numeric: false,
      disablePadding: true,
      label: 'Amount',
      isActiveSort: true,
      sortBy: '&sortBy=money',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
  ],
  WITHDRAWAL_HISTORY_TABLE: [
    {
      id: 'Data_create',
      numeric: false,
      disablePadding: true,
      label: 'Data create',
      isActiveSort: true,
      sortBy: '&sortBy=date',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Data_update',
      numeric: false,
      disablePadding: true,
      label: 'Data update',
      isActiveSort: true,
      sortBy: '&sortBy=updatedDate',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,

    },
    {
      id: 'requester_email',
      numeric: false,
      disablePadding: true,
      label: 'Requester email',
      isActiveSort: true,
      sortBy: '&sortBy=reviewerEmail',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'requester_full_name',
      numeric: false,
      disablePadding: true,
      label: 'Requester full name',
      isActiveSort: true,
      sortBy: '&sortBy=requesterFirstName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'reviewer_Full_name',
      numeric: false,
      disablePadding: true,
      label: 'Reviewer full name',
      isActiveSort: true,
      sortBy: '&sortBy=reviewerFirstName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'reviewer_email',
      numeric: false,
      disablePadding: true,
      label: 'Reviewer email',
      isActiveSort: true,
      sortBy: '&sortBy=reviewerEmail',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Sum',
      numeric: false,
      disablePadding: true,
      label: 'Amount',
      isActiveSort: true,
      sortBy: '&sortBy=money',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
      isActiveSort: true,
      sortBy: '&sortBy=status',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
  ],
  CREATE_MODERATOR: [
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: 'First name',
      isActiveSort: true,
      sortBy: '&sortBy=firstName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: true,
      label: 'Last name',
      isActiveSort: true,
      sortBy: '&sortBy=lastName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email',
      isActiveSort: true,
      sortBy: '&sortBy=email',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: true,
      label: 'Data created',
      isActiveSort: true,
      sortBy: '&sortBy=createdAt',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
  ],
  REFERRALS_TABLE: [
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: 'First name',
      isActiveSort: true,
      sortBy: '&sortBy=firstName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: true,
      label: 'Last name',
      isActiveSort: true,
      sortBy: '&sortBy=lastName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email',
      isActiveSort: true,
      sortBy: '&sortBy=email',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: true,
      label: 'Data created',
      isActiveSort: true,
      sortBy: '&sortBy=createdAt',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'Invited_peoples',
      numeric: false,
      disablePadding: true,
      label: 'Invited peoples',
      isActiveSort: true,
      sortBy: '&sortBy=invitedPeople',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'All_amount',
      numeric: false,
      disablePadding: true,
      label: 'All amount',
      isActiveSort: true,
      sortBy: '&sortBy=allAmount',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,    },
  ],
  REFERRAL_TABLE:[
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: 'First name',
      isActiveSort: true,
      sortBy: '&sortBy=firstName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: true,
      label: 'Last name',
      isActiveSort: true,
      sortBy: '&sortBy=lastName',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email',
      isActiveSort: true,
      sortBy: '&sortBy=email',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
    {
      id: 'createdAt',
      numeric: false,
      disablePadding: true,
      label: 'Data created',
      isActiveSort: true,
      sortBy: '&sortBy=invitedAt',
      typeSort: ['&orderBy=asc', '&orderBy=desc'],
      statusSort: false,
    },
  ]
};

export const TABLE_TYPES = {
  INSTAGRAM_LIST: 'INSTAGRAM_LIST',
  BLOGGERS_LIST: 'BLOGGERS_LIST',
  CPAOFFER_LIST: 'CPAOFFER_LIST',
  BLOG_LIST: 'BLOG_LIST',
  ACCOUNTS_VERIFICATION: 'ACCOUNTS_VERIFICATION',
  STATISTICS_VERIFICATION: 'STATISTICS_VERIFICATION',
  ADS_REVIEW_COMPANY_LIST: 'ADS_REVIEW_COMPANY_LIST',
  ADS_REVIEW_LIST: 'ADS_REVIEW_LIST',
  VERIFICATION_LIST: 'VERIFICATION_LIST',
  CONTENT_VERIFICATION: 'CONTENT_VERIFICATION',
  CAMPAIGNS_LIST: 'CAMPAIGNS_LIST',
  DEFAULT: 'DEFAULT',
  ADVERTISERS_TABLE: 'ADVERTISERS_TABLE',
  TRANSACTION_TABLE: 'TRANSACTION_TABLE',
  TRANSACTION_TABLE_USER: 'TRANSACTION_TABLE_USER',
  WITHDRAWAL_TABLE: 'WITHDRAWAL_TABLE',
  WITHDRAWAL_HISTORY_TABLE: 'WITHDRAWAL_HISTORY_TABLE',
  CREATE_MODERATOR: 'CREATE_MODERATOR',
  REFERRALS_TABLE: 'REFERRALS_TABLE',

  REFERRAL_TABLE:'REFERRAL_TABLE'
};
