import moment from 'moment';
import { useHistory } from 'react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchUserNotifications,
  editNotifcationIsRead,
} from '../../../redux/store/notification/notificationOperations.js';
import {
  loaderSelector,
  userNotificationSelector,
} from '../../../redux/selectors/selectors';

import bell from '../../../assets/images/notifications.svg';
import './styles.css';

const Notification = () => {
  const { count, messages, unreadCount } = useSelector(
    userNotificationSelector,
  );
  const { contentLoader } = useSelector(loaderSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectStatus, changeSelectStatus] = useState(false);
  const [limit, setLimit] = useState(6);

  useEffect(() => {
    dispatch(fetchUserNotifications(limit));
  }, [dispatch, limit]);

  const toggleVisibleSelect = () => {
    changeSelectStatus(!selectStatus);
    setLimit(6);
  };

  const onHandleClick = (id, title, isRead) => {
    dispatch(editNotifcationIsRead(id, title, isRead, history));
  };

  const handleScroll = e => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    if (scrollHeight - scrollTop === clientHeight && count > limit) {
      setLimit(prev => prev + 6);
    }
  };

  return (
    <div
      className="notification"
      onMouseEnter={toggleVisibleSelect}
      onMouseLeave={toggleVisibleSelect}
    >
      <div
        className={
          unreadCount ? 'notification__img active' : 'notification__img'
        }
      >
        <img src={bell} alt="notification" />
      </div>
      <div className="notification__dropdown-item"></div>
      {selectStatus && (
        <div className="notification__dropdown">
          <div className="notification__dropdown-item-title">Notifications</div>
          {count ? (
            <div
              onScroll={handleScroll}
              className="notification__dropdown-list"
            >
              {messages &&
                messages.map(
                  (
                    {
                      title,
                      isRead,
                      data,
                      _id,
                      notificationCase,
                      idForRedirect,
                      image_url,
                    },
                    index,
                  ) => (
                    <div
                      key={title + index}
                      onClick={() => onHandleClick(_id, title, isRead)}
                      className="notification__dropdown-list-item"
                    >
                      <div
                        style={{ background: 'rgba(62, 202, 242, 0.13)' }}
                        className={
                          isRead === 'NOT_READ'
                            ? 'notification__dropdown-list-item-image bell'
                            : 'notification__dropdown-list-item-image'
                        }
                      >
                        <img src={image_url} alt="notification" />
                      </div>
                      <div className="notification__dropdown-list-item-information">
                        <span className="notification__dropdown-list-item-information-title">
                          {title}
                        </span>
                        <span className="notification__dropdown-list-item-information-time">
                          {moment(data).fromNow()}
                        </span>
                      </div>
                    </div>
                  ),
                )}
              {contentLoader && <div className="notification__loader" />}
            </div>
          ) : (
            <span className="notification__dropdown-list-empty">
              You dont have any notifications yet
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default Notification;
