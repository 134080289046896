import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Tab, Tabs, AppBar } from '@material-ui/core';

import TabPanel from './TabPanel/TabPanel';
import styles from './styles';

//VARIANT (fullWidth / scrollable)

const TabsContent = ({ classes, listOfTabs, variant = 'fullWidth' }) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const goToNextStep=(step)=>{
    setValue(step)
  }
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant={variant}
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {listOfTabs.map(({ label }) => (
            <Tab key={uuidv4()} label={label} />
          ))}
        </Tabs>
      </AppBar>
      <div axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value}>
        {listOfTabs.map(({ Component }, index) => (
          <TabPanel
            key={uuidv4()}
            value={value}
            index={index}
            dir={theme.direction}
          >
            {Component(goToNextStep)}
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(TabsContent);
