const types = {
  GET_VERIFICATION_REQUESTS_START:
    'verification/GET_VERIFICATION_REQUESTS_START',
  GET_VERIFICATION_REQUESTS_SUCCESS:
    'verification/GET_VERIFICATION_REQUESTS_SUCCESS',
  GET_VERIFICATION_REQUESTS_ERROR:
    'verification/GET_VERIFICATION_REQUESTS_ERROR',

  GET_SCREENS_FOR_APPROVE_START: 'verification/GET_SCREENS_FOR_APPROVE_START',
  GET_SCREENS_FOR_APPROVE_SUCCESS:
    'verification/GET_SCREENS_FOR_APPROVE_SUCCESS',
  GET_SCREENS_FOR_APPROVE_ERROR: 'verification/GET_SCREENS_FOR_APPROVES_ERROR',

  GET_SCREENS_FOR_APPROVE_BY_ID_START:
    'verification/GET_SCREENS_FOR_APPROVE_BY_ID_START',
  GET_SCREENS_FOR_APPROVE_BY_ID_SUCCESS:
    'verification/GET_SCREENS_FOR_APPROVE_BY_ID_SUCCESS',
  GET_SCREENS_FOR_APPROVE_BY_ID_ERROR:
    'verification/GET_SCREENS_FOR_APPROVE_BY_ID_ERROR',

  SEND_VERIFICATION_FORM_START: 'verification/SEND_VERIFICATION_FORM_START',
  SEND_VERIFICATION_FORM_SUCCESS: 'verification/SEND_VERIFICATION_FORM_SUCCESS',
  SEND_VERIFICATION_FORM_ERROR: 'verification/SEND_VERIFICATION_FORM_ERROR',

  REJECT_VERIFICATION_FORM_START: 'verification/REJECT_VERIFICATION_FORM_START',
  REJECT_VERIFICATION_FORM_SUCCESS:
    'verification/REJECT_VERIFICATION_FORM_SUCCESS',
  REJECT_VERIFICATION_FORM_ERROR: 'verification/REJECT_VERIFICATION_FORM_ERROR',

  ADD_DATA_TO_VERIFICATION_FORM_OBJECT:
    'verification/ADD_DATA_TO_VERIFICATION_FORM_OBJECT',

  SEND_VERIFY_REQUEST_STATUS_START:
    'verification/SEND_VERIFY_REQUEST_STATUS_START',
  SEND_VERIFY_REQUEST_STATUS_SUCCESS:
    'verification/SEND_VERIFY_REQUEST_STATUS_SUCCESS',
  SEND_VERIFY_REQUEST_STATUS_ERROR:
    'verification/SEND_VERIFY_REQUEST_STATUS_ERROR',

  CHANGE_VERIFICATION_FORM_STATUS:
    'verification/CHANGE_VERIFICATION_FORM_STATUS',

  CLEAR_VERIFICATION_FORM_DATA: 'verification/CLEAR_VERIFICATION_FORM_DATA',
};

export default types;
