import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { TABLE_TYPES } from '../../components/Tables/TablePrimary/TablePrimaryHead/config';
import TablePrimary from '../../components/Tables/TablePrimary/TablePrimary';
import { useDispatch, useSelector } from 'react-redux';
import { getListReferrer } from '../../../redux/store/referrals/referralsOperations';

const InvitedPeopleList = ({ id }) => {
  const dispatch = useDispatch();
  const bodyTable = useSelector((state) => state.referrals.listReferrer);
  const [params, setParams] = useState('');
  useEffect(() => {
    dispatch(getListReferrer(id));
  }, [id]);
  const _pagination = (e) => {
    dispatch(getListReferrer(id, 10, e*10, params));
  };
  const _sort = (e) => {
    setParams(e);
    dispatch(getListReferrer(id, 10, 0, e));
  };
  return (
    <Container  style={{overflow:'auto'}}>
      <Box p={4}>
        <Typography variant='h4' gutterBottom>
          Referral system
        </Typography>
        <TablePrimary
          notShowControl={true}

          rows={bodyTable.items}
          blocked={false}
          tableType={TABLE_TYPES.REFERRAL_TABLE}
          pagination={{
            count: bodyTable.count,
            length: 10,
            onChangePage: _pagination,
          }}
          sortInfo={_sort}
        />
      </Box>
    </Container>
  );
};
export default InvitedPeopleList;