import { writeCpa,writeCpaOfferList} from './cpaofferActions';
import {
  getCpaOffersList,
  postCpaOffer,
} from '../../../api/cpaoffer-service/cpaoffer-service';
import { setNotifyStatus } from '../notify/notifyActions';
import { errorHandler } from '../notify/notifyOperations';
import {
  changeButtonLoaderStatus,
  changeGlobalLoaderStatus,
} from '../loader/loaderActions';


export const getCpaList = id => dispatch => {
  dispatch(changeGlobalLoaderStatus(true));
  getCpaOffersList()
    .then(response => {
      dispatch(writeCpaOfferList(response));
    })
    .catch(error => dispatch(errorHandler(error.message)))
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};



export const creatCpa = (body, history) => dispatch => {
  console.log("CC " +body)
  dispatch(changeButtonLoaderStatus(true));
  postCpaOffer(body)
    .then(response => {
      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Added new cpa offer success`,
        }),
      );
      history.push('/cpaoffer');
    })
    .catch(error => dispatch(errorHandler(error.message)))
    .finally(() => dispatch(changeButtonLoaderStatus(false)));
};

