const types = {
  GET_USER_NOTIFICATIONS_START: 'notifications/GET_USER_NOTIFICATIONS_START',
  GET_USER_NOTIFICATIONS_SUCCESS:
    'notifications/GET_USER_NOTIFICATIONS_SUCCESS',
  GET_USER_NOTIFICATIONS_ERROR: 'notifications/GET_USER_NOTIFICATIONS_ERROR',

  PUT_USER_NOTIFICATION_IS_READ_START:
    'notifications/PUT_USER_NOTIFICATION_IS_READ_START',
  PUT_USER_NOTIFICATION_IS_READ_SUCCESS:
    'notifications/PUT_USER_NOTIFICATION_IS_READ_SUCCESS',
  PUT_USER_NOTIFICATION_IS_READ_ERROR:
    'notifications/PUT_USER_NOTIFICATION_IS_READ_ERROR',
};

export default types;
