import React, { useEffect, useState } from 'react';
import { Box, Button, Input } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  editProfitSettings,
  getPercentInfo,
  getStatisticsAdmin,
} from '../../../redux/store/referrals/referralsOperations';


const SettingsReferrals = () => {
  const dispatch = useDispatch();
  const { countOfAllTime, countOfMonth, profit } = useSelector(state => state.referrals.statistic);
  const { percent } = useSelector(state => state.referrals.percentInfo);
  const [activePercent, setActivePercent] = useState();
  useEffect(() => {
    dispatch(getStatisticsAdmin());
    dispatch(getPercentInfo());
  }, []);
  useEffect(() => {
    setActivePercent(percent);
  }, [percent]);
  const _onUpdatePercent = ({ target: { value } }) => {
    setActivePercent(prevValue => (
      value >= 0 && value < 100 ? value : prevValue
    ));


  };
  const saveActivePercent = () => {
    if(percent!==activePercent){
      dispatch(editProfitSettings(activePercent));
    }
  };

  return (
    <Box display={'flex'} justifyContent={'space-around'} flexWrap={'wrap'}>
      <Box display={'flex'} padding={'30px'} bgcolor={'#f2f2f2'} width={'90%'} margin={'10px'} borderRadius={'10px'}>
        <Box margin={'auto'}>All sum payment in partners program:</Box>
        <Box margin={'auto'}>{profit}</Box>
      </Box>
      <Box display={'flex'} padding={'30px'} bgcolor={'#f2f2f2'} width={'40%'} margin={'10px'} borderRadius={'10px'}>
        <Box margin={'auto'}>Set active percent for referrals: </Box>
        <Box display={'flex'} width={'40%'}>
          <Input value={activePercent} type={'number'} max={100} min={0}
                 onChange={_onUpdatePercent} />
          <Button onClick={saveActivePercent}>
            update
          </Button>
        </Box>
      </Box>
      <Box display={'flex'} padding={'30px'} bgcolor={'#f2f2f2'} width={'40%'} margin={'10px'} borderRadius={'10px'}>
        <Box margin={'auto'}>Active percent for referrals:</Box>
        <Box margin={'auto'}>{percent} %</Box>
      </Box>

      <Box display={'flex'} padding={'30px'} bgcolor={'#f2f2f2'} width={'40%'} margin={'10px'} borderRadius={'10px'}>
        <Box margin={'auto'}>Count of month:</Box>
        <Box margin={'auto'}>{countOfMonth}</Box>
      </Box>

      <Box display={'flex'} padding={'30px'} bgcolor={'#f2f2f2'} width={'40%'} margin={'10px'} borderRadius={'10px'}>
        <Box margin={'auto'}>Count of all time</Box>
        <Box margin={'auto'}>{countOfAllTime}</Box>
      </Box>

    </Box>);
};
export default SettingsReferrals;