import types from './blogTypes';

const initialState = {

  blogList: {
    items: [{
      view: {},
      _id: 1,
    }],
    count: 0,
  },
  blogItem: null,

};

const blogReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_LIST_BLOG:
      return { ...state, blogList: payload };
    case types.GET_ITEM_BLOG:
      return { ...state, blogItem: payload };
    default:
      return state;
  }
};

export default blogReducer;
