import types from './confirmTypes';

const initialState = {
  isVisible: false,
  type: null,
  actionParams: [],
};

const confirmReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CHANGE_CONFIRM_STATUS:
      return payload;

    default:
      return state;
  }
};

export default confirmReducer;
