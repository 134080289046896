import types from './authenticationTypes';

export const loginUser = payload => ({
  type: types.POST_LOGIN_USER_SUCCESS,
  payload,
});
export const logoutUser = () => ({
  type: types.GET_LOGOUT_SUCCESS,
});

export const setFcmToken = payload => ({
  type: types.SET_FCM_TOKEN,
  payload,
});
