import { get, put, post, remove } from '../apiService';


export const registrationModeration = (body ) => {
  return post(`/auth/registration/moderator`, body);
};
export const editModeration = (id, body ) => {
  return put(`/user/moderator/${id}`, body);
};
export const removeModeration = (id) => {
  return remove(`/user/moderator/${id}`);
};
export const getListModerators = (limit, offset, sort) => {
  return get(`/user/moderator?limit=${limit}&offset=${offset}${sort}`);
};
export const getUser = (id) => {
  return get(`/user/${id}`);
};