import {
  getCampaigns,
  getOfferItem,
  getOfferList,
  getReviewList,
  getVerificationItem,
  postAddedApprove,
  postAddedReject,
  putEditStatusReview,
  getOfferByPublicId,
} from '../../../api/campaigns-servise/campaigns-servise';
import {
  getAddForOfferListSuccess,
  getAddForReviewListSuccess,
  getCampaignListSuccess,
  getVerificationItemSuccess,
  getVerificationListSuccess,
  setReviewItem,
} from './campaignsActions';
import { setNotifyStatus } from '../notify/notifyActions';
import { errorHandler } from '../notify/notifyOperations';
import { dataFormat } from '../../../utilites/globalUtils';
import moment from 'moment';
import { changeGlobalLoaderStatus } from '../loader/loaderActions';

export const fetchVerificationList =
  (page = 0, type, filter) =>
  dispatch => {
    dispatch(changeGlobalLoaderStatus(true));
    getCampaigns(
      'Advertisements_verification' === type ? 'PENDING' : 'ALL',
      page * 10,
      filter,
    )
      .then(({ items, count }) => {
        const buffer = items.map(
          ({ createdAt, updatedAt, _id, adPublicId, user, approved, adId }) => {
            if (type === 'advertisements') {
              return {
                view: {
                  createdAt: dataFormat(createdAt),
                  fullName: user?.firstName + ' ' + user?.lastName,
                  EmailUser: user?.email ? user.email : '----------------',
                  idUser: adPublicId,
                },
                _id: typeof approved === 'boolean' && !approved ? _id : adId,
                isRejected: typeof approved === 'boolean' && !approved,
                approved: approved,
              };
            } else {
              return {
                view: {
                  createdAt: dataFormat(createdAt),
                  updatedAt: dataFormat(updatedAt),
                  fullName: user?.firstName + ' ' + user?.lastName,
                  idUser: adPublicId,
                  EmailUser: user?.email ? user.email : '----------------',
                },
                _id: _id,
                approved: approved,
              };
            }
          },
        );
        type === 'advertisements'
          ? dispatch(getCampaignListSuccess({ count, items: buffer }))
          : dispatch(getVerificationListSuccess({ count, items: buffer }));
      })
      .catch(error => {
        dispatch(errorHandler(error.message));
      })
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  };

export const fetchAddForReviewList =
  (page = 0, filter) =>
  dispatch => {
    dispatch(changeGlobalLoaderStatus(true));
    getReviewList(page * 10, filter)
      .then(({ items, count }) => {
        const buffer = items.map(
          ({
            endDate,
            _id,
            publishTimeTo,
            offersCount: {
              active,
              complete,
              failed,

              moderation,
              unresolved,
            },
            title,
            publicId,
          }) => {
            return {
              view: {
                createdAt:
                  moment(new Date(endDate)).format('ll') +
                  ' ' +
                  moment(new Date(publishTimeTo)).format('LT'),
                publicId,
                title,
                active: { color: '#000', text: active },
                moderation: { color: '#008000', text: moderation },
                unresolved: { color: '#ffac12', text: unresolved },
                failed: { color: '#f11', text: failed },
                complete: { color: '#000', text: complete },
              },
              _id: _id,
            };
          },
        );
        dispatch(getAddForReviewListSuccess({ count, items: buffer }));
      })
      .catch(error => {
        dispatch(errorHandler(error.message));
      })
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  };

export const fetchGetOfferList =
  (id, page = 0, filter) =>
  dispatch => {
    dispatch(changeGlobalLoaderStatus(true));
    getOfferList(id, page * 10, filter)
      .then(({ offers, count }) => {
        const buffer = offers.map(({ _id, status, user, publicId }) => {
          return {
            view: {
              name: user.email,
              bloggerInfo: `@${user.accountName}`,
              offerId: publicId,
              status,
            },
            _id: _id,
          };
        });
        dispatch(getAddForOfferListSuccess({ count, items: buffer }));
      })
      .catch(error => {
        dispatch(errorHandler(error.message));
      })
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  };

export const fetchVerificationItem = id => dispatch => {
  dispatch(changeGlobalLoaderStatus(true));
  getVerificationItem(id)
    .then(r => {
      dispatch(getVerificationItemSuccess(r));
    })
    .catch(error => {
      dispatch(errorHandler(error.message));
    })
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};

export const verificationReject = (id, comment) => dispatch => {
  postAddedReject(id, comment)
    .then(r => {
      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Content rejected`,
        }),
      );
    })
    .catch(error => {
      dispatch(errorHandler(error.message));
    });
};
export const verificationApprove = id => dispatch => {
  postAddedApprove(id)
    .then(r => {
      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Content approved`,
        }),
      );
    })
    .catch(error => {
      dispatch(errorHandler(error.message));
    });
};
export const getReviewItem = id => dispatch => {
  dispatch(changeGlobalLoaderStatus(true));
  getOfferItem(id)
    .then(r => {
      dispatch(fetchVerificationItem(r.advertisement.story._id));

      // dispatch(fetchVerificationItem(r.campaign._id));

      dispatch(setReviewItem(r));
    })
    .catch(error => {
      dispatch(errorHandler(error.message));
    })
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};

export const editStatus = (id, body) => dispatch => {
  putEditStatusReview(id, body)
    .then(r => {
      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Review approved`,
        }),
      );
    })
    .catch(error => {
      dispatch(errorHandler(error));
    });
};

export const getAdsByPublicId = (publicId, history) => dispatch => {
  getOfferByPublicId(publicId)
    .then(({ _id: offerId }) => {
      history.push(`/review/${offerId}`);
    })
    .catch(({ message }) =>
      dispatch(
        setNotifyStatus({
          type: 'error',
          content: message,
        }),
      ),
    );
};
