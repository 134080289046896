import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Input from '../../../ui-core/Input/Input';
import TextArea from '../../../ui-core/TextArea/Input';
import styles from './styles';
import { createFormData , createJSON} from '../../../../utilites/formDataOperations';
import {
  creatCpa,
} from '../../../../redux/store/cpaoffer/cpaofferOperations';
import { useHistory } from 'react-router-dom';
import { loaderSelector } from '../../../../redux/selectors/selectors';
import { cpaofferSchema } from '../../../../validators/formValidators';
const FormCpaOffer = ({ classes, isEdit, body, id }) => {
  const { buttonLoader } = useSelector(loaderSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: isEdit ? { ...body } : {},
  });
  const onSubmit =  data => {
    const formData =  createJSON(data);
    console.log(formData)
    dispatch(creatCpa(formData, history));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.innerFormContainer}>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={8}>
            <Input
              control={control}
              name="title"
              label="title"
              defaultValue={''}
            />
            <Typography style={{ color: '#f33' }}>
              {errors?.title?.message}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Input
              control={control}
              name="adminProfit"
              label="adminProfit"
              defaultValue={''}
            />
            <Typography style={{ color: '#f33' }}>
              {errors?.title?.message}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Input
              control={control}
              name="price"
              label="price"
              defaultValue={''}
            />
            <Typography style={{ color: '#f33' }}>
              {errors?.title?.message}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Input
              control={control}
              name="link"
              label="link"
              defaultValue={''}
            />
            <Typography style={{ color: '#f33' }}>
              {errors?.title?.message}
            </Typography>
          </Grid>
          <Grid container item alignItems="center">
            <Grid item xs={8}>
              <TextArea
                control={control}
                name="technicalDescription"
                label="technicalDescription"
                rows={4}
              />
              <Typography style={{ color: '#f33' }}>
                {errors?.summary?.message}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            style={{ height: '42px', width: '127.859px' }}
            disabled={!isValid || buttonLoader}
            startIcon={!buttonLoader && <SaveIcon />}
          >
            {buttonLoader ? <CircularProgress size={14} /> : 'Submit'}
          </Button>
        </Grid>
      </Paper>
    </form>
  );
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(withStyles(styles)(FormCpaOffer));
