import React from 'react';
import { Grid, Box, Button } from '@material-ui/core';
import Input from '../../../../ui-core/Input/Input';

const City = ({name,
                number, control, removeCity = () => {
    }, length,
              }) => {
    return (
      <>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Grid m={2} item={true} xs={length > 1 ? 7 : 9}>
            <Input
              control={control}
              name={`locationCities.city-${number}.cityName`}
              label='City'
              defaultValue={''}
            />
          </Grid>

          <Grid item={true} xs={2}>
            <Input
              type='number'
              control={control}
              name={`locationCities.city-${number}.percent`}
              label='%'
              defaultValue={''}
            />
          </Grid>{length > 1 ?
          <Grid item={true} xs={2}>
            <Button color='secondary' onClick={() => removeCity(name)}>Remove</Button>
          </Grid> : null}
        </Box>
      </>
    );
  }
;

export default City;
