import { useState, useEffect } from 'react';
import { getInfoUser } from '../api/authentication-servise/authentication';

export const useGetUserRole = () => {
  const [userRole, setUserRole] = useState(() => {
    const role = localStorage.getItem('role');
    return role ? JSON.parse(role) : null;
  });
  useEffect(() => {
    if (!userRole) {
      getInfoUser().then(({ role }) => {
        setUserRole(role)
        localStorage.setItem(
          'role',
          JSON.stringify(role),
        );
      }).catch((e)=>{console.log(e)});
    }
  }, []);
  return userRole;
};