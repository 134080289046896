import React  from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { login } from '../../../../redux/store/authentication/authenticationOperations';
import { personalInfoSelector } from '../../../../redux/selectors/selectors';

import Input from '../../../ui-core/Input/Input';
import { loginValidation } from './config';
import { useHistory } from 'react-router-dom';

const LoginForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidation),
  });
const history=useHistory()
  const { fcmToken } = useSelector(personalInfoSelector);
  const dispatch = useDispatch();

  const handleFormSubmit = data => {
    fcmToken ? dispatch(login({ ...data, fcmToken },history)) : dispatch(login(data,history));
  };

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Input
        type={'email'}
        control={control}
        name={`email`}
        label="email"
        defaultValue={''}
      />
      <p>{errors.password?.email}</p>

      <Input
        type={'password'}
        control={control}
        name={`password`}
        label="password"
        defaultValue={''}
      />
      <p>{errors.password?.message}</p>

      <Button
        color="primary"
        autoFocus
        style={{ margin: 'auto' }}
        // disabled={!isValid}
        onClick={() => {}}
        type="submit"
      >
        Confirm
      </Button>
    </form>
  );
};

export default LoginForm;
