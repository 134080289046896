import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import TablePrimary from '../../components/Tables/TablePrimary/TablePrimary';
import { Button, Box, Container, Typography } from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import routeBook from '../../../constants/routeBook';
import styles from './styles';
import React, { useEffect } from 'react';
import { updateBlogList } from '../../../redux/store/blog/blogOperations';
import { TABLE_TYPES } from '../../components/Tables/TablePrimary/TablePrimaryHead/config';
import { useDispatch, useSelector } from 'react-redux';
import { changeConfirmStatus } from '../../../redux/store/confirm/confirmActions';
import { confirmationKeys } from '../../../constants/confirmationKeys';


const Blog = ({ history, classes }) => {
  const handleEditButtonClick = (id) =>
    history.push(`/blog/${id}`);
  const handleDeletedButtonClick = (id) =>
    history.push(dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.deleteBlog,
        actionParams: [id, false],
      }),
    ));
  const valueRows = useSelector(store => store.blog.blogList);
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(updateBlogList(10, 0));
    }, [dispatch],
  );
  const _selectAasReview = (page) => {
    dispatch(updateBlogList(10, page * 10));
  };
  const _sort = (sort) => {
   dispatch(updateBlogList(10, 0,sort));
  };
  return (
    <Container maxWidth='lg'  style={{overflow:'auto',padding:'0'}}>
      <Box p={1}>
        <Typography variant='h4' gutterBottom>
          Blog
        </Typography>
        <Link className={classes.link} to={routeBook.blogCreate}>
          <Button
            style={{ margin: '0 0 50px 0' }}
            variant='outlined'
            color='primary'
            size='large'
          >
            create news
            <AddCircleOutlineOutlinedIcon style={{ margin: '0 0 0 10px' }} />
          </Button>
        </Link>
        <TablePrimary
          tableType={TABLE_TYPES.BLOG_LIST}
          rows={valueRows.items}
          handlers={{ handleEditButtonClick, handleDeletedButtonClick }}
          pagination={{
            count: valueRows.count,
            length: 10,
            onChangePage: _selectAasReview,
          }}
          sortInfo={_sort}
        />
      </Box>
    </Container>
  );
};

export default withStyles(styles)(Blog);
