import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@material-ui/core';
import { balanceAdvertiserRefile } from '../../../../api/transactions-service/transactions-service';
import { errorHandler } from '../../../../redux/store/notify/notifyOperations';
import { useDispatch } from 'react-redux';
import { setNotifyStatus } from '../../../../redux/store/notify/notifyActions';
import { changeConfirmStatus } from '../../../../redux/store/confirm/confirmActions';


const TopUpBalance = ({ idAd,updateInfo }) => {
  const [balance, setBalance] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  const _refillAccountModeration = () => {
    setIsLoader(true);
    balanceAdvertiserRefile({
      amount: balance,
      advertiserId: idAd,
    }).then(r => {
      updateInfo()
      dispatch(changeConfirmStatus({ isVisible: false, type: null }));

      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Account refill was successful`,
        }),
      );
    }).catch(({ message }) => {
      dispatch(errorHandler(message));
    }).finally(() => {
      setIsLoader(false);
    });
  };
  return (
    <Box>
      <Box margin={'10px 20px 10px '}>
        <Typography variant='h6' align='center'>Account refill</Typography>
      </Box>
      <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
        <TextField
          style={{ margin: '20px 20px' }}
          label={'Top up balance'}
          type='number'
          size='small'
          variant='outlined'
          value={balance}
          onChange={({ target: { value } }) => {
            setBalance(Number.parseInt(value));
          }}
        />
        <Button
          variant='contained'
          color='primary'
          size='large'
          type='submit'
          style={{ height: '42px', width: '127.938px' }}
          disabled={!balance || isLoader}
          onClick={_refillAccountModeration}
        >
          Refill </Button>

      </Box>
    </Box>

  );
};

export default TopUpBalance;