import loaderTypes from './loaderTypes';

export const changeContentLoaderStatus = status => ({
  type: loaderTypes.CHANGE_CONTENT_LOADER_STATUS,
  payload: status,
});

export const changeButtonLoaderStatus = status => ({
  type: loaderTypes.CHANGE_BUTTON_LOADER_STATUS,
  payload: status,
});

export const changeGlobalLoaderStatus = status => ({
  type: loaderTypes.CHANGE_GLOBAL_LOADER_STATUS,
  payload: status,
});
