import ArtTrackRoundedIcon from '@material-ui/icons/ArtTrackRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import WorkIcon from '@material-ui/icons/Work';
import routeBook from '../../../constants/routeBook';
import { typeRole } from '../../router/typeRole';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

export const navigationConfig = [
  {
    text: 'Advertisers',
    Icon: PeopleRoundedIcon,
    link: routeBook.advertisers,
    role: [typeRole.moderator, typeRole.admin],
  },
  {
    text: 'Bloggers',
    Icon: AccountBoxIcon,
    link: routeBook.bloggers,
    role: [typeRole.moderator, typeRole.admin],
  },/*
  {
    ext: 'Yotube Bloggers',
    Icon: AccountBoxIcon,
    link: routeBook.YoutubeBloggers,
    role: [typeRole.moderator, typeRole.admin],
  },*/
  {
    text: 'CPA',
    Icon: AccountBoxIcon,
    link: routeBook.cpaoffers,
    role: [typeRole.moderator, typeRole.admin],
  },
  {
    text: 'Advertisements',
    Icon: WorkIcon,
    link: '/item/0',
    role: [typeRole.moderator, typeRole.admin],
  },
  {
    text: 'Transactions',
    Icon: ImportExportIcon,
    link: routeBook.transactions,
    role: [typeRole.admin],

  },
  {
    text: 'Withdrawal',
    Icon: AutorenewIcon,
    link: routeBook.withdrawal,
    role: [typeRole.admin],

  },
  {
    text: 'Verification',
    Icon: VerifiedUserIcon,
    link: routeBook.verification,
    role: [typeRole.moderator, typeRole.admin],

  },
  {
    text: 'Blog',
    Icon: ArtTrackRoundedIcon,
    link: routeBook.blog,
    role: [typeRole.moderator, typeRole.admin],

  },
  {
    text: 'Moderators',
    Icon: AssignmentIndIcon,
    link: routeBook.moderator,
    role: [typeRole.admin],
  },
  {
    text: 'Referral system',
    Icon: GroupAddIcon,
    link: routeBook.referralInfoUser,
    role: [typeRole.admin],
  },
];
