import { Typography, Box, TextField } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LanguageIcon from '@material-ui/icons/Language';
import EventIcon from '@material-ui/icons/Event';
import DescriptionIcon from '@material-ui/icons/Description';
import { dataFormat } from '../../../../utilites/globalUtils';
import moment from 'moment';

const InformationBlock = (props) => {
    const {
      profile,
      instruction,
      site,
      updatedAt,
      type,
      adPublicId,
      adName,
      isNotReview,
      createdAt,
      reviewData,
    } = props;
    // moment(new Date(reviewData.advertisement.endDate)).format('ll')+' '+ moment(new Date(reviewData.advertisement.publishTimeTo)).format('LT'),
    // moment(new Date(reviewData.advertisement.startDate)).format('ll')+' '+ moment(new Date(reviewData.advertisement.publishTimeFrom)).format('LT'),

    return (
      <Box minWidth={type === 'IMPROVISATION' ? '100%' : '50%'}>
        <Box display='flex' mb={4} flexDirection='row' justifyContent={'space-between'} width={'100%'}>
          <Box display='flex' flexDirection='row' bgcolor='#fff' p={1} borderRadius={3}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Name:</span> {adName}
            </Typography>
          </Box>
          <Box display='flex' justifyContent={'flex-end'} flexDirection='row' bgcolor='#fff' p={1} borderRadius={3}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>ID:</span> {adPublicId}
            </Typography>
          </Box>
        </Box>
        {updatedAt ?
          <Box display='flex' mb={4} flexDirection='column'>
            <Box display='flex' mb={2} flexDirection='row' alignItems='flex-end'>
              <EventIcon color='primary' />
              <Typography
                style={{ marginLeft: '5px' }}>{!isNotReview ? 'Publish start date :' : ' data update :'} </Typography>
            </Box>
            <Box display='flex' flexDirection='row' bgcolor='#fff' p={1} borderRadius={3}>
              <Typography>
                {!isNotReview && reviewData?.advertisement?.startDate && reviewData?.advertisement?.publishTimeFrom ? moment(new Date(reviewData.advertisement.startDate)).format('ll') + ' ' + moment(new Date(reviewData.advertisement.publishTimeFrom)).format('LT')
                  : dataFormat(updatedAt)}
              </Typography>
            </Box>
          </Box> : null}

        {!isNotReview && createdAt ?
          <Box display='flex' mb={4} flexDirection='column'>
            <Box display='flex' mb={2} flexDirection='row' alignItems='flex-end'>
              <EventIcon color='primary' />
              <Typography style={{ marginLeft: '5px' }}>Publish end date </Typography>
            </Box>
            <Box display='flex' flexDirection='row' bgcolor='#fff' p={1} borderRadius={3}>
              <Typography>
                {!isNotReview && reviewData?.advertisement?.publishTimeTo && reviewData?.advertisement?.endDate ? moment(new Date(reviewData.advertisement.endDate)).format('ll') + ' ' + moment(new Date(reviewData.advertisement.publishTimeTo)).format('LT')
                  : dataFormat(updatedAt)}
              </Typography>
            </Box>
          </Box> : null}

        {instruction ?
          <Box display='flex' mb={4} flexDirection='column'>
            <Box display='flex' mb={2} flexDirection='row' alignItems='flex-end'>
              <DescriptionIcon color='primary' />
              <Typography style={{ marginLeft: '5px' }}>instruction : </Typography>
            </Box>
            <Box display='flex' flexDirection='row' bgcolor='#fff' p={1} borderRadius={3} height='150px'>
          <textarea aria-label='minimum height' value={instruction ? instruction : '-'} onChange={() => {
          }}
                    style={{ height: '150px', width: '100%', resize: 'none', border: 'none', outline: 'none' }}
          />
            </Box>
          </Box> : null
        }
        <Box display='flex' justifyContent='space-between'>
          {profile ?
            <Box display='flex' mb={4} flexDirection='column' width={'48%'}>
              <Box display='flex' mb={2} flexDirection='row' alignItems='flex-end'>
                <PersonIcon color='primary' />
                <Typography style={{ marginLeft: '5px' }}>profile: </Typography>
              </Box>
              <Box display='flex' flexDirection='row' bgcolor='#fff' borderRadius={3}>
                <TextField variant='outlined' value={profile ? profile : '-'}
                           style={{ width: '100%' }} onChange={() => {
                }} />
              </Box>
            </Box> : null}
          {
            site ? <Box display='flex' mb={4} flexDirection='column' width={' 48%'}>
              <Box display='flex' mb={2} flexDirection='row' alignItems='flex-end'>
                <LanguageIcon color='primary' />
                <Typography style={{ marginLeft: '5px' }}>site: </Typography>
              </Box>
              <Box display='flex' flexDirection='row' bgcolor='#fff' borderRadius={3}>
                <TextField variant='outlined' value={site ? site : '-'} style={{ width: '100%' }} onChange={() => {
                }} />
              </Box>
            </Box> : null
          }

        </Box>

      </Box>
    );
  }
;

export default InformationBlock;
