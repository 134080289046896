import { get, post } from '../apiService';

export const getListTransaction = (limit, offset, filter, sort, id) => {
  return get(`/transaction/history?limit=${limit}&offset=${offset}&type=${filter}${id ? '&userId=' + id:''}${sort}`);
};
export const getListWithdrawalRequest = (limit, offset, filter, sort) => {
  return get(`/withdrawal-request?limit=${limit}&offset=${offset}&type=${filter}${sort}`);
};
export const getListWithdrawalHistory = (limit, offset, filter, sort) => {
  return get(`/withdrawal-request/history?limit=${limit}&offset=${offset}&type=${filter}${sort}`);
};
export const checkingWithdrawal = (id, isApprove = false) => {
  return post(`/withdrawal-request/${id}/check`, {
    approve: isApprove,
  });
};
export const balanceAdvertiserRefile =(body)=>{
    return post('/wallet/fill-up',body)
}