import {
  saveListReferral,
  saveListReferrer,
  savePercent,
  saveStatistics,
} from './referralsActions';
import {
  getAllReferralProgramPartners, getInfoActiveReferralPercent,
  getInfoUserReferrer,
  getStatistics, setProfitSettings,
} from '../../../api/referrals-servise/referrals-servise';
import { dataFormat } from '../../../utilites/globalUtils';
import { setNotifyStatus } from '../notify/notifyActions';
import { changeGlobalLoaderStatus } from '../loader/loaderActions';


export const getListReferral = (limit = 10, offset = 0, params = '') => dispatch => { //Реферер - это человек, пригласивший конкретного реферала
  dispatch(changeGlobalLoaderStatus(true))
  getAllReferralProgramPartners(limit, offset, params).then(({ items, count }) => {
    const buffer = items.map(
      ({
         allAmount,
         createdAt,
         email,
         firstName,
         invitedPeople,
         lastName,
         _id,
       }) => {
        return {
          view: {
            firstName,
            lastName,
            email,
            createdAt: dataFormat(createdAt),
            invitedPeople,
            allAmount: '' + allAmount,
          },
          id: _id,
        };

      },
    );
    dispatch(saveListReferral({ items: buffer, count }));
  }).catch(({ message }) => {
    dispatch(
      setNotifyStatus({
        type: 'error',
        content: `${message}`,
      }),
    );
  }).finally(() => dispatch(changeGlobalLoaderStatus(false)));
};
export const getListReferrer = (id, limit = 10, offset = 0, params = '') => dispatch => {
  dispatch(changeGlobalLoaderStatus(true))
  getInfoUserReferrer(id, limit, offset, params).then(({ items, count }) => {
    const buffer = items.map(
      ({
         firstName,
         lastName,
         email,
         invitedAt,
       }) => (
        {
          view: {
            firstName,
            lastName,
            email,
            invitedAt: dataFormat(invitedAt),
          },
        }
      ));
    dispatch(saveListReferrer({ items: buffer, count }));
  }).catch(({ message }) => {
    dispatch(
      setNotifyStatus({
        type: 'error',
        content: `${message}`,
      }),
    );
  }).finally(() => dispatch(changeGlobalLoaderStatus(false)));
};
export const getStatisticsAdmin = () => dispatch => {
  dispatch(changeGlobalLoaderStatus(false))
  getStatistics().then((r) => {
    dispatch(saveStatistics(r));
  }).catch(({ message }) => {
    dispatch(
      setNotifyStatus({
        type: 'error',
        content: `${message}`,
      }),
    );
  }).finally(() => dispatch(changeGlobalLoaderStatus(false)));
};
export const getPercentInfo = () => dispatch => {
  getInfoActiveReferralPercent().then(({ items }) => {
    dispatch(savePercent(items.length?items[0]:{percent: 3}));
  }).catch(({ message }) => {
    dispatch(
      setNotifyStatus({
        type: 'error',
        content: `${message}`,
      }),
    );
  }).finally(() => dispatch(changeGlobalLoaderStatus(false)));

};
export const editProfitSettings = (percent) => dispatch => {
  setProfitSettings({ percent }).then(r => {
    dispatch(
      setNotifyStatus({
        type: 'success',
        content: `Successful updated percent for referrals`,
      }),
    );
    dispatch(getPercentInfo())}).catch(({ message }) => {
    dispatch(
      setNotifyStatus({
        type: 'error',
        content: `${message}`,
      }),
    );
  }).finally(() => dispatch(changeGlobalLoaderStatus(false)));
};