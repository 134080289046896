import * as yup from 'yup';

export const moderatorSchema = yup.object().shape({
  email: yup.string().email(),
  firstName: yup.string().min(2).max(20).trim(true).required(),
  lastName: yup.string().min(2).max(20).trim(true).required(),
  createPassword: yup.string().required().min(6),
  confirmPassword: yup.string().required().min(6).oneOf([yup.ref('createPassword'), null], 'Passwords must match'),
});
export const editSchema = yup.object().shape({
  email: yup.string().email(),
  firstName: yup.string().min(2).max(20).trim(true).required(),
  lastName: yup.string().min(2).max(20).trim(true).required(),
  newPassword: yup.string().min(6),
  confirmNewPassword: yup.string().min(6).oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});