import { get, remove, postFormData, putFormData } from '../apiService';

export const getBlogList = (limit = 10, offset = 0, sort) => {
  return get(`/news/?limit=${limit}&offset=${offset}${sort ? sort : ''}`);
};
export const postBlog = body => {
  return postFormData(`/news`, body);
};
export const putBlog = (id, body) => {
  return putFormData(`/news/${id}`, body);
};
export const deleteBlog = id => {
  return remove(`/news/${id}`);
};
export const getBlogId = id => {
  return get(`/news/${id}`);
};
