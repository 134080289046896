const listOfInputsCategories = [
  { category: 'all', label: 'all' },
  { category: 'ageMale', label: 'male' },
  { category: 'ageFemale', label: 'female' },
];

export const listOfAgeRange = [
  {
    name: '13-17',
    inputs: listOfInputsCategories,
  },
  {
    name: '18-24',
    inputs: listOfInputsCategories,
  },
  {
    name: '25-34',
    inputs: listOfInputsCategories,
  },
  {
    name: '35-44',
    inputs: listOfInputsCategories,
  },
  {
    name: '45-54',
    inputs: listOfInputsCategories,
  },
  {
    name: '55-64',
    inputs: listOfInputsCategories,
  },
  {
    name: '65',
    inputs: listOfInputsCategories,
  },
];
