import React from 'react';
import SunEditor from 'suneditor-react';
import { Controller } from 'react-hook-form';

import { textEditorConfig } from '../../../constants/textEditorConfig';

import 'suneditor/dist/css/suneditor.min.css';

const TextEditor = ({ name, control, defaultValue = '' }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur } }) => (
        <SunEditor
          showToolbar={true}
          setOptions={textEditorConfig}
          height="200"
          resize={false}
          onChange={onChange}
          onBlur={onBlur}
          defaultValue={defaultValue}
        />
      )}
    ></Controller>
  );
};
export default TextEditor;
