import React, { useEffect, useState } from 'react';
import { Box, Container, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { TABLE_TYPES } from '../../components/Tables/TablePrimary/TablePrimaryHead/config';
import TablePrimary from '../../components/Tables/TablePrimary/TablePrimary';
import { changeConfirmStatus } from '../../../redux/store/confirm/confirmActions';
import { confirmationKeys } from '../../../constants/confirmationKeys';
import InvitedPeopleList from './invitedPeopleList';
import { useDispatch, useSelector } from 'react-redux';
import { getListReferral } from '../../../redux/store/referrals/referralsOperations';
import TabPanel from '../../components/TabPanel/TabPanel';
import SettingsReferrals from './settingsReferrals';


const Referrals = () => {
  const [params, setParams] = useState('');
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const bodyTable = useSelector((state) => state.referrals.listReferral);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListReferral());
  }, [dispatch]);

  const handleDetailsButton = (id) => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.createModerator,
        actionParams: { form: () => <InvitedPeopleList id={id} /> },
      }),
    );
  };
  const _pagination = (e) => {
    dispatch(getListReferral(10, e * 10, params));

  };
  const _sort = (e) => {
    setParams(e);
    dispatch(getListReferral(10, 0, e));
  };
  const _infoReferrals = () => {
    setActiveTabIndex(0);
  };
  const _settingsReferrals = () => {
    setActiveTabIndex(1);
  };
  return (
    <Container maxWidth='lg'  style={{overflow:'auto',padding:'0'}}>
      <Box p={1} minWidth={'900px'}>
        <Typography variant='h4' gutterBottom>
          Referral system
        </Typography>
        <Paper style={{ margin: '0 0 10px 0' }} square>
          <Tabs
            value={activeTabIndex}
            onChange={setActiveTabIndex}
            variant='fullWidth'
            indicatorColor='primary'
            textColor='primary'
          >
            <Tab
              label={
                <Box fontWeight='fontWeightBold'>info referrals</Box>
              }
              onClick={_infoReferrals}
            />
            <Tab
              label={<Box fontWeight='fontWeightBold'>Settings referrals</Box>}
              onClick={_settingsReferrals}
              color='primary'
            />

          </Tabs>
          <TabPanel activeTabIndex={activeTabIndex} index={0}>


            <TablePrimary
              rows={bodyTable.items}
              tableType={TABLE_TYPES.REFERRALS_TABLE}
              pagination={{
                count: bodyTable.count,
                length: 10,
                onChangePage: _pagination,
              }}
              sortInfo={_sort}
              handlers={{
                handleDetailsButton: handleDetailsButton,
              }}
            />
          </TabPanel>
          <TabPanel activeTabIndex={activeTabIndex} index={1}>
            <SettingsReferrals/>

          </TabPanel>
        </Paper>
      </Box>

    </Container>
  );
};
export default Referrals;