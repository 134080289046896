import { post, get } from '../apiService';

export const postLoginUser = (body = { email: '', password: '' }) => {
  post('/auth/login/admin', body).then((res)=>console.log(res))
  return post('/auth/login/admin', body);
};
export const getLogoutUser = () => {
  return get('/auth/logout');
};
export const postUpdateToken = body => {
  return post('/auth/update-token', body);
};
export const getInfoUser = () => {
  return get('/user/me');
};
export const getProfitPercent=()=>{
 return  get('/admin-profit-settings/default')
}
export const setPercent=(size)=>{
  return post('/admin-profit-settings',{percent:size.editPercent})
}