export const createFormData = data => {
 delete data.similarNews
  const formData = new FormData();
  const newData = Object.entries(data);
  newData.forEach(item => formData.append(item[0], item[1]));
  return formData;
};
export const createJSON = data => {
  const formData = {}
  const newData = Object.entries(data);
  newData.forEach(item => formData[item[0]] = item[1]);
  return formData;
};
