import types from './moderatorTypes';

export const saveModeratorList = (payload) => ({
  type: types.GET_MODERATORS_LIST,
  payload,
});
export const saveModeratorItem = (payload) => (
  {
    type: types.GET_MODERATOR_ITEM,
    payload,
  }
);
export const clearModeratorItem = () => (
  {
    type: types.CLEAR_MODERATOR_ITEM,
  }
);
export const setErrorModeratorItem=(payload)=>(
  {
    type:types.ERROR_INFO_ITEM,
    payload
  }
)