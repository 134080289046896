import React from 'react';
import {Container} from '@material-ui/core';
import LoginForm from '../../components/Forms/LoginForm/LoginForm';

const Login = () => {
  return (<Container fixed maxWidth='sm'
                     style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
    <LoginForm />
  </Container>);
};

export default Login;