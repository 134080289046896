export const getTextTypeTransaction = (type) => {
  const typeValue = {
    FILL_UP_ADVERTISER_WALLET: 'Wallet refill',
    FILL_UP_ADVERTISEMENT_WALLET: 'Advertisement launch',
    WITHDRAW_MONEY: ' Money withdrawal',
    PAYMENT_FOR_BLOGGER_SERVICE: ' Payment for offer completion',
    REFUND_MONEY_TO_ADVERTISER: 'Offer update (rejection / failure / completion)',
    PAYMENT_PROFIT_TO_ADMIN:'Payment profit'
  };
  return typeValue[type]
};