import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

import { putNotificationSubscribe } from '../../api/notifications-service/notifications-service';

import {
  loginUser,
  setFcmToken,
} from '../../redux/store/authentication/authenticationActions';

import { firebaseConfig } from '../../constants/firebase/firebaseConfig';
import { isSupportedNotification } from './isSupportedNotification';

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();
const publicKey =
  'BL-QA0ZbFxh_S3sHU66dadP0m60y-M1k3ZW9k7BBP1rkQbxBVLifhfcPCDZ7-QDzDq-Mxtg9UGhy9YOIU2vK9gU';

export const getToken = async dispatch => {
  let fcmToken = '';

  const infoUser = JSON.parse(localStorage.getItem('userInfo'));
  const role = localStorage.getItem('role');

  try {
    fcmToken = await messaging.getToken({ vapidKey: publicKey });
    dispatch(setFcmToken(fcmToken));
  } catch (error) {
    console.log(error);
  } finally {
    if (isSupportedNotification()) {
      if (infoUser?.notification === Notification.permission || !role) return;
      putNotificationSubscribe({ fcmToken }).then(() => {
        dispatch(
          loginUser({
            ...infoUser,
            fcmToken: fcmToken,
            dateStart: new Date(),
            notification: isSupportedNotification()
              ? Notification.permission
              : '',
          }),
        );
        localStorage.setItem(
          'userInfo',
          JSON.stringify({
            ...infoUser,
            dateStart: new Date(),
            notification: isSupportedNotification()
              ? Notification.permission
              : '',
          }),
        );
      }).catch(()=>{console.log('')});
    }
  }

  return fcmToken;
};
