import {
  getLogoutUser,
  postLoginUser,
  postUpdateToken,
  getInfoUser,
} from '../../../api/authentication-servise/authentication';
import { loginUser, logoutUser, setFcmToken } from './authenticationActions';
import { errorHandler } from '../notify/notifyOperations';
import { personalInfoSelector } from '../../selectors/selectors';
import { checkNotificationPermission } from '../../../helpers/firebase/checkNotificationPermission';
import { generatorDeviceId } from '../../../utilites/generatotDeviseId/generatotDeviseId';
import { isSupportedNotification } from '../../../helpers/firebase/isSupportedNotification';

export const login = (body, history) => dispatch => {
  const deviceId = generatorDeviceId();
  postLoginUser({ ...body, deviceId })
    .then(r => {
      checkNotificationPermission(dispatch);
      dispatch(
        loginUser({
          ...r,
          dateStart: new Date(),
          notification: isSupportedNotification()
            ? Notification.permission
            : '',
        }),
      );
      localStorage.setItem(
        'userInfo',
        JSON.stringify({
          ...r,
          dateStart: new Date(),
          notification: isSupportedNotification()
            ? Notification.permission
            : '',
        }),
      );
      localStorage.setItem('role', JSON.stringify(r.role));
      localStorage.setItem('deviceId', deviceId);
      history.push('/');
      dispatch(
        setTimerUpdateToken(
          r.refreshToken,
          getEndTime(new Date(), r.expiration_date),
        ),
      );
    })
    .catch(({ message }) => {
      dispatch(errorHandler(message));
    });
};
export const logout = () => (dispatch, getState) => {
  const { fcmToken } = personalInfoSelector(getState());

  getLogoutUser()
    .then(r => {
      clearLocalTimeout();
      localStorage.removeItem('userInfo');
      localStorage.removeItem('role');
      dispatch(logoutUser());
      dispatch(setFcmToken(fcmToken));
    })
    .catch(({ message }) => {
      dispatch(errorHandler(message));
    });
};
const getEndTime = (dateStart, expiration_date) => {
  let dataEnd = new Date(dateStart).getTime();
  dataEnd -= 3 * 60 * 1000;
  dataEnd = Number.parseInt(dataEnd) + Number.parseInt(expiration_date);

  return dataEnd - new Date().getTime();
};
export const checkingStatusUser = () => dispatch => {
  let userInfo = localStorage.getItem('userInfo');
  if (userInfo) {
    const buffer = JSON.parse(userInfo);
    dispatch(loginUser(buffer));
    dispatch(checkingStatusToken(buffer));
  } else {
    dispatch(logoutUser());
  }
};
export const checkingStatusToken = data => dispatch => {
  if (data) {
    const { expiration_date, refreshToken, dateStart } = data;

    if (getEndTime(dateStart, expiration_date) > 0) {
      dispatch(
        setTimerUpdateToken(
          refreshToken,
          getEndTime(dateStart, expiration_date),
        ),
      );
    } else {
      dispatch(updateToken(refreshToken));
    }
  }
};
const setTimerUpdateToken = (refreshToken, time) => dispatch => {
  clearLocalTimeout();
  window.user_login = setTimeout(() => {
    dispatch(updateToken(refreshToken));
  }, time);
};
const clearLocalTimeout = () => {
  if (window.user_login) {
    clearTimeout(window.user_login);
    window.user_login = null;
  }
};
export const updateToken = refreshToken => (dispatch, getState) => {
  const { fcmToken } = personalInfoSelector(getState());

  const body = fcmToken ? { refreshToken, fcmToken } : { refreshToken };
  const deviceId = localStorage.getItem('deviceId');

  postUpdateToken({ ...body, deviceId })
    .then(r => {
      dispatch(
        setTimerUpdateToken(
          r.refreshToken,
          getEndTime(new Date(), r.expiration_date),
        ),
      );
      dispatch(
        loginUser({
          ...r,
          dateStart: new Date(),
          notification: isSupportedNotification()
            ? Notification.permission
            : '',
        }),
      );
      localStorage.setItem(
        'userInfo',
        JSON.stringify({
          ...r,
          dateStart: new Date(),
          notification: isSupportedNotification()
            ? Notification.permission
            : '',
        }),
      );
    })
    .catch(r => {
      clearLocalTimeout();
      localStorage.removeItem('userInfo');
      localStorage.removeItem('role');
      dispatch(logoutUser());
    });
};

export const getUserInformation = () => async (dispatch, getState) => {
  const user = personalInfoSelector(getState());

  getInfoUser().then(({ firstName, lastName }) =>
    dispatch(loginUser({ ...user, firstName, lastName })),
  ).catch(()=>{console.log('')});
};
