import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InstagramIcon from '@material-ui/icons/Instagram';

import { setModalStatus } from '../../../redux/store/modal/modalActions';
import { screensForApproveItemSelector } from '../../../redux/selectors/verification';

import VideoPlayer from '../VideoPlayer/VideoPlayer';

import './styles.css';
import { v4 as uuidv4 } from 'uuid';

const Slider = ({ slides, name ,onClickMoreInfo=()=>{}}) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const dispatch = useDispatch();
  const screensInformation = useSelector(screensForApproveItemSelector);

  const nextSlide = () => setCurrent(current === length - 1 ? 0 : current + 1);
  const prevSlide = () => setCurrent(current === 0 ? length - 1 : current - 1);

  const handleImageClick = index => {
    dispatch(
      setModalStatus({
        status: true,
        content: slides[index],
      }),
    );
  };

  const checkContentType = (slide, index) => {
    const isVideo = slide && (slide.includes('mp4') || slide.includes('mov'));
    const isVisible = index === current;
    if (isVisible && isVideo) return <VideoPlayer link={slide} />;
    if (isVisible && !isVideo)
      return (
        <img
          className='slider-img'
          style={{ objectFit: 'contain' }}
          onClick={() => handleImageClick(index)}
          src={slide}
          alt='screen'
        />
      );

    return null;
  };

  return (
    <section className='slider-container'>
      {screensInformation.userName ?
        <Box flexDirection={'column'} className='slider-title'>
          <Typography className='slider-title__accaunt-name'>
            <InstagramIcon color='primary' />{' '}
            {name ? name : screensInformation.userName}
          </Typography>
          <Button variant='outlined'
                  color='primary'
                  className='lider-button' onClick={onClickMoreInfo}>More info </Button>
        </Box> : null}
      {slides.map((slide, index) => {
        return (
          <div
            className={`slider-img-container ${
              index === current ? 'slide active' : 'slide'
            }`}
            key={uuidv4()}
          >
            {checkContentType(slide, index)}
          </div>
        );
      })}
      {slides.length > 1 ?
        <Box className='slider-buttons'>
          <Button
            variant='outlined'
            color='primary'
            className='lider-button'
            onClick={prevSlide}
          >
            <ArrowBackIcon />
          </Button>

          <Button
            variant='outlined'
            color='primary'
            className='left-arrow'
            onClick={nextSlide}
          >
            <ArrowForwardIcon />
          </Button>
        </Box>
        : null}

    </section>
  );
};

export default Slider;
