import { writeBlog, writeBlogList } from './blogActions';
import {
  deleteBlog,
  getBlogId,
  postBlog,
  putBlog,
} from '../../../api/blog-service/blog-service';
import { setNotifyStatus } from '../notify/notifyActions';
import { errorHandler } from '../notify/notifyOperations';
import { dataFormat } from '../../../utilites/globalUtils';
import {
  changeButtonLoaderStatus,
  changeGlobalLoaderStatus,
} from '../loader/loaderActions';

const { getBlogList } = require('../../../api/blog-service/blog-service');

export const updateBlogList = (limit, offset, sort) => dispatch => {
  dispatch(changeGlobalLoaderStatus(true));
  getBlogList(limit, offset, sort)
    .then(({ count, items }) => {
      const formatListBlog = items.map(
        ({ title, data, summary, category, _id }) => ({
          view: {
            data: dataFormat(data),
            title,
            category,
            summary,
          },
          id: _id,
        }),
      );
      dispatch(writeBlogList({ items: formatListBlog, count }));
    })
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};

export const removeBlog = id => dispatch => {
  deleteBlog(id)
    .then(r => {
      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Remove blog success`,
        }),
      );
      dispatch(updateBlogList());
    })
    .catch(error => dispatch(errorHandler(error.message)));
};

export const getBlogItem = id => dispatch => {
  dispatch(changeGlobalLoaderStatus(true));
  getBlogId(id)
    .then(response => {
      dispatch(writeBlog(response));
    })
    .catch(error => dispatch(errorHandler(error.message)))
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};

export const clearBlog = () => dispatch => {
  dispatch(writeBlog(null));
};

export const creatBlog = (body, history) => dispatch => {
  dispatch(changeButtonLoaderStatus(true));
  postBlog(body)
    .then(response => {
      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Added new blog success`,
        }),
      );
      history.push('/blog');
    })
    .catch(error => dispatch(errorHandler(error.message)))
    .finally(() => dispatch(changeButtonLoaderStatus(false)));
};

export const editBlog = (id, body, history) => dispatch => {
  dispatch(changeButtonLoaderStatus(true));
  putBlog(id, body)
    .then(response => {
      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Added new blog success`,
        }),
      );
      history.push('/blog');
    })
    .catch(error => dispatch(errorHandler(error.message)))
    .finally(() => dispatch(changeButtonLoaderStatus(false)));
};
