import { setNotifyStatus } from '../../redux/store/notify/notifyActions';

export const notPermissionStatus = ['denied', 'pending', 'default'];

export const notificationError = [
  'This browser does not support desktop notifications',
  'You logged in without desktop notifications',
  'You logged in with desktop notifications',
];

export const checkNotificationPermission = dispatch => {
  if (!('Notification' in window)) {
    dispatch(
      setNotifyStatus({
        type: 'warning',
        content: notificationError[0],
      }),
    );
  } else if (notPermissionStatus.includes(Notification.permission)) {
    Notification.requestPermission();
    dispatch(
      setNotifyStatus({
        type: 'warning',
        content: notificationError[1],
      }),
    );
  } else if (Notification.permission === 'granted') {
    dispatch(
      setNotifyStatus({
        type: 'success',
        content: notificationError[2],
      }),
    );
  }
};
