import {
  Typography,
  Box,
  Container,
  Tabs,
  Tab,
  Paper,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TablePrimary from '../../components/Tables/TablePrimary/TablePrimary';
import { TABLE_TYPES } from '../../components/Tables/TablePrimary/TablePrimaryHead/config';
import {
  checkingWithdrawal,
  getListWithdrawalHistory,
  getListWithdrawalRequest,
} from '../../../api/transactions-service/transactions-service';
import { dataFormat } from '../../../utilites/globalUtils';
import { changeConfirmStatus } from '../../../redux/store/confirm/confirmActions';
import { confirmationKeys } from '../../../constants/confirmationKeys';
import { useDispatch } from 'react-redux';
import { errorHandler } from '../../../redux/store/notify/notifyOperations';
import { setNotifyStatus } from '../../../redux/store/notify/notifyActions';
import TabPanel from '../../components/TabPanel/TabPanel';
import { changeGlobalLoaderStatus } from '../../../redux/store/loader/loaderActions';

const Withdrawal = () => {
  const [body, setBody] = useState({ item: [], count: 0 });
  const [bodyHistory, setBodyHistory] = useState({ item: [], count: 0 });

  const dispatch = useDispatch();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const getBodyWithdrawal = (
    limit = 10,
    offset = 0,
    sort = '',
    filter = 'all',
    amount,
  ) => {
    dispatch(changeGlobalLoaderStatus(true));
    getListWithdrawalRequest(limit, offset, filter, sort)
      .then(({ items, count }) => {
        const buffer = items.map(({ requester, createdAt, _id, amount }) => {
          return {
            view: {
              firstName: requester.firstName,
              lastName: requester.lastName,
              email: requester.email,
              createdAt: dataFormat(createdAt),
              money: amount,
            },
            id: _id,
          };
        });
        setBody({ items: buffer, count });
      })
      .catch(message => dispatch(errorHandler(message)))
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  };
  const getBodyWithdrawalHistory = (
    limit = 10,
    offset = 0,
    sort = '',
    filter = 'all',
  ) => {
    dispatch(changeGlobalLoaderStatus(true));
    getListWithdrawalHistory(limit, offset, filter, sort)
      .then(({ items, count }) => {
        const buffer = items.map(
          ({
            requester,
            createdAt,
            reviewer,
            _id,
            updatedAt,
            amount,
            approved,
          }) => {
            return {
              view: {
                createdAt: dataFormat(createdAt),
                updatedAt: dataFormat(createdAt),
                email: requester.email,
                fullName: requester.firstName + ' ' + requester.lastName,
                fullNameReviewer: reviewer.firstName + ' ' + reviewer.lastName,
                fullNameEmail: reviewer.email,
                amount: amount,
                status: approved ? 'approve' : 'rejected',
              },
              id: _id,
            };
          },
        );
        setBodyHistory({ items: buffer, count });
      })
      .catch(message => dispatch(errorHandler(message)))
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  };
  /* jshint ignore:start*/

  useEffect(() => {
    dispatch(changeGlobalLoaderStatus(true));
    getListWithdrawalRequest(10, 0, '', '')
      .then(({ items, count }) => {
        const buffer = items.map(({ requester, createdAt, _id, amount }) => {
          return {
            view: {
              firstName: requester.firstName,
              lastName: requester.lastName,
              email: requester.email,
              createdAt: dataFormat(createdAt),
              money: amount,
            },
            id: _id,
          };
        });
        setBody({ items: buffer, count });
      })
      .catch(message => dispatch(errorHandler(message)))
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  }, [dispatch]);
  /* jshint ignore:end */

  const _pagination = page => {
    getBodyWithdrawal(10, 10 * page);
  };
  const _sort = sort => {
    getBodyWithdrawal(10, 0, sort);
  };
  const _openWithdrawalHistory = () => {
    getBodyWithdrawalHistory();
    setActiveTabIndex(1);
  };
  const _paginationWithdrawalHistory = page => {
    getBodyWithdrawalHistory(10, 10 * page);
  };
  const _sortWithdrawalHistory = sort => {
    getBodyWithdrawalHistory(10, 0, sort);
  };

  const rejectedWithdrawal = id => {
    checkingWithdrawal(id, false)
      .then(() => {
        dispatch(
          setNotifyStatus({
            type: 'success',
            content: `Rejected withdrawal success`,
          }),
        );
        getBodyWithdrawal();
      })
      .catch(message => dispatch(errorHandler(message)));
  };
  const approveWithdrawal = id => {
    checkingWithdrawal(id, true)
      .then(() => {
        dispatch(
          setNotifyStatus({
            type: 'success',
            content: `Approve withdrawal success`,
          }),
        );
        getBodyWithdrawal();
      })
      .catch(message => dispatch(errorHandler(message)));
  };
  const handleApproveButton = id => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.approveWithdrawal,
        actionParams: { approveWithdrawal, id },
      }),
    );
  };

  const handleDeclineButton = id => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.rejectedWithdrawal,
        actionParams: { rejectedWithdrawal, id },
      }),
    );
  };
  const _openWithdrawal = () => {
    getBodyWithdrawal();
    setActiveTabIndex(0);
  };

  return (
    <Container maxWidth="lg" style={{overflow:'auto',padding:'0'}}  >
      <Box p={1} >
        <Typography variant="h4" gutterBottom>
          Withdrawal
        </Typography>
        <Paper square>
          <Tabs
            value={activeTabIndex}
            onChange={setActiveTabIndex}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={<Box fontWeight="fontWeightBold">Withdrawal request</Box>}
              onClick={_openWithdrawal}
            />
            <Tab
              label={<Box fontWeight="fontWeightBold">Withdrawal history</Box>}
              onClick={_openWithdrawalHistory}
            />
          </Tabs >
        </Paper>
        <Box margin={'10px'} />

        <TabPanel activeTabIndex={activeTabIndex} index={0}>
          <TablePrimary
            rows={body.items}
            tableType={TABLE_TYPES.WITHDRAWAL_TABLE}
            pagination={{
              count: body.count,
              length: 10,
              onChangePage: _pagination,
            }}
            sortInfo={_sort}
            handlers={{
              handleApproveButton: handleApproveButton,
              handleDeclineButton: handleDeclineButton,
            }}
          />
        </TabPanel>
        <TabPanel activeTabIndex={activeTabIndex} index={1}>
          <TablePrimary
            rows={bodyHistory.items}
            blocked={true}
            tableType={TABLE_TYPES.WITHDRAWAL_HISTORY_TABLE}
            pagination={{
              count: bodyHistory.count,
              length: 10,
              onChangePage: _paginationWithdrawalHistory,
            }}
            sortInfo={_sortWithdrawalHistory}
            notShowControl={true}
          />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default Withdrawal;
