import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';

const TextArea = ({ name, label, control, defaultValue, rows = 4 }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          id="outlined-multiline-static"
          label={label}
          variant="outlined"
          multiline
          fullWidth={true}
          rows={rows}
        />
      )}
    />
  );
};
export default TextArea;
