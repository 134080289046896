import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Paper,
  Tab,
  Tabs,
  Typography,
  Box,
  Container,
} from '@material-ui/core';
import TablePrimary from '../../components/Tables/TablePrimary/TablePrimary';
import TabPanel from '../../components/TabPanel/TabPanel';
import { TABLE_TYPES } from '../../components/Tables/TablePrimary/TablePrimaryHead/config';

import {
  fetchVerificationRequests,
  fetchScreensForApprove,
} from '../../../redux/store/verification/verificationOperations';

import routerBook from '../../../constants/routeBook';
import { changeConfirmStatus } from '../../../redux/store/confirm/confirmActions';
import {
  verificationRequestsSelector,
  screensForApproveSelector,
} from '../../../redux/selectors/verification';
import {
  changeScreensForApproveDataFormat,
  changeVerificationDataFormat,
} from '../../../helpers/changeVerificationDataFormat';
import { confirmationKeys } from '../../../constants/confirmationKeys';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import AprovalForm from './fortm/AprovalForm';

const Verification = ({ classes, history }) => {
  const dispatch = useDispatch();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    dispatch(fetchVerificationRequests(10, 0));
  }, [dispatch]);

  const verificationRequestsList = useSelector(verificationRequestsSelector);
  const screenForApprovList = useSelector(screensForApproveSelector);
  useEffect(()=>{
    console.log(verificationRequestsList)
  })
  const handleApproveButton = id =>
  {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.createModerator,
        actionParams: { form: () => <AprovalForm id={id} isEdit={false} /> },
      }),
    );
  }


  const handleDeclineButton = id => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.verificationAccountDeclined,
        actionParams: [id, false],
      }),
    );
  };

  const handleStatisticsDetailsButton = id => {
    history.push(`${routerBook.verification}/${id}`);
  };
  const _nextPageAccounts = (page) => {
    dispatch(fetchVerificationRequests(10, page * 10));
  };
  const _nextPageStatistics = (page) => {
    dispatch(fetchScreensForApprove(10, page * 10));
  };
  const openAccounts = () => {
    dispatch(fetchVerificationRequests(10, 0));
    setActiveTabIndex(0);
  };
  const openStatistics = () => {
    dispatch(fetchScreensForApprove(10, 0));
    setActiveTabIndex(1);
  };
  const _updatePaginationVerificationCodeList=(sort)=>{
    dispatch(fetchVerificationRequests(10, 0,sort));

  };
  const _updatePaginationVerificationAccountList=(sort)=>{
    dispatch(fetchScreensForApprove(10, 0,sort));
  };
  return (
    <Container maxWidth="lg"  style={{overflow:'auto',padding:'0'}}>
      <Box p={1} minWidth={'900px'}>
        <Typography variant="h4" gutterBottom>
          Verification
        </Typography>
        <Paper className={classes.innerContainer} square>
          <Tabs
            value={activeTabIndex}
            onChange={setActiveTabIndex}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={<Box fontWeight="fontWeightBold">Accounts</Box>}
              onClick={openAccounts}
            />
            <Tab
              label={<Box fontWeight="fontWeightBold">Statistics</Box>}
              onClick={openStatistics}
            />
          </Tabs>
        </Paper>

        <TabPanel activeTabIndex={activeTabIndex} index={0}>
          <TablePrimary
            rows={changeVerificationDataFormat(
              verificationRequestsList.items
            )}
            sortInfo={_updatePaginationVerificationCodeList}
            handlers={{
              handleApproveButton: handleApproveButton,
              handleDeclineButton: handleDeclineButton,
            }}
            tableType={TABLE_TYPES.ACCOUNTS_VERIFICATION}
            pagination={{
              count: verificationRequestsList.count,
              length: 10,
              onChangePage:_nextPageAccounts
            }}
          />
        </TabPanel>
        <TabPanel activeTabIndex={activeTabIndex} index={1} >
          <TablePrimary
            rows={changeScreensForApproveDataFormat(
              screenForApprovList.items
            )}
            sortInfo={_updatePaginationVerificationAccountList}
            handlers={{
              handleStatisticsDetailsButton: handleStatisticsDetailsButton,
            }}
            pagination={{
              count: screenForApprovList.count,
              length: 10,
              onChangePage:_nextPageStatistics
            }}
            tableType={TABLE_TYPES.STATISTICS_VERIFICATION}
          />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default withStyles(styles)(Verification);
