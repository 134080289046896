import { dataFormat } from '../utilites/globalUtils';

export const changeVerificationDataFormat = data => {
  return data.reduce((acc, current) => {
    const { verifiedCode, userName, _id, updatedAt , serviceType} = current;
    return [
      ...acc,
      {
        view: { verifiedCode, userName, serviceType, createdAt:dataFormat(updatedAt)},
        _id,
      },
    ];
  }, []);
};

export const changeScreensForApproveDataFormat = data => {
  return data.reduce((acc, current) => {
    const { userName, id, updatedAt } = current;
    return [
      ...acc,
      { view: { userName, createdAt:dataFormat(updatedAt)}, id },
    ];
  }, []);
};

export const changeScreensForApproveItemFormat = data => {
  const isEmptyObject = !Object.keys(data).length;

  if (isEmptyObject) return [];

  const {
    locationCountriesAndAgeRangeMen,
    locationCitiesAndAgeRangeAll,
    genderGeneral,
    audienceReach,
    ageFemale,
  } = data;

  return [
    locationCountriesAndAgeRangeMen,
    locationCitiesAndAgeRangeAll,
    genderGeneral,
    audienceReach,
    ageFemale,
  ];
};
