import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Button, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import {
  addDataToVerificationFormObject,
  sendVerificationForm,
} from '../../../../../redux/store/verification/verificationOperations';
import { verificationFormSelector } from '../../../../../redux/selectors/verification';
import { characteristicsFormSchema } from '../../../../../schema/verificationForms';

import { listOfAgeRange } from './config';

import Input from '../../../../ui-core/Input/Input';

import styles from './styles';

const СharacteristicForm = ({
  addDataToVerificationFormObject,
  defaultData,
                              sendVerificationForm
}) => {
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultData,
    resolver: yupResolver(characteristicsFormSchema),
  });
  const onSubmit = data => {addDataToVerificationFormObject(data)};

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography style={{ color: 'grey', margin: '10px' }}>
        The total % must be exactly 100% for each category for each category
        (all, male, female, genders)
      </Typography>
      <Box
        style={{
          backgroundColor: '#fff',
          padding: 16,
        }}
      >
        <Box m={2} item="true">
          {listOfAgeRange.map((current, index) => (
            <Box
              key={uuidv4()}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <Typography style={{ width: '100px' }}>{current.name}</Typography>
              {current.inputs.map(({ category, label }) => (
                <React.Fragment key={uuidv4()}>
                  <Grid item={true} xs={3}>
                    <Input
                      type="number"
                      control={control}
                      name={`${category}.${current.name}`}
                      label={`${label} %`}
                      defaultValue={''}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </Box>
          ))}
        </Box>

        <Box
          m={2}
          item="true"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <Typography>Genders</Typography>
          <Grid item={true} xs={3}>
            <Input
              type="number"
              control={control}
              name="genderGeneral.men"
              label={`men %`}
              defaultValue={''}
            />
          </Grid>
          <Grid item={true} xs={3}>
            <Input
              type="number"
              control={control}
              name="genderGeneral.women"
              label={`women %`}
              defaultValue={''}
            />
          </Grid>
        </Box>
        <Box
          m={2}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          item="true"
        >
          <Tooltip title="You must fill in all the fields to save">
            <span>
              <Button
                disabled={!isValid}
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                <AddIcon />
                submit
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </form>
  );
};

const mapStateToProps = state => ({
  defaultData: verificationFormSelector(state),
});

const mapDispatchToProps = {
  addDataToVerificationFormObject,
  sendVerificationForm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(СharacteristicForm));
