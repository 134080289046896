import { Controller } from 'react-hook-form';
import { CardContent, Grid, IconButton } from '@material-ui/core';

import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const UploadImage = ({ control, defaultValue, name, classes }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange } }) => (
        <CardContent>
          <Grid container justify="center" alignItems="center">
            <input
              onChange={e => {
                onChange(e.target.files[0]);
              }}
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
            />

            <label className={classes.label} htmlFor="contained-button-file">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="div"
                size="medium"
              >
                <PhotoCamera />
              </IconButton>
              <Typography color="primary">Select photo</Typography>
            </label>
          </Grid>
        </CardContent>
      )}
    />
  );
};

export default withStyles(styles)(UploadImage);
