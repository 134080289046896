import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from '../store/rootReducer';

const environment = process.env.NODE_ENV;

const middlewares = [thunk];
const devtools =
window.__REDUX_DEVTOOLS_EXTENSION__
? window.__REDUX_DEVTOOLS_EXTENSION__()
: f => f

const enhancer =
  environment === 'development'
    ? compose(applyMiddleware(...middlewares), devtools)
    : compose(applyMiddleware(...middlewares));

export const store = createStore(rootReducer, enhancer);
