const types = {
  POST_LOGIN_USER_START: 'authentication/POST_LOGIN_USER_START',
  POST_LOGIN_USER_SUCCESS: 'authentication/POST_LOGIN_USER_SUCCESS',
  POST_LOGIN_USER_ERROR: 'authentication/POST_LOGIN_USER_ERROR',

  GET_LOGOUT_START: 'authentication/GET_LOGOUT_START',
  GET_LOGOUT_SUCCESS: 'authentication/GET_LOGOUT_SUCCESS',
  GET_LOGOUT_ERROR: 'authentication/GET_LOGOUT_ERROR',

  POST_UPDATE_TOKEN_START: 'authentication/POST_UPDATE_TOKEN_START',
  POST_UPDATE_TOKEN_SUCCESS: 'authentication/POST_UPDATE_TOKEN_SUCCESS',
  POST_UPDATE_TOKEN_ERROR: 'authentication/POST_UPDATE_TOKEN_ERROR',

  SET_FCM_TOKEN: 'authentication/SET_FCM_TOKEN',
};

export default types;
