import { types } from './referralsTypes';

const initialState = {
  listReferrer: { items: [], count: 0 },
  listReferral: { items: [], count: 0 },
  allReferralInfo: {},
  statistic: {},
  percentInfo: {percent:0},
};

const referralsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_LIST_REFERRER:
      return {
        ...state,
        listReferrer: payload,
      };
    case types.LIST_REFERRAL:
      return {
        ...state,
        listReferral: payload,
      };
    case types.ALL_REFERRAL_INFO:
      return {
        ...state,
        allReferralInfo: payload,
      };
    case types.GET_STATISTICS:
      return {
        ...state,
        statistic: payload,
      };
    case types.GET_PERCENT:
      return {
        ...state,
        percentInfo: payload,
      };
    default:
      return state;
  }
};

export default referralsReducer;
