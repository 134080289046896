export const categories = [
    {
        name: "custom",
        value: "Custom",
    },
    {
        name: "common",
        value: "Common",
    },
    {
        name: "cars",
        value: "Cars",
    },
    {
        name: "games",
        value: "Games",
    },
]

export const serviceTypes = [
    {
        name: "youtube",
        value: "youtube",
    },
    {
        name: "instagram",
        value: "instagram",
    }
]