import { get, put , postFormData} from '../apiService';

export const getVerificationRequests = (limit,offset,sort) => get(`/instagram/verify-window?limit=${limit}&offset=${offset}${sort?sort:''}`);

export const getScreensForApprove = (limit, offset,sort) => get(`/instagram/screens-for-approve?limit=${limit}&offset=${offset}${sort?sort:''}`);

export const sendVerificationForm = (id, body) => {
  return put(`/instagram/screens-for-approve/${id}`, body);
};
export const sendVerificationFormWithData = (id, body) => {
  return postFormData(`/instagram/verification-with-data/${id}`, body);
};
export const getScreensForApproveById = id =>
  get(`/instagram/screens-for-approve/${id}`);

export const verifyStatusRequest = (id, body) =>
  put(`/instagram/do-verify/${id}`, body);
