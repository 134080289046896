import { get, post } from '../apiService';


export const getAllReferralProgramPartners = (limit, offset, params) => {
  return get(`/referral/referrers?limit=${limit}&offset=${offset}${params ? params : ''}` );
};
export const getInfoUserReferrer = (id, limit, offset, params) => {

  return get(`/referral/${id}/list?limit=${limit}&offset=${offset}${params ? params : ''}` );
};
export const getInfoActiveReferralPercent = () => {
  return get(`/referral/profit-settings?limit=100`);
};
export const getStatistics = () => {
  return get(`/referral/statistics/admin`);
};
export const setProfitSettings = (body) => {
  return post('/referral/profit-settings', body);
};