import { get, remove, postFormData, putFormData, post, patch } from '../apiService';

export const getCpaOffersList = () => {
  return get(`/cpaoffer`);
};
export const postCpaOffer = body => {
  return post(`/cpaoffer`, body);
};


export const deleteCpaOffer = body => {
  return patch('/cpaoffer', body)
}


