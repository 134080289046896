import PropTypes from 'prop-types';

import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
} from '@material-ui/core';

import { HEAD_CELLS } from './config';
import { v4 as uuidv4 } from 'uuid';

const TablePrimaryHead = props => {
  const { classes, order, orderBy, onRequestSort, tableType,notShowControl } = props;
  const createSortHandler = (index) => {
    HEAD_CELLS[tableType][index].statusSort = !HEAD_CELLS[tableType][index].statusSort;
    onRequestSort(HEAD_CELLS[tableType][index].sortBy + HEAD_CELLS[tableType][index].typeSort[HEAD_CELLS[tableType][index].statusSort ? 1 : 0]);
  };
  return (
    <TableHead  height={'60px'}>
      <TableRow >
        {HEAD_CELLS[tableType].map((headCell, index) => (
          headCell.isActiveSort ?
            <TableCell
              key={uuidv4()}
              align={'center'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => {
                  createSortHandler(index);
                }}
              >
                <Box fontWeight='fontWeightBold' textAlign={'right'}>
                  {headCell.label}
                </Box>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                ) : null}
              </TableSortLabel>
            </TableCell> :
            <TableCell key={uuidv4()} align={'center'}>
              <Box fontWeight='fontWeightBold'>{headCell.label}</Box>
            </TableCell>
        ))}
        {!notShowControl ?
        <TableCell align={'center'} key={'headCell.id'}>
          <Box fontWeight='fontWeightBold'>Controls</Box>
        </TableCell>:
          null
      }
      </TableRow>
    </TableHead>
  );
};

TablePrimaryHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default TablePrimaryHead;
