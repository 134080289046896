import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { withStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';

import City from './City';
import Country from './Country';
import { locationsFormSchema } from '../../../../../schema/verificationForms';

import { addDataToVerificationFormObject } from '../../../../../redux/store/verification/verificationOperations';
import { verificationFormSelector } from '../../../../../redux/selectors/verification';

import styles from './styles';

const LocationsForm = ({
                         addDataToVerificationFormObject,
                         defaultData,
                         goToNextStep
                       }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultData,
    resolver: yupResolver(locationsFormSchema),
  });
  const [cityListBuffer, setCityListBuffer] = useState([['city-1', {}]]);
  const [countriesListBuffer, setCountriesListBuffer] = useState(['country-1']);

  const [citiesList, setCitiesList] = useState({ 'city-1': {} });
  const [countriesList, setCountriesList] = useState({
    'country-1': {},
  });
  const [countCity,setCountCity]=useState(1)
  const [countCountry,setCountCountry]=useState(1)

  const onSubmit = data => {
    addDataToVerificationFormObject(data);
    goToNextStep(2)
  };

  const addNewLocation = (type) => {
    if (type === 'city') {
      setCountCity(e=>e+1)
      const lengthCityList = Object.values(citiesList).length;
      const activeList = { ...citiesList, [`city-${lengthCityList + 1}`]: {} };
      setCitiesList(activeList);
      setCityListBuffer(Object.entries(activeList));

    } else if (type === 'country') {
      setCountCountry(e=>e+1)
      const lengthCountryList = Object.values(countriesList).length;
      const activeList = {
        ...countriesList,
        [`country-${lengthCountryList + 1}`]: {},
      };
      setCountriesList(activeList);
      setCountriesListBuffer(Object.entries(activeList));

    }
  };
  const removeCity = (value) => {
    setCountCity(e=>e-1)
    const buffer = getValues().locationCities;
    buffer[value].notShow = true;
    citiesList[value].notShow = true;
    setCityListBuffer(Object.entries(citiesList));
    setCitiesList(citiesList);
    setValue(`locationCities`, buffer, { shouldValidate: true });
  };
  const removeCountry = (value) => {
    setCountCountry(e=>e-1)
    const buffer = getValues().locationCountries;
    buffer[value].notShow = true;
    countriesList[value].notShow = true;
    setCountriesListBuffer(Object.entries(countriesList));
    setCountriesList(countriesList);
    setValue(`locationCountries`, buffer, { shouldValidate: true });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography style={{ color: 'grey', margin: '10px' }}>
        The total % must be exactly 100% for each category for each category
        (cities, countries)
      </Typography>
      <Box style={{ padding: 16, backgroundColor: '#fff' }}>
        {cityListBuffer.map((item, index) => {
            return item[1].notShow ? null :
              <Box key={uuidv4()} m={2} item='true'>
                <City errors={errors} number={index + 1} control={control} removeCity={removeCity}
                      name={item[0]}
                      length={countCity} />
              </Box>;
          },
        )}
        <Box m={2}>
          <Button onClick={() => addNewLocation('city')} variant='outlined' color='primary'>
            <AddLocationIcon fontSize='small' />
            add new city
          </Button>
        </Box>
        {countriesListBuffer.map((item, index) => (
          item[1].notShow ? null :<Box key={uuidv4()} m={2} item='true'>
            <Country errors={errors} number={index + 1} control={control} removeCountry={removeCountry}
                     name={item[0]}
                     length={countCountry} />
          </Box>
        ))}
        <Box m={2}>
          <Button onClick={() => addNewLocation('country')} variant='outlined' color='primary'>
            <AddLocationIcon fontSize='small' />
            add new country
          </Button>
        </Box>
        <Box
          m={2}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          item='true'
        >
          <Tooltip title='You must fill in all the fields to save'>
            <span>
              <Button
                disabled={!isValid}
                size='large'
                type='submit'
                variant='contained'
                color='primary'
              >
                <AddIcon />
                submit
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </form>
  );
};

const mapStateToProps = state => ({
  defaultData: verificationFormSelector(state),
});

const mapDispatchToProps = {
  addDataToVerificationFormObject,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(LocationsForm));
