import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Input from '../../../ui-core/Input/Input';
import SelectInput from '../../../ui-core/Select/Select';
import TextArea from '../../../ui-core/TextArea/Input';
import TextEditor from '../../TextEditor/TextEditor';
import UploadImage from '../../../ui-core/Image/Image';
import { listOfNewsCategory } from './config';
import { blogSchema } from '../../../../validators/formValidators';
import styles from './styles';
import { createFormData } from '../../../../utilites/formDataOperations';
import {
  creatBlog,
  editBlog,
} from '../../../../redux/store/blog/blogOperations';
import { useHistory } from 'react-router-dom';
import { loaderSelector } from '../../../../redux/selectors/selectors';

const FormBlog = ({ classes, isEdit, body, id }) => {
  const { buttonLoader } = useSelector(loaderSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: isEdit ? { ...body } : {},
    resolver: yupResolver(blogSchema),
  });
  const onSubmit = data => {
    const formData = createFormData(data);
    if (isEdit) {
      dispatch(editBlog(id, formData, history));
    } else {
      dispatch(creatBlog(formData, history));
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.innerFormContainer}>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={4}>
            <SelectInput
              control={control}
              name="category"
              label="News type"
              defaultValue={''}
              optionsList={listOfNewsCategory}
            />
          </Grid>
          <Grid item xs={8}>
            <Input
              control={control}
              name="title"
              label="Title"
              defaultValue={''}
            />
            <Typography style={{ color: '#f33' }}>
              {errors?.title?.message}
            </Typography>
          </Grid>

          <Grid container item alignItems="center">
            <Grid item xs={8}>
              <TextArea
                control={control}
                name="summary"
                label="Description"
                rows={4}
              />
              <Typography style={{ color: '#f33' }}>
                {errors?.summary?.message}
              </Typography>
            </Grid>
            <Grid item container xs={2} justify="center" alignItems="center">
              <UploadImage control={control} name="banner" />
            </Grid>
            <Grid ontainer xs={2} justify="center" alignItems="center">
              {watch('banner') ? (
                <img
                  height={'113px'}
                  width={'100%'}
                  style={{objectFit: 'contain'}}
                  src={
                    typeof watch('banner') === 'string'
                      ? watch('banner')
                      : URL.createObjectURL(watch('banner'))
                  }
                  alt={''}
                />
              ) : null}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextEditor
              control={control}
              name="body"
              defaultValue={getValues().body}
            />
          </Grid>
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            style={{ height: '42px', width: '127.859px' }}
            disabled={!isValid || buttonLoader}
            startIcon={!buttonLoader && <SaveIcon />}
          >
            {buttonLoader ? <CircularProgress size={14} /> : 'Submit'}
          </Button>
        </Grid>
      </Paper>
    </form>
  );
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(withStyles(styles)(FormBlog));
