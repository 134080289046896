const styles = {
  container: {
    minWidth: '250px',
    backgroundColor: '#fff',
    minHeight: '95vh',
  },
  link: {
    listStyle: 'none',
    textDecoration: 'none',
    color: '#424242',
  },
  active: {
    fontWeight: 'bold',
    color: '#FF1B71',
  },
};

export default styles;
