import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import TablePrimaryHead from './TablePrimaryHead/TablePrimaryHead';
import ControlsCell from '../ControlsCell/ControlsCell';

import { TABLE_TYPES } from './TablePrimaryHead/config';

import styles from './styles';
import routerBook from '../../../../constants/routeBook';
import { useHistory } from 'react-router-dom';
import { croppingText } from '../../../../utilites/globalUtils';

const TablePrimary = ({
                        classes,
                        rows = [],
                        tableType = TABLE_TYPES.DEFAULT,
                        handlers,
                        notShowControl,
                        pagination = {
                          count: 10,
                          length: 5,
                          onChangePage: () => {
                          },
                        },
                        sortInfo = () => {
                        },
                      }) => {
  const [order] = useState('asc');
  const [orderBy] = useState('calories');
  const [selected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage] = useState(() => (pagination.count));
  const getContent = (name, item) => {
    return item;
    switch (tableType) {
      case TABLE_TYPES.ADS_REVIEW_LIST:
        return item.color ? <p style={{ color: item.color }}>{item.text}</p> : croppingText(item.toString(), 20);
      case TABLE_TYPES.TRANSACTION_TABLE:
        return item;
      default:
        return croppingText(item, 25);
    }
  };
  const removeContent = (name, item) =>{

  }
  const history = useHistory();
  const _openItemVerification = (id) => {
    history.push(`${routerBook.verification}/${id}`);
  };
  const _campaignsVerification = (id) => {
    history.push(`/campaigns-verification/${id}`);
  };
  const getActiveRow = () => (TABLE_TYPES.STATISTICS_VERIFICATION === tableType ||
    TABLE_TYPES.VERIFICATION_LIST === tableType || TABLE_TYPES.ADS_REVIEW_LIST === tableType ||
    TABLE_TYPES.ADS_REVIEW_COMPANY_LIST === tableType || TABLE_TYPES.CAMPAIGNS_LIST === tableType
    || TABLE_TYPES.BLOGGERS_LIST === tableType || TABLE_TYPES.INSTAGRAM_LIST === tableType ||
    TABLE_TYPES.ADVERTISERS_TABLE === tableType || TABLE_TYPES.REFERRALS_TABLE === tableType);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size={dense ? 'small' : 'medium'}
            aria-label='enhanced table'
          >
            <TablePrimaryHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={() => {
              }}
              onRequestSort={sortInfo}
              rowCount={rows.length}
              tableType={tableType}
              notShowControl={notShowControl}
            />
            <TableBody>
              {rows.map(row => {

                return (
                  <TableRow
                    key={uuidv4()}
                    hover={true}
                    variant={'fullWidth'}
                    className={getActiveRow()
                      ? classes.hoverStyle : null}
                    onClick={() => {
                      if (TABLE_TYPES.STATISTICS_VERIFICATION === tableType) {
                        _openItemVerification(row.id);
                      } else if (TABLE_TYPES.VERIFICATION_LIST === tableType) {
                        _campaignsVerification(row._id);
                      } else if (TABLE_TYPES.ADS_REVIEW_LIST === tableType) {
                        handlers.handleDetailsButton(row._id);
                      } else if (TABLE_TYPES.ADS_REVIEW_COMPANY_LIST === tableType) {
                        handlers.handleDetailsButton(row._id);
                      } else if (TABLE_TYPES.CAMPAIGNS_LIST === tableType) {
                        handlers.handleDetailsButton({
                          type: row.isRejected,
                          id: row._id,
                        });
                      } else if (TABLE_TYPES.INSTAGRAM_LIST === tableType) {
                        handlers.handleDetailsButton(row._id);
                      } else if (TABLE_TYPES.ADVERTISERS_TABLE === tableType) {
                        handlers.handleDetailsButton(row._id);
                      } else if (TABLE_TYPES.REFERRALS_TABLE === tableType) {
                        handlers.handleDetailsButton(row.id);
                      } 
                    }}
                    role='checkbox'
                    tabIndex={-1}
                  >
                    {Object.keys(row.view).map((cell, index) => {
                      return (
                        <TableCell align='center' key={uuidv4() + index}>
                          {getContent(cell, row.view[cell])}
                        </TableCell>
                      );
                    })}
                    {notShowControl ?
                      null :
                      <ControlsCell
                        handlers={handlers}
                        tableType={tableType}
                        row={row}
                      />}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          component='div'
          count={pagination.count}
          rowsPerPage={pagination.length}
          page={page}
          onChangePage={(detail, page) => {
            pagination.onChangePage(page);
            setPage(page);
          }}
          onChangeRowsPerPage={({ target }) => {
          }}
        />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(TablePrimary);
