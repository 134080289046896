import types from './modalTypes';

const initialState = {
  isVisible: false,
  type: '',
  content: '',
};

const modalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_MODAL_STATUS:
      if (payload) {
        return {
          ...state,
          isVisible: true,
          type: payload.type,
          content: payload.content,
        };
      }

      return {
        ...state,
        isVisible: false,
        type: '',
      };

    default:
      return state;
  }
};

export default modalReducer;
