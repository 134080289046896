import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Button, Box, Container, Typography } from '@material-ui/core';

import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckIcon from '@material-ui/icons/Check';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { changeConfirmStatus } from '../../../../redux/store/confirm/confirmActions';
import { confirmationKeys } from '../../../../constants/confirmationKeys';
import Slider from '../../../components/Slider/Slider';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import React, { useEffect } from 'react';
import { fetchVerificationItem } from '../../../../redux/store/company/campaignsOperations';
import InformationBlock from '../components/InformationBlock';
import { getVerificationItemSuccess } from '../../../../redux/store/company/campaignsActions';
import Input from '../../../ui-core/Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { reviewSchema } from '../../../../schema/reviewSchema';

const ContentVerification = ({ classes, isNotReview = true, statusReview }) => {
  const dispatch = useDispatch();
  const dataItem = useSelector((store) => store.campaigns.item_verification);
  const reviewData = useSelector((store) => store.campaigns.item_review);
  const { id } = useParams();
  const history = useHistory();


  useEffect(() => {
    if (isNotReview) {
      dispatch(fetchVerificationItem(id));
    }
  }, [dispatch, id, isNotReview]);
  const {
    handleSubmit, formState: { errors, isValid },
    control, getValues,
  } = useForm(
    {
      mode: 'onChange',
      resolver: yupResolver(reviewSchema),
    });
  const handleDeclineButton = id => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: isNotReview ? confirmationKeys.cancellationOfAdRequest : confirmationKeys.cancellationOfAdReviewVerified,
        actionParams: { id, value: isNotReview ? null : getValues() },
      }),
    );
  };

  const handleApproveButton = id => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: isNotReview ? confirmationKeys.AdRequestVerified : confirmationKeys.AdsReviewVerified,
        actionParams: { id, value: isNotReview ? null : getValues() },
      }),
    );
  };
  const getContent = () => {
    if (dataItem.contentType === 'IMPROVISATION') {
      return <InformationBlock {...dataItem} type={'IMPROVISATION'} isNotReview={isNotReview}
                               reviewData={reviewData} />;
    } else {
      return (<>
        <Slider slides={[dataItem.file]} />
        <InformationBlock {...dataItem} isNotReview={isNotReview} reviewData={reviewData} />
      </>);
    }
  };
  const getButtonReturn = () => (
    isNotReview ? <Button
      onClick={() => {
        history.push(`/item/0`);
        dispatch(getVerificationItemSuccess({}));
      }}
      color='primary'
      size='large'
    >
      <KeyboardBackspaceIcon className={classes.buttonIcon} /> Return to
      Tables
    </Button> : null);

  return (
    <Container maxWidth='lg'  style={{overflow:'auto'}}>

      <Box p={4} display={'flex'} flexDirection={isNotReview ? 'column' : 'column-reverse'}>
        <Box className={classes.innerContainer} flexDirection={isNotReview ? 'row' : 'column'} justifyContent={'center'}
             alignItems={'flex-start'}>
          {getButtonReturn()}
          {!isNotReview ? statusReview !== 'COMPLETED' &&
            <form onSubmit={handleSubmit()} style={{ width: '100%' }}>
              <Box display='flex' width={'100%'} flexDirection={'column'}>
                <Box marginBottom={'25px'} bgcolor={'#fff'} padding={'15px 35px 35px 35px'} borderRadius={'10px'}>
                  <Typography style={{
                    textAlign: 'center',
                    margin: '15px 0',
                    fontSize: '17px',
                    fontWeight: 700,
                    color: '#565656',
                  }}>Offer stats</Typography>
                  <Box>
                    <Input
                      control={control}
                      name='storyViews'
                      label='Stories views'
                      defaultValue={''}
                      type={'number'}
                      min='0'
                      max='10'
                    />
                    <p>{errors?.storyViews?.message}</p>
                  </Box>
                  <Box marginTop={'15px'}>
                    <Input
                      control={control}
                      name='linkFollowUps'
                      label='Link follow-ups'
                      defaultValue={''}
                    />
                    <p>{errors?.linkFollowUps?.message}</p>
                  </Box>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                  {statusReview !== 'UNRESOLVED' &&
                  <Button
                    onClick={() => handleDeclineButton(id)}
                    variant='outlined'
                    color='secondary'
                    size='large'
                    type='submit'
                    disabled={!isValid}
                  >
                    <CancelOutlinedIcon className={classes.buttonIcon} />
                    Reject with comment
                  </Button>}
                  <Button
                    onClick={() => handleApproveButton(id)}
                    variant='outlined'
                    color='primary'
                    size='large'
                    type='submit'
                    disabled={!isValid}
                    style={{
                      margin: '0 10px 0 0',
                      border: `solid 1px ${!isValid ? 'rgba(0, 0, 0, 0.26)' : '#008000'}`,
                      color: !isValid ? 'rgba(0, 0, 0, 0.26)' : '#008000',
                    }}
                  >
                    <CheckIcon className={classes.buttonIcon} />
                    Approve
                  </Button>
                </Box>
              </Box>
            </form> :
            <Box display='flex'>
              <Box className={classes.buttonContainer}>
                <Button
                  onClick={() => handleDeclineButton(id)}
                  variant='outlined'
                  color='secondary'
                  size='large'
                >
                  <CancelOutlinedIcon className={classes.buttonIcon} />
                  Reject with comment
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => handleApproveButton(id)}
                  variant='outlined'
                  color='primary'
                  size='large'
                  style={{
                    margin: '0 10px 0 0',
                    border: 'solid 1px #008000',
                    color: '#008000',
                  }}
                >
                  <CheckIcon className={classes.buttonIcon} />
                  Approve
                </Button>
              </Box>
            </Box>}
        </Box>
        <Box display='flex' justifyContent='space-between' flexDirection={isNotReview ? 'row' : 'column-reverse'}>
          {getContent()}
        </Box>
      </Box>
    </Container>
  );
};

export default withStyles(styles)(ContentVerification);
