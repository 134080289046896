export const generatorDeviceId = () => {
 const deviceId=localStorage.getItem('deviceId')
  if(!deviceId) {
    const newDeviceId = String.fromCharCode(Number.parseInt((15 * Math.random())) + 65) + (Math.random() * 100000000000000000) + String.fromCharCode(Number.parseInt((15 * Math.random())) + 65)
    localStorage.setItem('deviceId',newDeviceId)
    return newDeviceId
  }else {
    return deviceId
  }
};
