import * as api from '../../../api/notifications-service/notifications-service';

import { userNotificationSelector } from '../../selectors/selectors';
import { changeContentLoaderStatus } from '../loader/loaderActions';
import { errorHandler } from '../notify/notifyOperations';
import {
  getUserNotificationsSuccess,
  putUserNotificationIsReadSuccess,
} from './notificationActions';

import { handleNotificationRedirect } from '../../../helpers/firebase/handleNotificationRedirect';

export const fetchUserNotifications = limit => dispatch => {
  dispatch(changeContentLoaderStatus(true));

  api
    .getUserNotifications(limit)
    .then(notifications => {
      dispatch(getUserNotificationsSuccess(notifications));
    })
    .catch(({ message }) => {
      dispatch(errorHandler(message));
    })
    .finally(() => dispatch(changeContentLoaderStatus(false)));
};

export const editNotifcationIsRead =
  (id, title, isRead, history) => (dispatch, getState) => {
    const notifications = userNotificationSelector(getState());

    if (isRead === 'READ') {
      handleNotificationRedirect(title, history);
      return;
    }

    return api
      .putIsReadNotification(id)
      .then(() => {
        const updateMessages = notifications.messages.map(item =>
          item._id === id ? { ...item, isRead: 'READ' } : item,
        );
        const updateNotifications = {
          ...notifications,
          count: notifications.count,
          messages: updateMessages,
          unreadCount: notifications.unreadCount - 1,
        };

        dispatch(putUserNotificationIsReadSuccess(updateNotifications));
        handleNotificationRedirect(title, history);
      })
      .catch(({ message }) => dispatch(dispatch(errorHandler(message))));
  };
