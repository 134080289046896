import './styles.css';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getBloggerIngo } from '../../../api/campaigns-servise/campaigns-servise';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { errorHandler } from '../../../redux/store/notify/notifyOperations';
import { setNotifyStatus } from '../../../redux/store/notify/notifyActions';
import Input from '../../ui-core/Input/Input';
import { useForm } from 'react-hook-form';
import { bloggerCardSchema } from './shema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { putInstagramInfoBlogger } from '../../../api/bloggers-service/bloggers-service';
import {
  changeButtonLoaderStatus,
  changeGlobalLoaderStatus,
} from '../../../redux/store/loader/loaderActions';
import { loaderSelector } from '../../../redux/selectors/selectors';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import paywallAvatar  from './../../../assets/images/paywallAvatar.svg'
const InstagramEdit = () => {
  const [body, setBody] = useState([]);
  const [statisticsAge, setStatisticsAge] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const { id } = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [gender, setGander] = useState(null);
  const dispatch = useDispatch();
  const history=useHistory()
  const { buttonLoader } = useSelector(loaderSelector);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(bloggerCardSchema),
  });

  const getInfo = () => {
    const arr = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65'];
    getBloggerIngo(id).then(r => {
      setStatisticsAge(r.statistics);
      setCitiesList(r.accountId?.locationCities);
      setCountriesList(r.accountId?.locationCountries);
      setGander(r.accountId?.genderGeneral);
      const statistics = arr.map(item =>
        r.statistics.reduce((acum, b) => {
          if (b.ageCategory === item) {
            acum[b.gender + 'Score'] = b.score;
            acum.ageCategory = b.ageCategory;
            acum.id = b._id;
          }
          return acum;
        }, {}),
      );
      reset(
        { ...r.accountId, defValue: r.accountId },
        {
          keepErrors: true,
          keepDirty: true,
          keepIsSubmitted: true,
          keepTouched: true,
          keepIsValid: true,
          keepSubmitCount: true,
        },
      );
      setStatisticsAge(statistics);
      setBody({ ...r });
    });
  };

  useEffect(() => {
    const arr = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65'];
    dispatch(changeGlobalLoaderStatus(true));
    getBloggerIngo(id)
      .then(r => {
        setStatisticsAge(r.statistics);
        setCitiesList(r.accountId?.locationCities);
        setCountriesList(r.accountId?.locationCountries);
        setGander(r.accountId?.genderGeneral);
        const statistics = arr.map(item =>
          r.statistics.reduce((acum, b) => {
            if (b.ageCategory === item) {
              acum[b.gender + 'Score'] = b.score;
              acum.ageCategory = b.ageCategory;
              acum.id = b._id;
            }
            return acum;
          }, {}),
        );
        reset(
          { ...r.accountId, defValue: r.accountId },
          {
            keepErrors: true,
            keepDirty: true,
            keepIsSubmitted: true,
            keepTouched: true,
            keepIsValid: true,
            keepSubmitCount: true,
          },
        );
        setStatisticsAge(statistics);
        setBody({ ...r });
      })
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  }, [id, reset]);
  const _addCity = () => {
    const buff = [...citiesList];
    buff.push({ percent: 1, cityName: '' });
    setCitiesList(buff);
  };
  const _addCountries = () => {
    const buff = [...countriesList];
    buff.push({ percent: 1, countryName: '' });
    setCountriesList(buff);
  };
  const validateCountries = item => {
    return item.reduce(
      (acum, { percent, countryName }) => {
        if (countryName.length < 1) {
          acum.validName = false;
          acum.invalidCity = countryName;
        }
        acum.allPercent += Number.parseInt(percent);
        return acum;
      },
      { validName: true, allPercent: 0 },
    );
  };
  const validateCity = item => {
    return item.reduce(
      (acum, { percent, cityName }) => {
        if (cityName.length < 3) {
          acum.validName = false;
          acum.invalidCity = cityName;
        }
        acum.allPercent += Number.parseInt(percent);
        return acum;
      },
      { validName: true, allPercent: 0 },
    );
  };
  const getStatisticAge = () =>
    statisticsAge.reduce(
      (acum, { ageCategory, ageFemaleScore, ageMaleScore, allScore }) => {
        acum.push({ ageCategory, gender: 'all', score: allScore });
        acum.push({ ageCategory, gender: 'ageFemale', score: ageFemaleScore });
        acum.push({ ageCategory, gender: 'ageMale', score: ageMaleScore });
        return acum;
      },
      [],
    );
  const _onClickSave = body => {
    const isValid = statisticsAge.reduce(
      (acum, item) => {
        acum.all += Number.parseInt(item.allScore);
        acum.mean += Number.parseInt(item.ageMaleScore);
        acum.female += Number.parseInt(item.ageFemaleScore);
        return acum;
      },
      { all: 0, mean: 0, female: 0 },
    );
    let statistics = [];
    statisticsAge.forEach(
      ({ allScore, ageCategory, id, ageFemaleScore, ageMaleScore }) => {
        statistics.push({
          ageCategory: ageCategory,
          gender: 'all',
          score: allScore,
        });
        statistics.push({
          ageCategory: ageCategory,
          gender: 'ageFemale',
          score: ageFemaleScore,
        });
        statistics.push({
          ageCategory: ageCategory,
          gender: 'ageMale',
          score: ageMaleScore,
        });
      },
    );
    const countriesValidInfo = validateCountries(countriesList);
    const citiesValidInfo = validateCity(citiesList);
    const isValidTable =
      isValid.all <= 100 && isValid.mean <= 100 && isValid.female <= 100;
    const isValidName =
      citiesValidInfo.validName && countriesValidInfo.validName;
    const isValidAge =
      citiesValidInfo.allPercent <= 100 && countriesValidInfo.allPercent <= 100;
    if (isValidAge && isValidTable && isValidName) {
      dispatch(changeButtonLoaderStatus(true));
      putInstagramInfoBlogger(id, {
        statistic: getStatisticAge(),
        audienceReach: body.audienceReach,
        accountQuality: body.accountQuality,
        storiesViews: body.storiesViews,
        recommendedPrice: body.recommendedPrice,
        locationCities: citiesList,
        locationCountries: countriesList,
        genderGeneral: gender,
      })
        .then(e => {
          dispatch(
            setNotifyStatus({
              type: 'success',
              content: `Instagram edit success`,
            }),
          );
          getInfo();
        })
        .catch(({ message }) => {
          dispatch(errorHandler(message));
        })
        .finally(() => dispatch(changeButtonLoaderStatus(false)));
    } else {
      if (!isValidName) {
        setErrorMessage('Error name  cities or countries');
        clearMessage();
      } else if (!isValidAge || !isValidTable) {
        setErrorMessage(
          'The total % must be exactly 100% for each category for each category (cities, countries)',
        );
        clearMessage();
      }
    }
  };
  const clearMessage = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 10000);
  };
  const getTable = () => {
    return (
      <table border="1" className="instagram-edit__right__table">
        <tbody>
          <tr>
            <th>Age</th>
            <th>All</th>
            <th>Male</th>
            <th>Female</th>
          </tr>
          {statisticsAge?.map(
            (
              { ageCategory, ageMaleScore, ageFemaleScore, allScore },
              index,
            ) => (
              <tr key={uuidv4()}>
                <td>
                  {ageCategory === '65' ? ageCategory + ' +' : ageCategory}
                </td>
                <td>
                  <input
                    className="instagram-edit-input-age"
                    type={'number'}
                    min="0"
                    max="100"
                    defaultValue={allScore}
                    onChange={({ target }) => {
                      setStatisticsAge(e => {
                        e[index].allScore = Number.parseInt(target.value);
                        return e;
                      });
                    }}
                  />
                </td>
                <td>
                  <input
                    className="instagram-edit-input-age"
                    type={'number'}
                    min="0"
                    max="100"
                    defaultValue={ageMaleScore}
                    onChange={({ target }) => {
                      setStatisticsAge(e => {
                        e[index].ageMaleScore = Number.parseInt(target.value);
                        return e;
                      });
                    }}
                  />
                </td>
                <td>
                  <input
                    className="instagram-edit-input-age"
                    type={'number'}
                    min="0"
                    max="100"
                    defaultValue={ageFemaleScore}
                    onChange={({ target }) => {
                      setStatisticsAge(e => {
                        e[index].ageFemaleScore = Number.parseInt(target.value);
                        return e;
                      });
                    }}
                  />
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    );
  };
  const deleteCity = index => {
    setCitiesList(e => {
      return e.reduce((acum, item, i) => {
        if (index !== i) {
          acum.push(item);
        }
        return acum;
      }, []);
    });
  };
  const deleteCountry = index => {
    setCountriesList(e => {
      return e.reduce((acum, item, i) => {
        if (index !== i) {
          acum.push(item);
        }
        return acum;
      }, []);
    });
  };
  return (
    <form
      onSubmit={handleSubmit(_onClickSave)}
      className={'instagram-edit-form'}
    >
      <div className={'instagram-edit-form-button'}>
      <Button
        onClick={() =>history.push('/bloggers')}
        color="primary"
        size="large"
      >
        <KeyboardBackspaceIcon /> Return to bloggers table
      </Button>
      </div>
      <div className="instagram-edit__container">

        {errorMessage ? (
          <p className={'instagram-edit__container-error'}>{errorMessage}</p>
        ) : (
          ''
        )}
        <div className="instagram-edit__body">
          <div className="instagram-edit">
            <div className="instagram-edit-head ">
              <img
                crossOrigin="anonymous"
                src={body.accountId?.avatar?body.accountId.avatar:paywallAvatar}

                className="instagram-edit-head__img"
                alt="blooger"
              />
              <p className="instagram-edit-head__profile">
                {body.accountId?.userName}
              </p>
              <p className="instagram-edit-head__category">
                {body.accountId?.accountType}
              </p>
            </div>
            <div className="instagram-edit-main">
              <div className="instagram-edit__inner-container">
                <div className="instagram-edit-main__characteristic">
                  <div className="instagram-edit-main__characteristic-item">
                    <p className="instagram-edit-main__characteristic-desc">
                      Followers
                    </p>
                    <p className="instagram-edit-main__characteristic-value">
                      {body.accountId?.subscribersCount}
                    </p>
                  </div>
                </div>
                <div className="instagram-edit-main__characteristic">
                  <div className="instagram-edit-main__characteristic-item">
                    <p className="instagram-edit-main__characteristic-desc">
                      Audience reach
                    </p>

                    <Input
                      control={control}
                      type="number"
                      name="audienceReach"
                      className="instagram-edit-main__characteristic-value_input"
                    />
                  </div>
                  <div className={'instagram-edit-errors-container'}>
                    <p className={'instagram-edit-errors'}>
                      {errors.audienceReach ? errors.audienceReach.message : ''}
                    </p>
                  </div>
                </div>

                <div className="instagram-edit-main__characteristic">
                  <div className="instagram-edit-main__characteristic-item">
                    <p className="instagram-edit-main__characteristic-desc">
                      Story views
                    </p>

                    <Input
                      control={control}
                      type="number"
                      name="storiesViews"
                      className="instagram-edit-main__characteristic-value_input"
                    />
                  </div>
                  <div className={'instagram-edit-errors-container'}>
                    <p className={'instagram-edit-errors'}>
                      {errors.storiesViews ? errors.storiesViews.message : ''}
                    </p>
                  </div>
                </div>
                <div className="instagram-edit-main__characteristic">
                  <div className="instagram-edit-main__characteristic-item">
                    <p className="instagram-edit-main__characteristic-desc">
                      Account quality
                    </p>

                    <Input
                      control={control}
                      name="accountQuality"
                      type="number"
                      className="instagram-edit-main__characteristic-value_input"
                    />
                    <p className="instagram-edit-main__characteristic-value">
                      {' '}
                      /10
                    </p>
                  </div>
                  <div className={'instagram-edit-errors-container'}>
                    <p className={'instagram-edit-errors'}>
                      {errors.accountQuality
                        ? errors.accountQuality.message
                        : ''}
                    </p>
                  </div>
                </div>
                <div className="instagram-edit-main__characteristic">
                  <div className="instagram-edit-main__characteristic-item">
                    <p className="instagram-edit-main__characteristic-desc">
                      Stories per day
                    </p>
                    <p className="instagram-edit-main__characteristic-value-cust">
                      {body.accountId?.storiesPerDay}
                    </p>
                  </div>
                </div>
                <div className="instagram-edit-main__characteristic">
                  <div className="instagram-edit-main__characteristic-item">
                    <p className="instagram-edit-main__characteristic-desc">
                      Price per 1k views
                    </p>
                    <p className="instagram-edit-main__characteristic-value">
                      {body.accountId?.priceForOneThousand}
                    </p>
                  </div>
                </div>

                <div className="instagram-edit-main__characteristic">
                  <div className="instagram-edit-main__characteristic-item">
                    <p className="instagram-edit-main__characteristic-desc">
                      Price per post
                    </p>
                    <p className="instagram-edit-main__characteristic-value">
                      {body.accountId?.pricePerPost}
                    </p>
                  </div>
                </div>

                <div className="instagram-edit-main__characteristic">
                  <div className="instagram-edit-main__characteristic-item">
                    <p className="instagram-edit-main__characteristic-desc">
                      Recommended price
                    </p>

                    <Input
                      control={control}
                      value={body.accountId?.recommendedPrice}
                      name="recommendedPrice"
                      type="number"
                      className="instagram-edit-main__characteristic-value_input"
                    />
                  </div>
                  <div className={'instagram-edit-errors-container'}>
                    <p className={'instagram-edit-errors'}>
                      {errors.recommendedPrice
                        ? errors.recommendedPrice.message
                        : ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="instagram-edit__right">
            {getTable()}
            <div className="instagram-edit__right__item">
              <p className="instagram-edit__right__item-title">Gender</p>
              <div className="instagram-edit__right__item-container">
                <p className="instagram-edit__right__item-sub instagram-edit-no-border">
                  Male
                </p>
                <input
                  className="instagram-edit__right__item-sub "
                  defaultValue={gender?.men}
                  onChange={({ target }) => {
                    setGander(e => {
                      e.men = target.value - 0;
                      return e;
                    });
                  }}
                />
              </div>
              <div className="instagram-edit__right__item-container ">
                <p className="instagram-edit__right__item-sub instagram-edit-no-border">
                  Female
                </p>
                <input
                  className="instagram-edit__right__item-sub "
                  defaultValue={gender?.women}
                  onChange={({ target }) => {
                    setGander(e => {
                      e.women = target.value - 0;
                      return e;
                    });
                  }}
                />
              </div>
            </div>
            <div className="instagram-edit__right__item">
              <p className="instagram-edit__right__item-title">Top cities</p>
              {citiesList.map(({ cityName, percent }, index) => (
                <div
                  key={uuidv4()}
                  className="instagram-edit__right__item-container"
                >
                  <div className="instagram-edit-conteiner">
                    <div
                      className="instagram-edit-conteiner-button"
                      onClick={() => {
                        deleteCity(index);
                      }}
                    >
                      Delete
                    </div>
                    <input
                      className="instagram-edit__right__item-sub"
                      type="text"
                      placeholder={'please input name city'}
                      defaultValue={cityName}
                      min="0"
                      max="100"
                      onChange={({ target }) => {
                        setCitiesList(e => {
                          e[index].cityName = target.value;
                          return e;
                        });
                      }}
                    />
                  </div>
                  <input
                    className="instagram-edit__right__item-sub"
                    type="number"
                    defaultValue={percent}
                    min="0"
                    max="100"
                    onChange={({ target }) => {
                      setCitiesList(e => {
                        e[index].percent = Number.parseInt(target.value);
                        return e;
                      });
                    }}
                  />
                </div>
              ))}
              <button type="button" onClick={_addCity}>
                add City
              </button>
            </div>
            <div className="instagram-edit__right__item">
              <p className="instagram-edit__right__item-title">Top countries</p>
              {countriesList.map(({ countryName, percent }, index) => (
                <div
                  className="instagram-edit__right__item-container"
                  key={uuidv4()}
                >
                  <div className="instagram-edit-conteiner">
                    <div
                      className="instagram-edit-conteiner-button"
                      onClick={() => {
                        deleteCountry(index);
                      }}
                    >
                      Delete
                    </div>
                    <input
                      className="instagram-edit__right__item-sub"
                      type="text"
                      defaultValue={countryName}
                      min="0"
                      max="100"
                      placeholder={'please input name country'}
                      onChange={({ target }) => {
                        setCountriesList(e => {
                          e[index].countryName = target.value;
                          return e;
                        });
                      }}
                    />
                  </div>

                  <input
                    className="instagram-edit__right__item-sub"
                    type="number"
                    defaultValue={percent}
                    min="0"
                    max="100"
                    onChange={({ target }) => {
                      setCountriesList(e => {
                        e[index].percent = Number.parseInt(target.value);
                        return e;
                      });
                    }}
                  />
                </div>
              ))}
              <button type="button" onClick={_addCountries}>
                add Country
              </button>
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          style={{ height: '38px' }}
          disabled={buttonLoader}
          startIcon={!buttonLoader && <SaveIcon />}
        >
          {buttonLoader ? <CircularProgress size={14} /> : 'save'}
        </Button>
      </div>
    </form>
  );
};

export default InstagramEdit;
