import { getAdvertiserList } from '../../../api/advertisers-service/advertisers-service';
import { getAdvertisers } from './advertisersActions';
import { errorHandler } from '../notify/notifyOperations';
import { dataFormat } from '../../../utilites/globalUtils';
import { changeGlobalLoaderStatus } from '../loader/loaderActions';

export const getAdvertiserBody = (limit, offset, sort) => dispatch => {
  dispatch(changeGlobalLoaderStatus(true));
  getAdvertiserList(limit, offset, sort)
    .then(({ items, count }) => {
      const body = items.map(
        ({ firstName, lastName, email, _id, createdAt }) => ({
          view: {
            firstName,
            lastName,
            createdAt: dataFormat(createdAt),
            email,
          },
          _id,
        }),
      );
      dispatch(getAdvertisers({ items: body, count }));
    })
    .catch(({ message }) => {
      dispatch(errorHandler(message));
    })
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};
