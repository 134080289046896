import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getAdvertiser } from '../../../api/advertisers-service/advertisers-service';
import './style.css';
import { dataFormat } from '../../../utilites/globalUtils';
import { changeConfirmStatus } from '../../../redux/store/confirm/confirmActions';
import { confirmationKeys } from '../../../constants/confirmationKeys';
import { useDispatch } from 'react-redux';
import Transactions from '../transactions/Transactions';
import { changeGlobalLoaderStatus } from '../../../redux/store/loader/loaderActions';

import TopUpBalance from './topUpBalance/topUpBalance';
import { useGetUserRole } from '../../../customHooks/getUserRole';

const AdvertiserPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const [body, setBody] = useState({});
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const roll = useGetUserRole();
  const updateInfoAdvertiser = () => {
    dispatch(changeGlobalLoaderStatus(true));
    getAdvertiser(id)
      .then(r => {
        setBody(r);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  };
  useEffect(() => {
    updateInfoAdvertiser();
  }, [id]);
  const _controlOpenHistory = () => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.createModerator,
        actionParams: { form: () => <Transactions idAd={body._id} /> },
      }),
    );
  };
  const _openAddBalance = () => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.createModerator,
        actionParams: {
          form: () => <TopUpBalance idAd={body._id} updateInfo={updateInfoAdvertiser} />,
        },
      }),
    );
  };
  return (
    <div className='advertiser-page'>
      {error ? (
        <p className={'error_page_connect'}>Page not found </p>
      ) : (
        <>
          <div className='advertiser-page-head'>
            <button
              className='advertiser-page-back'
              onClick={() => {
                history.goBack();
              }}
            >
              <svg
                className='MuiSvgIcon-root ContentVerification-buttonIcon-49'
                focusable='false'
                viewBox='0 0 24 24'
                aria-hidden='true'
              >
                <path d='M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z' />
              </svg>
              Return to Tables
            </button>
          </div>
          <div className='advertiser-page__container'>
            <div className='advertiser-page__item'>
              <p className='advertiser-page__item-description'>First name:</p>
              <p className='advertiser-page__item-value'>{body?.firstName}</p>
            </div>
            <div className='advertiser-page__item'>
              <p className='advertiser-page__item-description'>Last name:</p>
              <p className='advertiser-page__item-value'>{body?.lastName}</p>
            </div>
            <div className='advertiser-page__item'>
              <p className='advertiser-page__item-description'>Email:</p>
              <p className='advertiser-page__item-value'>{body?.email}</p>
            </div>
            <div className='advertiser-page__item'>
              <p className='advertiser-page__item-description'>
                Favorite bloggers:
              </p>
              <p className='advertiser-page__item-value'>
                {body?.favoriteInstagramAccount?.length}
              </p>
            </div>
            <div className='advertiser-page__item'>
              <p className='advertiser-page__item-description'>Is confirmed:</p>
              <p className='advertiser-page__item-value'>
                {body?.isConfirmed ? 'Yes' : 'No'}
              </p>
            </div>
            <div className='advertiser-page__item'>
              <p className='advertiser-page__item-description'>
                Active advertisements:
              </p>
              <p className='advertiser-page__item-value'>
                {body?.countsOfAllAdvertisement?.activeCount}
              </p>
            </div>
            <div className='advertiser-page__item'>
              <p className='advertiser-page__item-description'>
                All advertisements:
              </p>
              <p className='advertiser-page__item-value'>
                {body?.countsOfAllAdvertisement?.count}
              </p>
            </div>
            {roll === 'SUPER_ADMIN' && <div className='advertiser-page__item'>
              <p className='advertiser-page__item-description'>Balance:</p>
              <button onClick={_openAddBalance}>Top up balance</button>
              <p className='advertiser-page__item-value'>${body?.budget}</p>
            </div>}

            <div className='advertiser-page__item'>
              <p className='advertiser-page__item-description'>
                Registration date:
              </p>
              <p className='advertiser-page__item-value'>
                {body?.createdAt ? dataFormat(body.createdAt) : '------'}
              </p>
            </div>
            <div className='advertiser-page__item'>
              <p className='advertiser-page__item-description'>Update date:</p>
              <p className='advertiser-page__item-value'>
                {body?.updatedAt ? dataFormat(body.updatedAt) : '------'}
              </p>
            </div>

            {roll === 'SUPER_ADMIN' && <div className='advertiser-page__item'>
              <p className={`advertiser-page__item-description`}>
                Transaction History
              </p>
              <button
                onClick={_controlOpenHistory}
                className={`advertiser-page__item-button `}
              >
                Open
              </button>
            </div>}
          </div>
        </>
      )}
    </div>
  );
};

export default AdvertiserPage;
