import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText, List } from '@material-ui/core';

import { navigationConfig } from './config';
import styles from './styles';
import { useGetUserRole } from '../../../customHooks/getUserRole';
import { useSelector } from 'react-redux';
import { loaderSelector } from '../../../redux/selectors/selectors';
import { v4 as uuidv4 } from 'uuid';

const NavigationBar = ({ classes }) => {
  const { globalLoader } = useSelector(loaderSelector);
  const userRole = useGetUserRole();
  const checkingAccessUser = role => role.some(item => item === userRole);
  return (
    <div
      style={{ zIndex: `${globalLoader ? '10002' : '1'}` }}
      className={clsx(classes.container)}
    >
      <List>
        {navigationConfig.map(({ text, Icon, link, role }, index) => {
          if (checkingAccessUser(role)) {
            return (
              <NavLink
                key={uuidv4()}
                activeClassName={clsx(classes.active)}
                className={clsx(classes.link)}
                to={link}
              >
                <ListItem button key={text}>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              </NavLink>
            );
          }
          return null;
        })}
      </List>
    </div>
  );
};

export default withStyles(styles)(NavigationBar);
