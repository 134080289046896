import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getModeratorItem } from '../../../../redux/store/moderator/moderatorOperation';
import { useDispatch, useSelector } from 'react-redux';
import ComponentInfoUser from '../../../components/componentInfoUser/componentInfoUser';


const ModeratorInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
   const moderatorItem=useSelector(state=>state.moderator.moderationItem)
  useEffect(() => {
    dispatch(getModeratorItem(id));
  }, [dispatch, id]);
  return <ComponentInfoUser list={moderatorItem}/>;
};

export default ModeratorInfo;