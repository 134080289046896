import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Paper,
  Typography,
  Box,
  Container,
  Tab,
  Tabs,
  Button,
  InputAdornment,
  IconButton,
  TextField,
} from '@material-ui/core';
import { TABLE_TYPES } from '../../components/Tables/TablePrimary/TablePrimaryHead/config';
import TablePrimary from '../../components/Tables/TablePrimary/TablePrimary';
import TabPanel from '../../components/TabPanel/TabPanel';
import { changeConfirmStatus } from '../../../redux/store/confirm/confirmActions';
import { confirmationKeys } from '../../../constants/confirmationKeys';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import {
  fetchAddForReviewList,
  fetchGetOfferList,
  fetchVerificationList,
  getAdsByPublicId,
} from '../../../redux/store/company/campaignsOperations';
import { useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import SearchIcon from '@material-ui/icons/Search';
import { getReviewList } from '../../../api/campaigns-servise/campaigns-servise';

const Campaigns = ({ history, classes }) => {
  const { activeStep } = useParams();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [reviewTable, setReviewTable] = useState('ad_list');
  const [publicId, setPublicId] = useState('');

  const dispatch = useDispatch();
  const campaigns = useSelector(state => state.campaigns.campaigns);

  const activeListVerification = useSelector(
    state => state.campaigns.verificationList,
  );
  const activeListReview = useSelector(state => state.campaigns.reviewList);
  const offerList = useSelector(state => state.campaigns.offerList);
  const [idOffer, setIdOffer] = useState('');
  useEffect(() => {
    if (+activeStep === 0) {
      dispatch(fetchVerificationList(0, 'Advertisements_verification'));
      setActiveTabIndex(+activeStep);
    } else if (+activeStep === 2) {
      dispatch(fetchAddForReviewList(0));
      setActiveTabIndex(+activeStep);
    } else if (+activeStep === 1) {
      dispatch(fetchVerificationList(0, 'advertisements'));
      setActiveTabIndex(+activeStep);
    }
  }, [dispatch, activeStep]);
  const handleDetailsButton = id => {
    history.push(`/campaigns-verification/${id}`);
  };
  const handleDeclineButton = id => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.cancellationOfAdRequest,
        actionParams: [id, false],
      }),
    );
  };
  const handleDetailsReviewButton = id => {
    setIdOffer(id);
    dispatch(fetchGetOfferList(id));
    setReviewTable('review_list');
  };

  const handleDetailsReviewItem = id => {
    history.push(`/review/${id}`);
    getReviewList();
  };

  const handleDeclineReviewButton = id => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.cancellationOfAdReviewVerified,
        actionParams: [id, false],
      }),
    );
  };
  const _selectCampaigns = page => {
    setActiveTabIndex(1);
    dispatch(fetchVerificationList(page, 'advertisements'));
  };
  const _selectContentVerification = page => {
    setActiveTabIndex(0);
    dispatch(fetchVerificationList(page, 'Advertisements_verification'));
  };
  const _selectAasReview = page => {
    setActiveTabIndex(2);
    dispatch(fetchAddForReviewList(page));
  };
  const _setOfferList = page => {
    dispatch(fetchGetOfferList(idOffer, page));
    setReviewTable('review_list');
  };
  const handleDetailsButtonCampaigns = ({ type, id }) => {
    if (type) {
      history.push(`/campaigns-verification/${id}`);
    } else {
      history.push(`/company-info/${id}`);
    }
  };
  const _updatePaginationAdvertisements = e => {
    dispatch(fetchVerificationList(0, 'advertisements', e));
  };
  const _updatePaginationAdvertisementsRew = e => {
    dispatch(fetchVerificationList(0, 'Advertisements_verification', e));
  };
  const _updatePaginationResultsRew = e => {
    dispatch(fetchAddForReviewList(0, e));
  };
  const _updatePaginationCompanyList = e => {
    dispatch(fetchGetOfferList(idOffer, e));
  };
  const regexp = /^[0-9\b]+$/;
  const _handleSearchByPublicId = () => {
    publicId && dispatch(getAdsByPublicId(publicId, history));
    setPublicId('');
  };
  return (
    <Container maxWidth="lg"  style={{overflow:'auto',padding:'0'}}>
      <Box p={1} minWidth={'1024px'}>
        <Typography variant="h4" gutterBottom>
          Advertisements
        </Typography>
        <Paper style={{ margin: '0 0 10px 0' }} square>
          <Tabs
            value={activeTabIndex}
            onChange={setActiveTabIndex}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={
                <Box fontWeight="fontWeightBold">Advertisement review</Box>
              }
              onClick={_selectContentVerification}
            />
            <Tab
              label={<Box fontWeight="fontWeightBold">Draft/rejected</Box>}
              onClick={_selectCampaigns}
              color="primary"
            />

            <Tab
              label={<Box fontWeight="fontWeightBold">Results review</Box>}
              onClick={_selectAasReview}
            />
          </Tabs>
        </Paper>
        <TabPanel activeTabIndex={activeTabIndex} index={0}>
          <TablePrimary
            pagination={{
              count: activeListVerification.count,
              length: 10,
              onChangePage: _selectContentVerification,
            }}
            sortInfo={_updatePaginationAdvertisementsRew}
            rows={activeListVerification.items}
            handlers={{
              handleDetailsButton: handleDetailsButton,
              handleDeclineButton: handleDeclineButton,
            }}
            tableType={TABLE_TYPES.VERIFICATION_LIST}
          />
        </TabPanel>
        <TabPanel activeTabIndex={activeTabIndex} index={1} color="primary">
          <TablePrimary
            pagination={{
              count: campaigns.count,
              length: 10,
              onChangePage: _selectCampaigns,
            }}
            rows={campaigns.items}
            sortInfo={_updatePaginationAdvertisements}
            handlers={{
              handleDetailsButton: handleDetailsButtonCampaigns,
              handleDeclineButton: handleDeclineButton,
            }}
            tableType={TABLE_TYPES.CAMPAIGNS_LIST}
          />
        </TabPanel>
        <TabPanel activeTabIndex={activeTabIndex} index={2}>
          {reviewTable === 'ad_list' ? (
            <>
              <Box display="flex" justifyContent="flex-end" paddingBottom={3}>
                <TextField
                  onChange={({ target: { value } }) =>
                    value && regexp.test(value) && setPublicId(value)
                  }
                  value={publicId}
                  style={{
                    padding: '7px 0',
                    width: '250px',
                  }}
                  label="Offers search by public ID"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          onClick={_handleSearchByPublicId}
                          style={{ margin: '0 0 12px 0' }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <TablePrimary
                pagination={{
                  count: activeListReview.count,
                  length: 10,
                  onChangePage: _selectAasReview,
                }}
                rows={activeListReview.items}
                sortInfo={_updatePaginationResultsRew}
                handlers={{
                  handleDetailsButton: handleDetailsReviewButton,
                  handleDeclineButton: handleDeclineReviewButton,
                }}
                tableType={TABLE_TYPES.ADS_REVIEW_LIST}
              />
            </>
          ) : (
            <>
              <Button
                onClick={() => setReviewTable('ad_list')}
                color="primary"
                size="large"
              >
                <KeyboardBackspaceIcon className={classes.buttonIcon} /> Return
                to advertisement list
              </Button>
              <TablePrimary
                pagination={{
                  count: offerList.count ? offerList.count : 0,
                  length: 10,
                  onChangePage: _setOfferList,
                }}
                rows={offerList.items}
                sortInfo={_updatePaginationCompanyList}
                handlers={{
                  handleDetailsButton: handleDetailsReviewItem,
                  handleDeclineButton: handleDeclineReviewButton,
                }}
                tableType={TABLE_TYPES.ADS_REVIEW_COMPANY_LIST}
              />
            </>
          )}
        </TabPanel>
      </Box>
    </Container>
  );
};

export default withStyles(styles)(Campaigns);
