import { useSelector, useDispatch } from 'react-redux';
import {
  TextField,
  useMediaQuery,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button, Box,
} from '@material-ui/core';

import { confirmStatusSelector } from '../../../redux/selectors/selectors';
import { changeConfirmStatus } from '../../../redux/store/confirm/confirmActions';
import { confirmationTypes, listOfConfirmationTypes } from './config';

import { useTheme, withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../pages/campaigns/ContentVerification/styles';
import { confirmationKeys } from '../../../constants/confirmationKeys';
import { getApiUrlImage } from '../../../api/apiService';
import Typography from '@material-ui/core/Typography';

const ResponsiveDialog = ({ operation, content, classes }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { isVisible, type, actionParams } = useSelector(confirmStatusSelector);
  const dispatch = useDispatch();
  const [valueText, setValueText] = useState('');
  const history = useHistory();
  const [errorText, setErrorText] = useState(null);
  useEffect(() => {
    setErrorText(null);
  }, [type]);
  const handleClose = () => {
    dispatch(changeConfirmStatus({ isVisible: false, type: null }));
  };
  const _onChange = ({ target }) => {
    setValueText(target.value);
  };
  const renderContent = confirmationTypes[type];
  const getError = (e) => {
    setErrorText(e);
  };
  const getContent = () => {

    switch (type) {
      case confirmationKeys.editModerator:
      case confirmationKeys.createModerator:
        return <DialogActions style={{
          justifyContent: 'center',
        }}> {actionParams.form()}</DialogActions>;
      case confirmationKeys.infoBlogger:
        return (<DialogContent>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box width={'100%'} display={'flex'} alignItems={'center'}>
              <img style={{ margin: 'auto', borderRadius: '50%', width: '100px', height: '100px' }}
                   src={getApiUrlImage(actionParams.avatar)}
                   alt={'logo'}
              />
            </Box>

            <Box justifyContent={'center'} display={'flex'} alignItems={'center'} flexDirection={'column'}>
              <Typography color={'textSecondary'}>Account subject</Typography>
              <Typography>{actionParams.accountSubject.toLowerCase()}</Typography>

            </Box>
            <Box justifyContent={'center'} display={'flex'} alignItems={'center'} flexDirection={'column'}>
              <Typography color={'textSecondary'}> Account type</Typography>
              <Typography>{actionParams.accountType.toLowerCase()}</Typography>

            </Box>
            <Box justifyContent={'center'} display={'flex'} alignItems={'center'} flexDirection={'column'}>
              <Typography color={'textSecondary'}>Subscribers count</Typography>
              <Typography> {actionParams.subscribersCount}</Typography>

            </Box>
            <Box justifyContent={'center'} display={'flex'} alignItems={'center'} flexDirection={'column'}>
              <Typography color={'textSecondary'}>Stories per day</Typography>
              <Typography>{actionParams.storiesPerDay}</Typography>

            </Box>
            <Box justifyContent={'center'} display={'flex'} alignItems={'center'} flexDirection={'column'}>
              <Typography color={'textSecondary'}>Price per post</Typography>
              <Typography>{actionParams.pricePerPost}</Typography>

            </Box>

            <Box justifyContent={'center'} display={'flex'} alignItems={'center'} flexDirection={'column'}>
              <Typography color={'textSecondary'}>Account gender</Typography>
              <Typography>{actionParams.accountGender.toLowerCase()}</Typography>

            </Box>
          </Box>
        </DialogContent>);
      default :
        return (<>
          <DialogContent>
            <DialogContentText>{renderContent.content}</DialogContentText>
            {listOfConfirmationTypes.includes(renderContent.type) && (
              <TextField
                autoFocus
                multiline
                margin='dense'
                id='name'
                label='Describe the reason for refusal'
                type='text'
                fullWidth
                onChange={_onChange}
                style={{ width: ' 570px' }}
              />
            )}
          </DialogContent>
          <DialogContent>
            <Typography style={{ color: '#f22' }}> {errorText}</Typography>
          </DialogContent>
          <DialogActions style={{
            justifyContent: 'center',
          }}>
            <Button autoFocus onClick={handleClose} color='secondary'>
              Cancel
            </Button>
            <Button
              onClick={() => renderContent.action(dispatch, actionParams, valueText, history, getError)}
              color='primary'
              autoFocus
              style={{
                color: '#008000',
              }}
            >
              Confirm
            </Button>
          </DialogActions></>);
    }
  };
  return (
    <>
      {renderContent && (
        <div style={{ width: ' 700px' }}>
          <Dialog
            fullScreen={fullScreen}
            open={isVisible}
            onClose={handleClose}
            maxWidth={'lg'}
            aria-labelledby='responsive-dialog-title'
          >
            {getContent()}
          </Dialog>
        </div>
      )}
    </>
  );
};
export default withStyles(styles)(ResponsiveDialog);