import React, { useEffect, useState } from 'react';
import Input from '../../../ui-core/Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import {
  editSchema,
  moderatorSchema,
} from '../../../../schema/moderatorSchema';
import { useDispatch, useSelector } from 'react-redux';
import {
  addedNewModerator,
  moderatorEdit,
} from '../../../../redux/store/moderator/moderatorOperation';
import { getUser } from '../../../../api/createModerator-servise/createModerator-servise';
import { loaderSelector } from '../../../../redux/selectors/selectors';
import { changeGlobalLoaderStatus } from '../../../../redux/store/loader/loaderActions';

const CreteModeratorForm = ({ isEdit, id }) => {
  const {
    handleSubmit,
    control,

    formState: { isValid, errors },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(isEdit ? editSchema : moderatorSchema),
  });
  const { buttonLoader } = useSelector(loaderSelector);
  const dispatch = useDispatch();
  const [body, setBody] = useState({});
  const [errorEdit, setErrorEdit] = useState('');
  useEffect(() => {
    if (isEdit) {
      dispatch(changeGlobalLoaderStatus(true));
      getUser(id)
        .then(({ firstName, lastName, email }) => {
          reset({ firstName, lastName, email });
          setBody({
            firstName,
            lastName,
            email,
            newPassword: undefined,
            confirmNewPassword: undefined,
          });
        })
        .catch(() => {})
        .finally(() => dispatch(changeGlobalLoaderStatus(false)));
    }
  }, []);
  console.log(errors);
  const onSubmit = e => {
    if (isEdit) {
      let buffer = {};
      let notEdit = 0;
      Object.keys(body).forEach(name => {
        body[name] !== e[name] ? (buffer[name] = e[name]) : notEdit++;
      });
      console.log(notEdit, e);
      console.log(body);
      if (notEdit !== 5 && notEdit) {
        dispatch(moderatorEdit(id, buffer));
      } else {
        setErrorEdit('nothing changed');
      }
    } else {
      dispatch(addedNewModerator(e));
    }
  };
  return (
    <Box width={'400px'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box margin={'10px'}>
          <Input
            control={control}
            label="First name"
            type="text"
            name="firstName"
            InputLabelProps={
              isEdit
                ? {
                    shrink: true,
                  }
                : {}
            }
          />
          <p className={'instagram-edit-errors'}>
            {errors.firstName ? errors.firstName.message : ''}
          </p>
        </Box>
        <Box margin={'10px'}>
          <Input
            control={control}
            InputLabelProps={
              isEdit
                ? {
                    shrink: true,
                  }
                : {}
            }
            label="Last name"
            type="text"
            name="lastName"
          />
          <p className={'instagram-edit-errors'}>
            {errors.lastName ? errors.lastName.message : ''}
          </p>
        </Box>
        <Box margin={'10px'}>
          <Input
            control={control}
            InputLabelProps={
              isEdit
                ? {
                    shrink: true,
                  }
                : {}
            }
            label="Email"
            name="email"
            type="email"
          />
          <p className={'instagram-edit-errors'}>
            {errors.email ? errors.email.message : ''}
          </p>
        </Box>
        <Box margin={'10px'}>
          <Input
            control={control}
            label="Create password"
            type="password"
            name={isEdit ? 'newPassword' : 'createPassword'}
          />
          <p className={'instagram-edit-errors'}>
            {errors.createPassword ? errors.createPassword.message : ''}
          </p>
        </Box>
        <Box margin={'10px'}>
          <Input
            control={control}
            label="Confirm password"
            type="password"
            name={isEdit ? 'confirmNewPassword' : 'confirmPassword'}
          />
          <p className={'instagram-edit-errors'}>
            {errors.confirmPassword ? errors.confirmPassword.message : ''}
          </p>
        </Box>

        <Box
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          justifyContent={'center'}
          marginTop={'20px'}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            style={{ height: '42px', width: '127.938px' }}
            disabled={!isValid || buttonLoader}
            startIcon={!buttonLoader && <SaveIcon />}
          >
            {buttonLoader ? <CircularProgress size={14} /> : 'create'}
          </Button>
        </Box>

        <p className={'instagram-edit-errors'}>{errorEdit}</p>
      </form>
    </Box>
  );
};

export default CreteModeratorForm;
