import { get, put, post } from '../apiService';

export const getCampaigns = (status, page = 0, filter) => {
  return get(
    `/story?status=${status}&limit=10&offset=${page ? page : 0}${
      filter ? `&` + filter : ''
    }`,
  );
};
export const getReviewList = (page = 0, filter) => {
  return get(
    `/item/all?status=ACTIVE&limit=10&offset=${page ? page : 0}${
      filter ? `&` + filter : ''
    }`,
  );
};
export const getOfferList = (id, page = 0, filter = '') => {
  return get(
    `/item/${id}/offer/on-moderation?limit=10&status=ALL&offset=${
      page ? page : 0
    }${filter ? `&` + filter : ''}`,
  );
};
export const getVerificationItem = id => {
  return get(`/story/${id}`);
};
export const postAddedReject = (id, comment = '') => {
  return post(`/story/${id}/reject`, comment ? { comment } : {});
};
export const postAddedApprove = id => {
  return post(`/story/${id}/approve`);
};
export const putEditStatusReview = (id, body) => {
  return put(`/offer/${id}/check`, body);
};
export const getOfferItem = id => {
  return get(`/offer/${id}`);
};
export const getBloggerIngo = id => {
  return get(`/instagram/all-about-account/${id}`);
};
export const editStoryViews = (id, body) => {
  return put(`/instagram/update-stories-views/${id}`, body);
};
export const getCampaignsInfo = id => {
  return get(`/item/${id}`);
};
export const getOfferByPublicId = id => {
  return get(`/offer/search/${id}`);
};
