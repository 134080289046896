import React from 'react';

import { Grid, Box, Button } from '@material-ui/core';
import Input from '../../../../ui-core/Input/Input';

const Country = ({ number, control, removeCountry, length, name }) => (
  <>
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
      }}
    >
      <Grid m={2} item={true} xs={7}>
        <Input
          control={control}
          name={`locationCountries.country-${number}.countryName`}
          label='Country'
          defaultValue={''}
        />
      </Grid>
      <Grid item={true} xs={2}>
        <Input
          type='number'
          control={control}
          name={`locationCountries.country-${number}.percent`}
          label='%'
          defaultValue={''}
        />
      </Grid>{length > 1 ?
      <Grid item={true} xs={2}>
        <Button color='secondary' onClick={() => removeCountry(name)}>Remove</Button>
      </Grid> : null}
    </Box>
  </>
);

export default Country;
