import types from './advertisersTypes';

const initialState = {

  body: {
    items: [],
    count: 0,
  },
};

const advertisersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_ADVERTISER:
      return { ...state, body: payload };

    default:
      return state;
  }
};

export default advertisersReducer;
