export const verificationRequestsSelector = state =>
  state.verification.requests;

export const screensForApproveSelector = state =>
  state.verification.screensForApprove;

export const screensForApproveItemSelector = state =>
  state.verification.verificationScreens;

export const verificationFormSelector = state =>
  state.verification.verificationForm;

export const verificationFormStatusSelector = state =>
  state.verification.verificationFormIsReadyToSend;
