import * as yup from 'yup';

const blogSchema = yup.object().shape({
  title: yup.string().min(5).max(100,'Title has a maximum limit of 100 characters.').required(),
  summary: yup.string().min(5).max(400,'Description has a maximum limit of 100 characters.').required(),
  banner: yup.mixed().required(),
  body: yup.string().required()
});
const cpaofferSchema = yup.object().shape({
  title: yup.string().min(5).max(100,'Title has a maximum limit of 100 characters.').required(),
  link: yup.string().min(5).max(100,'Link has a maximum limit of 100 characters.').required(),
  price: yup.number().required(),
  adminProfit: yup.number().required(),
  technicalDescription:  yup.string().min(5).max(100,'Link has a maximum limit of 100 characters.').required()
});

export { blogSchema , cpaofferSchema};
