import { Typography, Box, Container } from '@material-ui/core';
import TablePrimary from '../../components/Tables/TablePrimary/TablePrimary';
import React, { useEffect } from 'react';
import { TABLE_TYPES } from '../../components/Tables/TablePrimary/TablePrimaryHead/config';
import { useDispatch, useSelector } from 'react-redux';
import { getAdvertiserBody } from '../../../redux/store/advertisers/advertisersOperations';
import { useHistory } from 'react-router-dom';

const Advertisers = () => {
  const dispatch = useDispatch();

  const bodyAdvertisers = useSelector((store) => (store.advertisers.body));
  const history=useHistory()
  useEffect(() => {
    dispatch(getAdvertiserBody(10, 0));
  }, [dispatch]);
  const _pagination = (page) => {
    dispatch(getAdvertiserBody(10, page * 10));
  };
  const _sort = (sort) => {
    dispatch(getAdvertiserBody(10, 0, sort));
  };
  return (
    <Container maxWidth='lg'  style={{overflow:'auto',padding:'0'}}>
      <Box p={1}>
        <Typography variant='h4' gutterBottom>
          Advertisers
        </Typography>
        <TablePrimary rows={bodyAdvertisers.items} blocked={true} tableType={TABLE_TYPES.ADVERTISERS_TABLE}
                      pagination={{
                        count: bodyAdvertisers.count,
                        length: 10,
                        onChangePage: _pagination,
                      }} sortInfo={_sort}
                      handlers={{
                        handleDetailsButton:(e)=>{
                          history.push('advertiser/'+e)
                         }
                      }}
        />
      </Box>
    </Container>
  );
};

export default Advertisers;
