import { Typography, Box, Container, Paper, Tabs, Tab, Button, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getListTransaction } from '../../../api/transactions-service/transactions-service';
import TablePrimary from '../../components/Tables/TablePrimary/TablePrimary';
import { TABLE_TYPES } from '../../components/Tables/TablePrimary/TablePrimaryHead/config';
import { dataFormat } from '../../../utilites/globalUtils';
import { getTextTypeTransaction } from '../../../utilites/typeTransaction/typeTransaction';
import { errorHandler } from '../../../redux/store/notify/notifyOperations';
import { useDispatch } from 'react-redux';
import { changeGlobalLoaderStatus } from '../../../redux/store/loader/loaderActions';
import TabPanel from '../../components/TabPanel/TabPanel';
import { getInfoUser, getProfitPercent } from '../../../api/authentication-servise/authentication';
import { changeConfirmStatus } from '../../../redux/store/confirm/confirmActions';
import { confirmationKeys } from '../../../constants/confirmationKeys';

const Transactions = ({ idAd }) => {
  const [body, setBody] = useState({ items: [], count: 0 });
  const dispatch = useDispatch();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [userId, setUserId] = useState('');
  const [activePercent, setActivePercent] = useState(0);
  const [editPercent, setEditPercent] = useState();
  const getUser = () => {
    getInfoUser()
      .then(r => setUserId(r._id))
      .catch(message => dispatch(errorHandler(message)));
  };
  const getBodyTransaction = (
    limit = 10,
    offset = 0,
    sort = '',
    filter = 'all',
    isWallet,
  ) => {
    dispatch(changeGlobalLoaderStatus(true));
    const isWalletHistory = isWallet ? isWallet === 'walletHistory' : activeTabIndex === 1;
    getListTransaction(limit, offset, filter,
      isWalletHistory ? `&userId=${userId}` + sort : sort, idAd)
      .then(({ items, count }) => {
        const buffer = items.map(({ amount, createdAt, recipient, type }) => {
          return {
            view: {
              name: recipient.name,
              amount,
              createdAt: dataFormat(createdAt),
              type: getTextTypeTransaction(type),
            },
          };
        });
        setBody({ items: buffer, count });
      })
      .catch(message => dispatch(errorHandler(message)))
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  };
  useEffect(() => {
    getUser();
    getInfoPercent();
    getBodyTransaction(10, 0);
  }, [idAd]);

  const _pagination = page => {
    getBodyTransaction(10, 10 * page);
  };
  const _sort = sort => {
    getBodyTransaction(10, 0, sort);
  };
  const getInfoPercent = () => {
    getProfitPercent()
      .then(({ percent }) => {
        setActivePercent(percent);
      }).catch(message => dispatch(errorHandler(message)));
  };

  const _transactionHistory = () => {
    setActiveTabIndex(0);
    getBodyTransaction(10, 0, '', 'all', 'transactionHistory');
  };
  const _adminWalletHistory = () => {
    setActiveTabIndex(1);
    getBodyTransaction(10, 0, '', 'all', 'walletHistory');

  };
  const getTransactionHistory = () => (
    body.items.length ? (
      <TablePrimary
        rows={body.items}
        blocked={true}
        tableType={TABLE_TYPES.TRANSACTION_TABLE}
        pagination={{
          count: body.count,
          length: 10,
          onChangePage: _pagination,
        }}
        notShowControl={true}
        sortInfo={_sort}
      />
    ) : (
      <Box p={'20px'}>
        {idAd && (
          <Typography variant='h6' gutterBottom>
            Advertiser is not have active Transactions
          </Typography>
        )}
      </Box>
    )
  );
  const updateData = () => {
    getInfoPercent();
  };
  const setPercent = () => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.setPercent,
        actionParams: { editPercent, updateData },
      }));
  };
  const getTransactionPercent = () => (
    !idAd ?
      <Box style={{ background: '#fff' }} display={'flex'} justifyContent={'space-between'} p={'20px'}
           marginTop={'5px'}>
        <Box alignItems={'center'}>
          <Typography
                      gutterBottom> Platform wallet: {activePercent}%
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <Typography  gutterBottom>
            Set percent:
          </Typography>
          <TextField
            style={{ margin: '0 20px' }}
            label={'input percent'}
            type='number'
            size='small'
            variant='outlined'
            value={editPercent}
            onChange={({ target: { value } }) => {
              setEditPercent((prevValue) => (value >= 0 && value <= 100 ? Number.parseInt(value) : prevValue));
            }}
          />
          <Button size='small'
                  m={'30px'}
                  onClick={setPercent}
                  variant='contained'
                  disabled={!editPercent || editPercent === activePercent || !(editPercent >= 0 && editPercent <= 100)}
                  color='primary'>Set percent</Button>
        </Box>
      </Box> : null
  );
  const getSelectHistory = () => (
    !idAd ? <Tabs
      value={activeTabIndex}
      onChange={setActiveTabIndex}
      variant='fullWidth'
      indicatorColor='primary'
      textColor='primary'
    >
      <Tab
        label={<Box fontWeight='fontWeightBold'>Transaction history</Box>}
        onClick={_transactionHistory}
      />
      <Tab
        label={<Box fontWeight='fontWeightBold'>Admin Wallet history </Box>}
        onClick={_adminWalletHistory}
      />

    </Tabs> : null
)

  return (
    <Container maxWidth='lg'   style={{overflow:'auto',padding:'0'}}>
      <Box p={1}>
        <Typography variant='h4' gutterBottom>
          Transactions
        </Typography>
        {getTransactionPercent()}
        <Paper square>
          {getSelectHistory()}
        </Paper>
        <TabPanel activeTabIndex={activeTabIndex} index={0}>
          <Box marginTop={'5px'}>
            {getTransactionHistory()}
          </Box>
        </TabPanel>
        <TabPanel activeTabIndex={activeTabIndex} index={1}>
          <Box marginTop={'5px'}>
            {getTransactionHistory()}
          </Box>
        </TabPanel>

      </Box>
    </Container>
  );
};

export default Transactions;
