import types from './campaignsTypes';

const initialState = {
  campaigns: {
    items: [{
      view: {},
      _id: 1,
    }],
    count: 0,
  },
  reviewList: {
    items: [{
      view: {},
      _id: 1,
    }],
    count: 0,
  },
  verificationList: {
    items: [],
    count: 0,
  },
  item_verification: {},
  item_review: {},
  offerList: {
    items: [{
      view: {},
      _id: 1,
    }],
    count: 0,
  },

};

const campaignsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_CAMPAIGNS_LIST_SUCCESS:
      return {
        ...state,
        campaigns: payload,
      };
    case types.GET_ADS_FOR_REVIEW_SUCCESS:
      return {
        ...state,
        reviewList: payload,
      };
    case types.GET_ADS_FOR_OFFER_SUCCESS :
      return {
        ...state,
        offerList: payload,
      };
    case types.GET_VERIFICATION_ITEM_SUCCESS :
      return {
        ...state,
        item_verification: payload,
      };
    case types.SEND_VERIFICATION_LIST_SUCCESS :
      return {
        ...state,
        verificationList: payload,
      };
    case types.GET_REVIEW_ITEM_SUCCESS :
      return {
        ...state,
        item_review: payload,
      };
    default:
      return state;
  }
};

export default campaignsReducer;
