const styles = {
  root: {
    flexGrow: 1,
    color: '#332256',
    position: 'relative',
  },

  appBar: {
    position: 'static',
    backgroundColor: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid #dee2e6',
  },

  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  logo: {
    cursor: 'pointer',
  },

  right: {
    marginRight: '15px',
  },

  link: {
    textDecoration: 'none',
  },
};

export default styles;
