const styles = {
  buttonContainer: {
    marginTop: 20,
  },
  innerFormContainer: {
    padding: 20,
  },
};

export default styles;
