import { Typography, Box, Container, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import TablePrimary from '../../components/Tables/TablePrimary/TablePrimary';
import React, { useEffect, useState } from 'react';
import { getBloggersList } from '../../../api/bloggers-service/bloggers-service';
import { TABLE_TYPES } from '../../components/Tables/TablePrimary/TablePrimaryHead/config';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withStyles } from '@material-ui/core/styles';
import styles from '../verification/styles';
import { useHistory } from 'react-router-dom';
import { changeGlobalLoaderStatus } from '../../../redux/store/loader/loaderActions';
import {DeleteAccountByAdmin} from "../../../api/bloggers-service/bloggers-service"
import { confirmationKeys } from '../../../constants/confirmationKeys';
import AprovalForm from '../verification/fortm/AprovalForm';
import { changeConfirmStatus } from '../../../redux/store/confirm/confirmActions';
const Bloggers = () => {
  const [instagramList, setInstagramList] = useState();
  const [bloggersList, setBloggersList] = useState({ items: [], count: 0 });
  const dispatch = useDispatch();
  const history = useHistory();
  const saveBloggerLost = (limit, offset, sort) => {
    dispatch(changeGlobalLoaderStatus(true));
    getBloggersList(limit, offset, sort)
      .then(({ items, count }) => {
        console.log(items)
        const buffer = items.map(
          ({ lastName, _id, email, firstName, instagramAccounts }) => {
            return {
              view: {
                firstName,
                lastName,
                email,
                numberInstagramAccounts: instagramAccounts.length,
              },
              instagramAccounts: instagramAccounts,
            };
          },
        );
        setBloggersList({ items: buffer, count });
      })
      .catch(() => {
      })
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  };
  useEffect(() => {
    saveBloggerLost(10, 0);
  }, []);
  const handleOpenInstagram = e => {
    history.push(`/instagramEdit/${e}`);
  };
  
  const handleDeleteUser = e => {
    DeleteAccountByAdmin(e).then((data)=>{
      if (data){
        saveBloggerLost(10, 0);
      }
    })
  };
  const handleOpenUser = e => {
    const buffer = e.map(({ _id, userName }) => {
      return {
        view: {
          userName,
        },
        _id: _id,
      };
    });
    setInstagramList(buffer);
  };
  const handleChangeButton = id =>
  {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.createModerator,
        actionParams: { form: () => <AprovalForm id={id} isEdit={true} /> },
      }),
    );
  }

  const _pagination = page => {
    saveBloggerLost(10, page * 10);
  };
  const _sort = sort => {
    saveBloggerLost(10, 0, sort);
  };
  return (
    <Container maxWidth='lg' style={{ overflow: 'auto', padding: '0' }}>
      <Box p={1}>
        <Box display='flex' justifyContent='space-between'>
          {instagramList ? (
            <Button
              onClick={() => setInstagramList(null)}
              color='primary'
              size='large'
            >
              <KeyboardBackspaceIcon /> Return to bloggers table
            </Button>
          ) : <Typography variant='h4' gutterBottom>
            Bloggers
          </Typography>}
        </Box>
        {instagramList ? (
          <TablePrimary
            handlers={{
              handleDeleteUser: handleDeleteUser,
              handleChangeButton:handleChangeButton,
            }}
            rows={instagramList}
            blocked={true}
            pagination={{
              count: instagramList.length,
              length: instagramList.length,
            }}
            tableType={TABLE_TYPES.BLOGGERS_LIST}
          />
        ) : (
          <TablePrimary
            handlers={{
              handleDeleteUser: handleDeleteUser,
              handleChangeButton:handleChangeButton,
            }}
            rows={bloggersList?.items}
            blocked={true}
            tableType={TABLE_TYPES.BLOGGERS_LIST}
            pagination={{
              count: bloggersList?.count,
              length: 10,
              onChangePage: _pagination,
            }}
            sortInfo={_sort}
          />
        )}
      </Box>
    </Container>
  );
};
export default withStyles(styles)(Bloggers);
