import { Typography, Box, Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getStatisticsHomePage } from '../../../api/home-servise/homeServise';
import { useHistory } from 'react-router-dom';
import { infoStatistiksItem } from './const';
import { errorHandler } from '../../../redux/store/notify/notifyOperations';
import { useDispatch } from 'react-redux';
import { changeGlobalLoaderStatus } from '../../../redux/store/loader/loaderActions';
import { v4 as uuidv4 } from 'uuid';

const Home = () => {
  const [body, setBody] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeGlobalLoaderStatus(true));
    getStatisticsHomePage()
      .then(e => {
        setBody(e);
      })
      .catch(({ message }) => {
        dispatch(errorHandler(message));
      })
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  }, []);
  const history = useHistory();
  const goToPage = page => {
    history.push(page);
  };
  const item = () => {
    Object.keys(body);
    return Object.keys(body).map(item => (
      <Box
        key={uuidv4()}
        display={'flex'}
        flexDirection={'column'}
        textAlign={'center'}
        bgcolor={'#fff'}
        padding={'20px 0'}
        width={'300px'}
        margin={'20px'}
        minHeight={'200px'}
        borderRadius={'10px'}
      >
        <Box
          width={'100%'}
          borderBottom={'1px solid #f0f2f8'}
          paddingBottom={'30px'}
          marginBottom={'20px'}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            goToPage(infoStatistiksItem[item].pushTo);
          }}
        >
          <Typography variant="h6" gutterBottom p={'30px'}>
            {infoStatistiksItem[item].name}
          </Typography>
        </Box>
        <Box width={'100%'}>
          <Typography variant="h6" gutterBottom>
            {' '}
            {body[item]}
          </Typography>
        </Box>
      </Box>
    ));
  };
  return (
    <Container maxWidth="lg"  style={{overflow:'auto'}}>
      <Box p={4}>
        <Typography variant="h4" gutterBottom>
          Home
        </Typography>
      </Box>
      <Box
        display={'flex'}
        flexWrap={'wrap'}
        width={'100%'}
        justifyContent={'flex-start'}
        alignItems={'center'}
      >
        {item()}
      </Box>
    </Container>
  );
};

export default Home;
