const styles = {
  buttonIcon: {
    marginRight: 10,
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '50px',
    marginTop: '50px',
  },
  buttonContainer: { marginRight: 20 },
  test:{
    width:'1000px'
  }
};

export default styles;
