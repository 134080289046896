import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './views/app/App';
import ModalContainer from './views/components/Modal/Modal';
import Notistack from './views/components/Notistack/Notistack';
import ConfirmDialog from './views/components/ConfirmDisalog/ConfirmDialog';

import { store } from './redux/store/store';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import 'normalize.css';
import './assets/styles/global.css'
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Arial',
  },
  color: '#E5E5E5',
});

//need to add React.StrictMode to replace a fragment

ReactDOM.render(
  <>
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Router basename="/">
          <App />
          <ModalContainer />
          <Notistack />
          <ConfirmDialog />
        </Router>
      </Provider>
    </MuiThemeProvider>
  </>,
  document.getElementById('root'),
);
