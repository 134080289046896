import './styles.css';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { editStoryViews, getBloggerIngo } from '../../../api/campaigns-servise/campaigns-servise';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { errorHandler } from '../../../redux/store/notify/notifyOperations';
import { setNotifyStatus } from '../../../redux/store/notify/notifyActions';


const BloggerCard = () => {
  const [body, setBody] = useState([]);
  const [storyViews, setStoryViews] = useState('');
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const getInfo = () => {
    const arr = ['13-17', '18-24', '35-44', '45-54', '55-64', '65'];
    getBloggerIngo(id).then((r) => {
      setStoryViews(r.accountId?.storiesViews);
      const statistics = arr.map(item =>
        r.statistics.reduce((acum, b) => {
          if (b.ageCategory === item) {
            acum[b.gender + 'Score'] = b.score;
            acum.ageCategory = b.ageCategory;
          }
          return acum;
        }, {}),
      );
      setBody({ ...r, statistics });
    });
  };
  useEffect(() => {
    const arr = ['13-17', '18-24', '35-44', '45-54', '55-64', '65'];
    getBloggerIngo(id).then((r) => {
      setStoryViews(r.accountId?.storiesViews);
      const statistics = arr.map(item =>
        r.statistics.reduce((acum, b) => {
          if (b.ageCategory === item) {
            acum[b.gender + 'Score'] = b.score;
            acum.ageCategory = b.ageCategory;
          }
          return acum;
        }, {}),
      );
      setBody({ ...r, statistics });
    });
  }, [id]);
  const _onClickSave = () => {
    if (storyViews !== +body.accountId?.storiesViews && Number.isInteger(+storyViews) && storyViews && storyViews > 0) {
      editStoryViews(id, {
        storiesViews: storyViews,
      }).then(r => {
        history.goBack();
        dispatch(setNotifyStatus({
          type: 'success',
          content: `Account story views updated`,
        }));
        getInfo();
      }).catch(error =>
        dispatch(errorHandler(error.message)),
      );
    }
  };
  return (
    <div className='blogger-card__container'>
      <div className='blogger-card__body'>
        <div className='blogger-card'>
          <div className='blogger-card-head '>
            <img
              crossOrigin='anonymous'
              src={body.accountId?.avatar}
              className='blogger-card-head__img'
              alt='blooger'
            />
            <p className='blogger-card-head__profile'>{body.accountId?.userName}</p>
            <p className='blogger-card-head__category'>{body.accountId?.accountType}</p>
          </div>
          <div className='blogger-card-main'>
            <div className='blogger-card__inner-container'>
              <div className='blogger-card-main__characteristic'>
                <p className='blogger-card-main__characteristic-desc'>
                  Followers
                </p>
                <p className='blogger-card-main__characteristic-value'>{body.accountId?.subscribersCount}</p>
              </div>
              <div className='blogger-card-main__characteristic'>
                <p className='blogger-card-main__characteristic-desc'>
                  Reach audience
                </p>
                <p className='blogger-card-main__characteristic-value'>{body.accountId?.audienceReach}</p>
              </div>
              <div className='blogger-card-main__characteristic'>
                <p className='blogger-card-main__characteristic-desc'>
                  Story views
                </p>
                <input value={storyViews} onChange={({ target }) => {
                  setStoryViews(target.value);
                }} className='blogger-card-main__characteristic-value_input' />
              </div>
              <div className='blogger-card-main__characteristic'>
                <p className='blogger-card-main__characteristic-desc'>
                  Account quality
                </p>
                <p className='blogger-card-main__characteristic-value'>
                <span className='blogger-card-main__characteristic-value-cust'>
                  {body.accountId?.accountQuality}
                </span>/ 10
                </p>
              </div>
              <div className='blogger-card-main__characteristic'>
                <p className='blogger-card-main__characteristic-desc'>
                  Stories per day
                </p>
                <p className='blogger-card-main__characteristic-value-cust'>{body.accountId?.storiesPerDay}</p>
              </div>
              <div className='blogger-card-main__characteristic'>
                <p className='blogger-card-main__characteristic-desc'>
                  Price per 1k views
                </p>
                <p className='blogger-card-main__characteristic-value'>{body.accountId?.priceForOneThousand}</p>
              </div>

              <div className='blogger-card-main__characteristic'>
                <p className='blogger-card-main__characteristic-desc'>
                  Price per post
                </p>
                <p className='blogger-card-main__characteristic-value'>{body.accountId?.pricePerPost}</p>
              </div>
              <div className='blogger-card-main__characteristic'>
                <p className='blogger-card-main__characteristic-desc'>
                  Recommended price
                </p>
                <p className='blogger-card-main__characteristic-value'>{body.accountId?.recommendedPrice}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='blogger-card__right'>

          <table border='1' className='blogger-card__right__table'>
            <tbody>
            <tr>
              <th>Age</th>
              <th>All</th>
              <th>Male</th>
              <th>Female</th>
            </tr>
            {body.statistics?.map(
              (
                { ageCategory, ageMaleScore, ageFemaleScore, allScore },
                index,
              ) => (
                <tr key={uuidv4()}>
                  <td>
                    {ageCategory === '65'
                      ? ageCategory + ' +'
                      : ageCategory}
                  </td>
                  <td>{allScore}</td>
                  <td>{ageMaleScore}</td>
                  <td>{ageFemaleScore}</td>
                </tr>
              ),
            )}
            </tbody>
          </table>
          <div className='blogger-card__right__item'>
            <p className='blogger-card__right__item-title'>title</p>
            <div className='blogger-card__right__item-container'>
              <p className='blogger-card__right__item-sub'>Male</p>
              <p className='blogger-card__right__item-sub'>{body.accountId?.genderGeneral?.men}</p>
            </div>
            <div className='blogger-card__right__item-container'>
              <p className='blogger-card__right__item-sub'>Female</p>
              <p className='blogger-card__right__item-sub'>{body.accountId?.genderGeneral?.women}</p>
            </div>
          </div>
          <div className='blogger-card__right__item'>
            <p className='blogger-card__right__item-title'>title</p>
            {body.accountId?.locationCountries.map(
              ({ countryName, percent }, index) => (<div className='blogger-card__right__item-container' key={uuidv4()}>
                  <p className='blogger-card__right__item-sub'>{countryName}</p>
                  <p className='blogger-card__right__item-sub'>{percent}</p>
                </div>
              ))}

          </div>
          <div className='blogger-card__right__item'>
            <p className='blogger-card__right__item-title'>title</p>
            {body.accountId?.locationCities.map(
              ({ cityName, percent }, index) => (<div key={uuidv4()} className='blogger-card__right__item-container'>
                  <p className='blogger-card__right__item-sub'>{cityName}</p>
                  <p className='blogger-card__right__item-sub'>{percent}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <button onClick={_onClickSave}
              className={storyViews !== +body.accountId?.storiesViews && Number.isInteger(+storyViews) && storyViews && storyViews > 0 ? `blogger-card__save ` : 'blogger-card__save blogger-card__save-disable'}>
        save
      </button>
    </div>
  );
};

export default BloggerCard;
