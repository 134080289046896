import { Typography, Box, Container } from '@material-ui/core';

import FormBlog from '../../components/Forms/FormBlog/FormBlog';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearBlog, getBlogItem } from '../../../redux/store/blog/blogOperations';

const BlogEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const body = useSelector((store) => store.blog.blogItem);
  if (!!body&&body._id) {
    delete body['_id'];
    delete body['data'];
  }
  useEffect(() => {
    dispatch(getBlogItem(id));
    return () => {
      dispatch(clearBlog());
    };
  }, [id, dispatch]);
  return (
    <Container maxWidth='lg'  style={{overflow:'auto'}}>
      <Box p={4}>
        <Typography variant='h4' gutterBottom>
          Edit News
        </Typography>
        {body ? <FormBlog isEdit={true} body={body} id={id} /> : null}

      </Box>
    </Container>
  );
};

export default BlogEdit;
