import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { Container, Button, Box, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { screensForApproveItemSelector } from '../../../redux/selectors/verification';
import { changeScreensForApproveItemFormat } from '../../../helpers/changeVerificationDataFormat';
import { verificationFormStatusSelector } from '../../../redux/selectors/verification';
import {
  sendVerificationForm,
  fetchScreensForApproveById,
  rejectVerificationForm,
} from '../../../redux/store/verification/verificationOperations';

import { clearVerificationFormData } from '../../../redux/store/verification/verificationActions';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import Tabs from '../../components/Forms/Tabs/Tabs';
import Slider from '../../components/Slider/Slider';
import { listOfTabs } from './config';

import { confirmationKeys } from '../../../constants/confirmationKeys';
import { changeConfirmStatus } from '../../../redux/store/confirm/confirmActions';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { loaderSelector } from '../../../redux/selectors/selectors';

const VerificationCurrent = ({
  classes,
  history,
  sendVerificationForm,
  screenshotsList,
  rejectVerificationForm,
  loader: { buttonLoader },
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const verificationFormStatus = useSelector(verificationFormStatusSelector);

  useEffect(() => {
    dispatch(fetchScreensForApproveById(id));

    return () => dispatch(clearVerificationFormData());
  }, [dispatch, id]);

  const handleDeclineButton = id => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.statisticsAccountDeclined,
        actionParams: [id, history],
      }),
    );
  };

  const changedDataFormatApproveItem =
    changeScreensForApproveItemFormat(screenshotsList);
  const _openPopUp = () => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.infoBlogger,
        actionParams: screenshotsList,
      }),
    );
  };
  return (
    <Container  style={{overflow:'auto', }} >
      <Box className={classes.currentInnerContainer} minWidth={'900px'}>
        <Button
          onClick={() => history.push('/verification')}
          color="primary"
          size="large"
        >
          <KeyboardBackspaceIcon className={classes.buttonIcon} /> Return to
          Tables
        </Button>
        <Box>
          <Button
            onClick={() => handleDeclineButton(id)}
            variant="outlined"
            color="secondary"
            size="large"
            style={{ height: '40px', width: '135px' }}
            disabled={buttonLoader}
          >
            {!buttonLoader && (
              <CancelOutlinedIcon className={classes.buttonIcon} />
            )}
            {buttonLoader ? <CircularProgress size={14} /> : 'Reject'}
          </Button>
          <Tooltip title="You must fill in all the fields to save">
              <Button
                className={classes.sendButton}
                disabled={!verificationFormStatus || buttonLoader}
                onClick={() => sendVerificationForm(id, history)}
                color="primary"
                variant="contained"
                size="large"
                style={{ height: '40px', minWidth: '165px' }}
              >
                {!buttonLoader && <SaveIcon className={classes.buttonIcon} />}
                {buttonLoader ? <CircularProgress size={14} /> : 'Save form'}
              </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Slider
          slides={changedDataFormatApproveItem}
          onClickMoreInfo={_openPopUp}
        />
        <Tabs listOfTabs={listOfTabs} />
      </Box>
    </Container>
  );
};

const mapStateToProps = state => ({
  screenshotsList: screensForApproveItemSelector(state),
  loader: loaderSelector(state),
});

const mapDispatchToProps = {
  sendVerificationForm,
  rejectVerificationForm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(VerificationCurrent));
