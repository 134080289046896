import * as verificationActions from './verificationActions';
import * as api from '../../../api/verification-service/verification-service';

import { transformStatisticData } from '../../../utilites/verification/transformStatisticData';
import { setNotifyStatus } from '../notify/notifyActions';
import { verificationFormSelector } from '../../selectors/verification';
import { validationFormDataSchema } from '../../../schema/verificationForms';
import { errorHandler } from '../notify/notifyOperations';
import {
  changeButtonLoaderStatus,
  changeGlobalLoaderStatus,
} from '../loader/loaderActions';

export const addDataToVerificationFormObject = data => (dispatch, getState) => {
  dispatch(verificationActions.addDataToVerificationFormObject(data));
  dispatch(validationFormData());
};

const modificationOfDataToSend = (data = {}) => {
  const { locationCountries, locationCities, all, ageMale, ageFemale } = data;
  const cities = [...Object.values(locationCities)];

  const conties = [...Object.values(locationCountries)];

  const transformationAll = transformStatisticData('all', all);
  const transformAgeFemale = transformStatisticData('ageFemale', ageFemale);
  const transformAgeMale = transformStatisticData('ageMale', ageMale);

  const changedObject = {
    ...data,
    locationCities: cities.filter(({ notShow }) => !notShow),
    locationCountries: conties.filter(({ notShow }) => !notShow),
    statistic: [
      ...transformationAll,
      ...transformAgeFemale,
      ...transformAgeMale,
    ],
  };

  delete changedObject.ageFemale;
  delete changedObject.ageMale;
  delete changedObject.all;

  return changedObject;
};

export const validationFormData = () => (dispatch, getState) => {
  const validationFormData = verificationFormSelector(getState());

  validationFormDataSchema
    .validate(validationFormData)
    .then(response => {
      dispatch(verificationActions.changeVerificationFormStatus(true));
    })
    .catch(error => {});
};

export const fetchVerificationRequests = (limit, offset, sort) => dispatch => {
  dispatch(verificationActions.getVerificationRequestsStart());
  dispatch(changeGlobalLoaderStatus(true));

  return api
    .getVerificationRequests(limit, offset, sort)
    .then(response => {
      dispatch(verificationActions.getVerificationRequestsSuccess(response));
    })
    .catch(error => {
      dispatch(verificationActions.getVerificationRequestsError(error));

      dispatch(
        setNotifyStatus({
          type: 'error',
          content: `An error has occurred`,
        }),
      );
    })
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};

export const fetchScreensForApprove = (limit, offset, sort) => dispatch => {
  dispatch(verificationActions.getScreensForApproveStart());
  dispatch(changeGlobalLoaderStatus(true));

  return api
    .getScreensForApprove(limit, offset, sort)
    .then(response => {
      dispatch(verificationActions.getScreensForApproveSuccess(response));
    })
    .catch(({ message }) => {
      dispatch(verificationActions.getScreensForApproveError(message));

      dispatch(
        setNotifyStatus({
          type: 'error',
          content: `${message}`,
        }),
      );
    })
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};

export const fetchScreensForApproveById = id => dispatch => {
  dispatch(verificationActions.getScreensForApproveByIdStart());
  dispatch(changeGlobalLoaderStatus(true));

  return api
    .getScreensForApproveById(id)
    .then(response => {
      dispatch(
        verificationActions.getScreensForApproveByIdSuccess(response[0]),
      );
    })
    .catch(({ message }) => {
      dispatch(verificationActions.getScreensForApproveByIdError(message));

      dispatch(errorHandler(message));
    })
    .finally(() => dispatch(changeGlobalLoaderStatus(false)));
};
export const sendVerificationFormWithData = (id, body, history) => (dispatch, getState) => {
  dispatch(verificationActions.sendVerificationFormStart());
  dispatch(changeButtonLoaderStatus(true));


  return api
    .sendVerificationFormWithData(id, body)
    .then(response => {
      history.push('/verification');
      dispatch(verificationActions.sendVerificationFormSuccess(response));

      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Verification was successful`,
        }),
      );
    })
    .catch(({ message }) => {
      dispatch(verificationActions.sendVerificationFormError(message));

      dispatch(errorHandler(message));
    })
    .finally(() => dispatch(changeButtonLoaderStatus(false)));
};
export const sendVerificationForm = (id, history) => (dispatch, getState) => {
  const validationFormData = verificationFormSelector(getState());
  dispatch(verificationActions.sendVerificationFormStart());
  dispatch(changeButtonLoaderStatus(true));

  const modifiedDataObject = modificationOfDataToSend(validationFormData);

  return api
    .sendVerificationForm(id, {
      ...modifiedDataObject,
      isScreenShotsApprove: true,
    })
    .then(response => {
      history.push('/verification');
      dispatch(verificationActions.sendVerificationFormSuccess(response));

      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Verification was successful`,
        }),
      );
    })
    .catch(({ message }) => {
      dispatch(verificationActions.sendVerificationFormError(message));

      dispatch(errorHandler(message));
    })
    .finally(() => dispatch(changeButtonLoaderStatus(false)));
};

export const rejectVerificationForm = (id, history) => dispatch => {
  dispatch(verificationActions.rejectVerificationFormStart());
  dispatch(changeButtonLoaderStatus(true));

  return api
    .sendVerificationForm(id, {
      isScreenShotsApprove: false,
    })
    .then(() => {
      dispatch(verificationActions.rejectVerificationFormSuccess());
      history.push('/verification');

      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Verification was successful rejected`,
        }),
      );
    })
    .catch(({ message }) => {
      dispatch(verificationActions.rejectVerificationFormError());

      dispatch(errorHandler(message));
    })
    .finally(() => dispatch(changeButtonLoaderStatus(false)));
};

export const sendVerifyRequestStatus = (id, status) => dispatch => {
  dispatch(verificationActions.sendVerifyRequestStatusStart());

  return api
    .verifyStatusRequest(id, { verified: status })
    .then(response => {
      dispatch(fetchVerificationRequests());
      dispatch(verificationActions.sendVerifyRequestStatusSuccess(response));

      dispatch(
        setNotifyStatus({
          type: 'success',
          content: `Account ${status ? 'verified' : 'declined'}`,
        }),
      );
    })
    .catch(({ message }) => {
      dispatch(verificationActions.sendVerifyRequestStatusError(message));

      dispatch(errorHandler(message));
    });
};
