import types from './authenticationTypes';

const initialState = {
  loginStatus: false,
  token: '',
  refreshToken: '',
  fcmToken: '',
};

const authenticationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.POST_LOGIN_USER_SUCCESS:
      return { ...state, ...payload, loginStatus: true };
    case types.SET_FCM_TOKEN:
      return { ...state, fcmToken: payload };
    case types.GET_LOGOUT_SUCCESS:
      return initialState;
    case types.POST_UPDATE_TOKEN_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default authenticationReducer;
