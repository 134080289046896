import React, { useEffect, useState } from 'react';
import { Box, Button, Container } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { getCampaignsInfo } from '../../../api/campaigns-servise/campaigns-servise';
import { onlyDataFormat, timeFormat } from '../../../utilites/globalUtils';
import './styles.css';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { errorHandler } from '../../../redux/store/notify/notifyOperations';
import { useDispatch } from 'react-redux';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { changeGlobalLoaderStatus } from '../../../redux/store/loader/loaderActions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

function CircularIndeterminate() {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ margin: '30% 45%' }}>
      <CircularProgress color="secondary" />
    </div>
  );
}

const improvisavionTypeSelectOptions = [
  {
    text: 'Select photo from the advertiser`s profile',
    key: 'PHOTO_SELECT',
  },
  {
    text: "Make post repost from the advertiser's profile",
    key: 'POST_REPOST',
  },
  {
    text: "Improvisation at the advertiser's discretion",
    key: 'DISCRETION',
  },
  {
    text: 'Record a live video on behalf of a blogger',
    key: 'RECORD_A_LIVE_VIDEO',
  },
];
const CampaignsInfo = () => {
  const { id } = useParams();
  const [body, setBody] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(changeGlobalLoaderStatus(true));
    getCampaignsInfo(id)
      .then(e => {
        setBody(e);
        setShowLoader(false);
      })
      .catch(({ message }) => dispatch(errorHandler(message)))
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  }, [id, dispatch]);

  const checkContentType = (slide, index) => {
    const isVideo = slide && (slide.includes('mp4') || slide.includes('mov'));
    if (isVideo)
      return <VideoPlayer width={'350px'} height={'350px'} link={slide} />;
    if (!isVideo)
      return (
        <img
          className="slider-img"
          style={{ objectFit: 'contain' }}
          src={slide}
          alt="screen"
        />
      );

    return null;
  };
  const getValueType = () => {
    return improvisavionTypeSelectOptions.filter(
      ({ key }) => key === body.story.content,
    )[0].text;
  };
  const goBack = () => {
    history.push(`/item/1`);
  };

  return (
    <Container maxWidth="lg"  style={{overflow:'auto'}}>
      <Button onClick={() => goBack()} color="primary" size="large">
        <KeyboardBackspaceIcon /> Return to ADS table
      </Button>
      {showLoader ? (
        <CircularIndeterminate />
      ) : (
        <div className="campaignsInfo-body">
          <Box>
            <h1 className="campaignsInfo-title">{body?.title}</h1>
          </Box>
          <p className="campaignsInfo-title-container">Common parameters</p>
          <div className="campaignsInfo-container-item">
            <p className="campaignsInfo-description">Heading</p> {body?.title}
          </div>

          <div className="campaignsInfo-container-item">
            <p className="campaignsInfo-description">Publishing period</p>
            from {body.startDate
              ? onlyDataFormat(body.startDate)
              : '----'} to{' '}
            {body.endDate ? onlyDataFormat(body.endDate) : '----'}
          </div>
          <div className="campaignsInfo-container-item">
            <p className="campaignsInfo-description">Publishing time</p> from{' '}
            {timeFormat(body.publishTimeFrom)} to{' '}
            {timeFormat(body.publishTimeTo)}
          </div>
          <Box>
            <div className="campaignsInfo-container-item">
              <p className="campaignsInfo-description">Content type </p>{' '}
              {body.story.contentType}
            </div>
            <div className="campaignsInfo-container-item">
              <p className="campaignsInfo-description">Link</p>{' '}
              {body.story.site ? <input value={body.story.site} /> : '----'}
            </div>
            <div className="campaignsInfo-container-item">
              <p className="campaignsInfo-description">Mention</p>{' '}
              {body.story.profile ? body.story.profile : '----'}
            </div>

            {body.story.instruction ? (
              <div className="campaignsInfo-container-item">
                <p className="campaignsInfo-description">instruction</p>{' '}
                {body.story.instruction}
              </div>
            ) : null}
            {body.story.content ? (
              <div className="campaignsInfo-container-item">
                <p className="campaignsInfo-description">Improvisation type</p>
                {getValueType()}
              </div>
            ) : null}
          </Box>

          {body.story.file ? (
            <>
              <p className="campaignsInfo-title-container">
                {' '}
                Аdvertising stories
              </p>
              <Box>
                <div className="campaignsInfo-container-item">
                  <p className="campaignsInfo-description">File </p>
                  <div className="campaignsInfo-container-content">
                    {checkContentType(body.story.file)}
                  </div>
                </div>
              </Box>
            </>
          ) : null}
        </div>
      )}
    </Container>
  );
};
export default CampaignsInfo;
