import React from 'react';
import { Controller } from 'react-hook-form';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const SelectInput = ({
  name,
  label,
  control,
  defaultValue = '',
  optionsList,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || optionsList[0].value}
      rules={{ required: true }}
      render={({ field }) => (
        <FormControl variant="outlined" fullWidth={true}>
          <InputLabel id="demo-simple-select-outlined-label">
            {label}
          </InputLabel>
          <Select
            {...field}
            id="outlined-select"
            variant="outlined"
            label={label}
          >
            {optionsList.map(({ value, name }) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
export default SelectInput;
