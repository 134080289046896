import React, { useEffect } from 'react';

import { TableCell, IconButton, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { TABLE_TYPES } from '../TablePrimary/TablePrimaryHead/config';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const ControlsCell = ({ tableType, handlers, row }) => {
  useEffect(()=>{
    console.log("DATA ")
    console.log(row)
  },[])
  const generateCell = () => {
    switch (tableType) {
      case TABLE_TYPES.ACCOUNTS_VERIFICATION:
        return (
          <>
            <Button
              style={{
                margin: '0 10px 0 0',
                border: 'solid 1px #008000',
                color: '#008000',
              }}
              onClick={() => handlers.handleApproveButton(row._id)}
              variant="outlined"
              color="primary"
              size="small"
            >
              approve
              <AssignmentTurnedInOutlinedIcon
                style={{ margin: '0 0 0 10px' }}
              />
            </Button>
            <Button
              onClick={() => handlers.handleDeclineButton(row._id)}
              variant="outlined"
              color="secondary"
              size="small"
            >
              decline
              <BlockIcon style={{ margin: '0 0 0 10px' }} />
            </Button>
          </>
        );

      case TABLE_TYPES.ADS_REVIEW_LIST:
        return (
          <>
            <Button
              style={{ margin: '0 10px 0 0' }}
              onClick={() => handlers.handleDetailsButton(row._id)}
              variant="outlined"
              color="primary"
              size="small"
            >
              open
              <AssignmentTurnedInOutlinedIcon
                style={{ margin: '0 0 0 10px' }}
              />
            </Button>
          </>
        );
      case TABLE_TYPES.ADS_REVIEW_COMPANY_LIST:
        return (
          <Button
            style={{
              margin: '0 10px 0 0',
              border: 'solid 1px #008000',
              color: '#008000',
            }}
            onClick={() => handlers.handleDetailsButton(row._id)}
            variant="outlined"
            color="primary"
            size="small"
          >
            New
            <AssignmentTurnedInOutlinedIcon style={{ margin: '0 0 0 10px' }} />
          </Button>
        );
      case TABLE_TYPES.CAMPAIGNS_LIST:
        return (
          <>
            <Button
              onClick={() =>
                handlers.handleDetailsButton({
                  type: row.isRejected,
                  id: row._id,
                })
              }
              variant="outlined"
              color="primary"
              size="small"
              style={{
                margin: '0 10px 0 0',
                border: `solid 1px ${row.isRejected ? '#f22' : '#3f51b5'}`,
                color: row.isRejected ? '#f22' : '#3f51b5',
              }}
            >
              {row.isRejected ? 'rejected' : 'open'}
              <AssignmentTurnedInOutlinedIcon
                style={{ margin: '0 0 0 10px' }}
              />
            </Button>
          </>
        );
      case TABLE_TYPES.VERIFICATION_LIST:
        return (
          <>
            <Button
              onClick={() => handlers.handleDetailsButton(row._id)}
              variant="outlined"
              color="primary"
              size="small"
              style={{
                margin: '0 10px 0 0',
                border: `solid 1px ${row.isRejected ? '#f22' : '#008000'}`,
                color: row.isRejected ? '#f22' : '#008000',
              }}
              // variant="contained"
            >
              {row.isRejected ? 'rejected' : 'New'}
              <AssignmentTurnedInOutlinedIcon
                style={{ margin: '0 0 0 10px' }}
              />
            </Button>
          </>
        );
      case TABLE_TYPES.WITHDRAWAL_TABLE:
        return (
          <>
            <Button
              onClick={() => handlers.handleApproveButton(row.id)}
              variant="outlined"
              color="primary"
              size="small"
              style={{
                width: '120.281px',
                margin: '5px 10px 0 0',
                border: 'solid 1px #008000',
                color: '#008000',
              }}
            >
              aprove
              <CheckIcon style={{ margin: '0 0 0 10px' }} />
            </Button>
            <Button
              onClick={() => handlers.handleDeclineButton(row.id)}
              variant="outlined"
              color="primary"
              size="small"
              style={{
                width: '120.281px',
                margin: '5px 10px 0 0',
                border: 'solid 1px #f22',
                color: '#f22',
              }}
            >
              Rejected
              <CloseIcon style={{ margin: '0 0 0 10px' }} />
            </Button>
          </>
        );
      case TABLE_TYPES.STATISTICS_VERIFICATION:
        return (
          <>
            <Button
              onClick={() => handlers.handleStatisticsDetailsButton(row.id)}
              variant="outlined"
              color="primary"
              size="small"
              style={{
                margin: '0 10px 0 0',
                border: 'solid 1px #008000',
                color: '#008000',
              }}
            >
              New
              <EqualizerIcon style={{ margin: '0 0 0 10px' }} />
            </Button>
          </>
        );
      case TABLE_TYPES.ADVERTISERS_TABLE:
        return (
          <>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{
                margin: '0 10px 0 0',
                border: 'solid 1px #008000',
                color: '#008000',
              }}
            >
              Open
              <EqualizerIcon style={{ margin: '0 0 0 10px' }} />
            </Button>
          </>
        );
      case TABLE_TYPES.BLOG_LIST:
        return (
          <>
            <IconButton
              aria-label="delete"
              onClick={() => {
                handlers.handleEditButtonClick(row.id);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="secondary"
              onClick={() => {
                handlers.handleDeletedButtonClick(row.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      case TABLE_TYPES.BLOGGERS_LIST:
        return (
          <>
            <Button
              onClick={() => {console.log("ROW" ); console.log(row.instagramAccounts
                [0]._id); handlers.handleDeleteUser(row.instagramAccounts
                  [0]._id);}}
              variant="outlined"
              color="primary"
              size="small"
              style={{
                margin: '0 10px 0 0',
                border: 'solid 1px #008000',
                color: '#008000',
              }}
            >
              Delete
            </Button>
            <Button
              onClick={() => {console.log("ROW" ); console.log(row.instagramAccounts
                [0]._id); handlers.handleChangeButton(row.instagramAccounts
                  [0]._id);}}
              variant="outlined"
              color="primary"
              size="small"
              style={{
                margin: '0 10px 0 0',
                border: 'solid 1px #008000',
                color: '#008000',
              }}
            >
              Edit
            </Button>
            
          </>
        );
      case TABLE_TYPES.INSTAGRAM_LIST:
        return (
          <>
            <Button
              onClick={() => handlers.handleStatisticsDetailsButton(row.id)}
              variant="outlined"
              color="primary"
              size="small"
              style={{
                margin: '0 10px 0 0',
                border: 'solid 1px #008000',
                color: '#008000',
              }}
            >
              open
            </Button>
          </>
        );
      case TABLE_TYPES.CREATE_MODERATOR:
        return (
          <>
            <Button
              onClick={() => handlers.openItem(row.id)}
              variant="outlined"
              color="primary"
              size="small"
              style={{
                margin: '0 10px 0 0',
                border: 'solid 1px #008000',
                color: '#008000',
              }}
            >
              <AssignmentTurnedInOutlinedIcon style={{ margin: '0 5px 0 0' }} />
              open
            </Button>
            <IconButton
              aria-label="delete"
              onClick={() => {
                handlers.editItem(row.id);
              }}
            >
              <EditIcon />
            </IconButton>

            <Button onClick={() => handlers.removeItem(row.id)}>
              <IconButton aria-label="delete" color="secondary">
                <DeleteIcon />
              </IconButton>
            </Button>
          </>
        );
      case TABLE_TYPES.REFERRALS_TABLE:
        return (
          <>
            <Button
              style={{ margin: '0 10px 0 0' }}
              // onClick={() => handlers.handleDetailsButton(row._id)}
              variant="outlined"
              color="primary"
              size="small"
            >
              open
              <AssignmentTurnedInOutlinedIcon
                style={{ margin: '0 0 0 10px' }}
              />
            </Button>
          </>
        );
      case TABLE_TYPES.CPAOFFER_LIST:
        return (
          <>
            <IconButton aria-label="delete" color="secondary" onClick={() => handlers.handleDeclineButton(row._id)}>
              <DeleteIcon />
            </IconButton>
          </>
        );
      default:
        return (
          <>
            <IconButton aria-label="delete">
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" color="secondary">
              <DeleteIcon />
            </IconButton>
          </>
        );
    }
  };
  return <TableCell align="right">{generateCell()}</TableCell>;
};

export default ControlsCell;
