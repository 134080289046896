import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Box, Container } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Slider from '../../../components/Slider/Slider';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { getReviewItem } from '../../../../redux/store/company/campaignsOperations';
import ContentVerification from '../ContentVerification/ContentVerification';

const CampaignsCurrent = ({ history, classes }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const body = useSelector(state => state.campaigns.item_review);
   useEffect(() => {
    dispatch(getReviewItem(id));
  }, [dispatch, id]);
  return (
    <Container maxWidth='lg'  style={{overflow:'auto'}}>
      <Box marginBottom={2} marginTop={12} display={'flex'} justifyContent={'space-between'} width={'95%'} m={1}>
        <Button
          onClick={() => history.push(`/item/2`)}
          color='primary'
          size='large'
        >
          <KeyboardBackspaceIcon className={classes.buttonIcon} /> Return to
          Tables
        </Button>


      </Box>
      <Box display={'flex'}>
        <Box width={'50%'} marginTop={4}>
          <Slider name={`@${body?.instagram?.userName}`}
                  slides={body.screenshots && Array.isArray(body.screenshots) ? body.screenshots : []} />
        < /Box>
        <Box width={'50%'}>
          <ContentVerification isNotReview={false} statusReview={body.status} />
        </Box>
      </Box>
    </Container>
  );
};

export default withStyles(styles)(CampaignsCurrent);
