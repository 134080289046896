import { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getMessaging, onMessage } from 'firebase/messaging';

import { checkingStatusUser } from '../../redux/store/authentication/authenticationOperations';
import { fetchUserNotifications } from '../../redux/store/notification/notificationOperations';
import { setNotifyStatus } from '../../redux/store/notify/notifyActions';
import { loaderSelector } from '../../redux/selectors/selectors';

import Notifications from '../components/Notification/firebaseNotification';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import GlobalLoader from '../components/GlobalLoader/GlobalLoader';
import AppBar from '../components/AppBar/AppBar';
import Login from '../pages/login/login';
import Routes from '../router/router';

import styles from './styles';

const App = ({ classes }) => {
  const isUserLogin = useSelector(state => state.authentication.loginStatus);
  const { globalLoader } = useSelector(loaderSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkingStatusUser());
  }, [dispatch]);

  const messaging = getMessaging();
  onMessage(messaging, payload => {
    dispatch(fetchUserNotifications());
    dispatch(
      setNotifyStatus({
        type: 'info',
        notification: payload,
      }),
    );
  });
  return (
    <>
      <Notifications />
      {isUserLogin ? (
        <Box className={classes.app}>
          {globalLoader && <GlobalLoader />}
          <AppBar />
          <Box display="flex"  >
            <NavigationBar />
            <Routes />
          </Box>
        </Box>
      ) : (
        <Login />
      )}
    </>
  );
};

export default withStyles(styles)(App);
