const styles = {
  innerContainer: { marginBottom: 10 },
  currentInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '25px',
    marginTop: '25px',
  },
  buttonIcon: {
    marginRight: 10,
  },

  sendButton: {
    marginLeft: 20,
  },
};

export default styles;
