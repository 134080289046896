import { Box, Container, Typography } from '@material-ui/core';

import FormBlog from '../../components/Forms/FormBlog/FormBlog';
import React from 'react';

const BlogCreate = () => {
  return (
    <Container maxWidth="lg"  style={{overflow:'auto'}}>
      <Box p={4}>
        <Typography variant="h4" gutterBottom>
          Create News
        </Typography>
        <FormBlog />
      </Box>
    </Container>
  );
};

export default BlogCreate;
