import types from './loaderTypes';

const initialState = {
  contentLoader: false,
  buttonLoader: false,
  globalLoader: false,
};

const loaderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CHANGE_CONTENT_LOADER_STATUS:
      return {
        ...state,
        contentLoader: payload,
      };
    case types.CHANGE_BUTTON_LOADER_STATUS:
      return {
        ...state,
        buttonLoader: payload,
      };
    case types.CHANGE_GLOBAL_LOADER_STATUS:
      return {
        ...state,
        globalLoader: payload,
      };

    default:
      return state;
  }
};

export default loaderReducer;
