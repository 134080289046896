import React, { useEffect, useState, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import Input from '../../../ui-core/Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress , Select} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import {
  editSchema,
  moderatorSchema,
} from '../../../../schema/moderatorSchema';
import SelectInput from '../../../ui-core/Select/Select';
import { useDispatch, useSelector } from 'react-redux';
import {
  addedNewModerator,
  moderatorEdit,
} from '../../../../redux/store/moderator/moderatorOperation';
import { getUser, registrationModeration } from '../../../../api/createModerator-servise/createModerator-servise';
import { loaderSelector } from '../../../../redux/selectors/selectors';
import { changeGlobalLoaderStatus } from '../../../../redux/store/loader/loaderActions';
import { categories , serviceTypes} from './AprovalData'
import { createFormData , createJSON} from '../../../../utilites/formDataOperations';
import { sendVerificationFormWithData } from '../../../../redux/store/verification/verificationOperations';
import { confirmationKeys } from '../../../../constants/confirmationKeys';
import { changeConfirmStatus } from '../../../../redux/store/confirm/confirmActions';
import { sendChangeFormWithData } from '../../../../api/bloggers-service/bloggers-service';
const AprovalForm = ({ isEdit, id }) => {
  const {
    handleSubmit,
    control,

    formState: { isValid, errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });
  const { buttonLoader } = useSelector(loaderSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const [body, setBody] = useState({});
  const [errorEdit, setErrorEdit] = useState('');
  useEffect(() => {
    if (isEdit) {

    }
  }, []);
  const refFile = useRef();
  console.log(errors);
  const onSubmit = data => {
    if (!isEdit){
      const formData =  createFormData(data);
      if (refFile.current.files[0])
        formData.append(
          "file", refFile.current.files[0],
        );
      dispatch(sendVerificationFormWithData(id, formData, history));
      
      dispatch(
        changeConfirmStatus({
          isVisible: true,
          type: confirmationKeys.verificationAccountVerified,
          actionParams: [id, true],
        }),)
    }else{
      console.log(id)
      const formData =  createFormData(data);
      if (refFile.current.files[0])
        formData.append(
          "file", refFile.current.files[0],
        );
      sendChangeFormWithData(id, formData, history).then((data)=>{
        dispatch(changeConfirmStatus({
          isVisible: true,
          type: confirmationKeys.editBlogger,
          actionParams: [id, true],
        }))
      })
    }

  };
  return (
    <Box width={'400px'}>
      <form onSubmit={handleSubmit(onSubmit)}>
      <Box margin={'10px'}>
          <label>Avatar</label>
        </Box>
        <Box margin={'10px'}>
          <input
            name="avatar"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            ref={refFile}
          />
        </Box>
        <Box margin={'10px'}>
            <SelectInput
              control={control}
              name="category"
              label="Channel category"
              defaultValue={""}
              optionsList={categories}
            />
        </Box>
        <Box margin={'10px'}>
          <Input
            control={control}
            InputLabelProps={
              isEdit
                ? {
                    shrink: true, 
                  }
                : {}
            }
            label="Count of subs"
            type="number"
            name="subscribersCount"
          />
          <p className={'instagram-edit-errors'}>
            {errors.lastName ? errors.lastName.message : ''}
          </p>
        </Box>
        <Box margin={'10px'}>
          <Input
              control={control}
              InputLabelProps={
                isEdit
                  ? {
                      shrink: true, 
                    }
                  : {}
              }
              label="Mean of views"
              type="number"
              name="mean_views"
            />
        </Box>
        <Box margin={'10px'}>
          <Input
              control={control}
              InputLabelProps={
                isEdit
                  ? {
                      shrink: true, 
                    }
                  : {}
              }
              label="Likes/Views"
              type="number"
              name="likes_views"
            />
        </Box>
        <Box margin={'10px'}>
          <Input
              control={control}
              InputLabelProps={
                isEdit
                  ? {
                      shrink: true, 
                    }
                  : {}
              }
              label="Rating(1 to 5)"
              type="number"
              name="rating"
            />
        </Box>
        <Box margin={'10px'}>
          <Input
              control={control}
              InputLabelProps={
                isEdit
                  ? {
                      shrink: true, 
                    }
                  : {}
              }
              label="Comments/Views"
              type="number"
              name="comments_views"
            />
        </Box>
        <Box margin={'10px'}>
          <Input
              control={control}
              InputLabelProps={
                isEdit
                  ? {
                      shrink: true, 
                    }
                  : {}
              }
              label="Price for 1000 views"
              type="number"
              name="price_for_1000"
            />
        </Box>
        <Box margin={'10px'}>
            <SelectInput
              control={control}
              name="serviceType"
              label="Service type"
              defaultValue={""}
              optionsList={serviceTypes}
            />
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          justifyContent={'center'}
          marginTop={'20px'}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            style={{ height: '42px', width: '127.938px' }}
            disabled={!isValid || buttonLoader}
            startIcon={!buttonLoader && <SaveIcon />}
          >
            {buttonLoader ? <CircularProgress size={14} /> : 'Aprove'}
          </Button>
        </Box>

        <p className={'instagram-edit-errors'}>{errorEdit}</p>
      </form>
    </Box>
  );
};

export default AprovalForm;
