import routeBook from '../../constants/routeBook';

import Home from '../pages/home/Home';
import Advertisers from '../pages/advertisers/Advertisers';
import Blog from '../pages/blog/Blog';
import BlogCreate from '../pages/blog/BlogCreate';
import BlogEdit from '../pages/blog/BlogEdit';
import nonFound from '../pages/notFound/notFound';
import Bloggers from '../pages/bloggers/Bloggers';
import Cpaoffers from '../pages/cpaoffers/Cpaoffers';
import Campaigns from '../pages/campaigns/Campaigns';
import ContentVerification from '../pages/campaigns/ContentVerification/ContentVerification';
import AdsReview from '../pages/campaigns/AdsReview/AdsReview';
import AccountStatistics from '../pages/campaigns/AccauntStatistics/AccountStatistics';
import CpaoffersCreate from '../pages/cpaoffers/CpaoffersCreate';
import Withdrawal from '../pages/withdrawal/Withdrawal';
import Verification from '../pages/verification/Verification';
import YoutubeBloggers from '../pages/youtubeBloggers/YoutubeBloggers';
import VerificationCurrent from '../pages/verification/VerificationCurrent';

import Transactions from '../pages/transactions/Transactions';
import CampaignsInfo from '../pages/campaignsInfo/campaignsInfo';
import InstagramEdit from '../pages/instagramEdit/instagramEdit';
import AdvertiserPage from '../pages/advertiserPage/advertiserPage';
import Moderator from '../pages/moderator/moderator';
import ModeratorInfo from '../pages/moderator/moderatorInfo/moderatorInfo';
import { typeRole } from './typeRole';
import Referrals from '../pages/referrals/referrals';

export const publicRoutes = [
  {
    path: `${routeBook.home}`,
    component: Home,
    isExact: true,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `/`,
    component: Home,
    isExact: true,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.cpaoffers}`,
    component: Cpaoffers,
    isExact: true,
    role:[typeRole.admin]
  },
  {
    path: `${routeBook.cpaofferCreate}`,
    component: CpaoffersCreate,
    role:[typeRole.admin]
  },
  {
    path: `${routeBook.bloggers}`,
    component: Bloggers,
    role:[typeRole.moderator,typeRole.admin]
  },
  /*{
    path: `${routeBook.YoutubeBloggers}`,
    component: YoutubeBloggers,
    role:[typeRole.moderator,typeRole.admin]
  },*/
  {
    path: `${routeBook.instagramEdit}`,
    component: InstagramEdit,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.transactions}`,
    component: Transactions,
    role:[typeRole.admin]
  },
  {
    path: `${routeBook.campaigns}`,
    component: Campaigns,
    isExact: true,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.campaignsCurrent}`,
    component: ContentVerification,
    isExact: true,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.adsReview}`,
    component: AdsReview,
    isExact: true,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.campaignsInfo}`,
    component: CampaignsInfo,
    isExact: true,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.accountStatistics}`,
    component: AccountStatistics,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.adsReviewStatistics}`,
    component: AccountStatistics,
    isExact: true,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.verification}`,
    component: Verification,
    isExact: true,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    isExact: true,
    path: `${routeBook.verificationCurrent}`,
    component: VerificationCurrent,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.advertisers}`,
    component: Advertisers,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.withdrawal}`,
    component: Withdrawal,
    role:[typeRole.admin]
  },
  {
    path: `${routeBook.blog}`,
    component: Blog,
    isExact: true,
    role:[typeRole.moderator,typeRole.admin]

  },
  {
    path: `${routeBook.blogCreate}`,
    component: BlogCreate,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.blogEdit}`,
    component: BlogEdit,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.notFound}`,
    component: nonFound,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.advertiser}`,
    component: AdvertiserPage,
    role:[typeRole.moderator,typeRole.admin]
  },
  {
    path: `${routeBook.moderator}`,
    component:Moderator,
    role:[typeRole.admin]
  },
  {
    path: `${routeBook.moderatorInfo}`,
    component:ModeratorInfo,
    role:[typeRole.admin]
  },
  {
    patch:`${routeBook.referralInfoUser}`,
    component:Referrals,
    role:[typeRole.admin]
  }
];

export const privateRoutes = [];
