export const modal = state => state.modal;

export const notifyStatusSelector = state => state.notify;

export const confirmStatusSelector = state => state.confirm;

export const personalInfoSelector = state => state.authentication;

export const userNotificationSelector = state =>
  state.userNotification.userNotifications;

export const loaderSelector = state => state.loader;
