import { get,put, post , postFormData} from '../apiService';

export const getBloggersList = (limit, offset, sort) => {
  return get(`/blogger/verified-blogger/?limit=${limit}&offset=${offset}${sort ? sort : ''}`);
};
export const postInstagramInfo = (id,body) => {
  return put(`/instagram/screens-for-approve/${id}`,body);
};

export const putInstagramInfoBlogger = (id,body) => {
  return put(`/instagram/update-account-by-admin/${id}`,body);
};
export const DeleteAccountByAdmin = (id) => {
  return post(`/instagram/remove-account-by-admin/${id}`);
};
export const sendVerificationFormWithData = (id, body) => {
  return postFormData(`/instagram/verification-with-data/${id}`, body);
};
export const sendChangeFormWithData = (id, body) => {
  return postFormData(`/instagram/change-data/${id}`, body);
};