import { Typography, Box, Container, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import TablePrimary from '../../components/Tables/TablePrimary/TablePrimary';
import React, { useEffect, useState } from 'react';
import { getCpaOffersList, postCpaOffer, deleteCpaOffer } from '../../../api/cpaoffer-service/cpaoffer-service';
import { TABLE_TYPES } from '../../components/Tables/TablePrimary/TablePrimaryHead/config';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withStyles } from '@material-ui/core/styles';
import styles from '../verification/styles';
import { useHistory } from 'react-router-dom';
import { changeGlobalLoaderStatus } from '../../../redux/store/loader/loaderActions';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import routeBook from '../../../constants/routeBook';
import CpaoffersCreate from './CpaoffersCreate';

const Cpaoffers = ({ classes }) => {
  const [instagramList, setInstagramList] = useState();
  const [bloggersList, setBloggersList] = useState({ items: [], count: 0 });
  const dispatch = useDispatch();
  const history = useHistory();
  const deleteCpaOfferHandle = (_id) => {
    return deleteCpaOffer({_id: _id})
  };
  const saveBloggerLost = (limit, offset, sort) => {
    dispatch(changeGlobalLoaderStatus(true));
    getCpaOffersList(limit, offset, sort)
      .then(({ items, count }) => {
        console.log(items)
        const buffer = items.map(
          ({ title, link, price ,technicalDescription,adminProfit, _id}) => {
            return {
              view: {
                title,
                link,
                price,
                adminProfit,
                technicalDescription
                
              },
              _id: _id,
            };
          },
        );
        setBloggersList({ items: buffer, count });
      })
      .catch(() => {
      })
      .finally(() => dispatch(changeGlobalLoaderStatus(false)));
  };
  useEffect(() => {
    saveBloggerLost(10, 0);
  }, []);
  const _pagination = page => {
    saveBloggerLost(10, page * 10);
  };
  const _sort = sort => {
    saveBloggerLost(10, 0, sort);
  };




  const handleDeclineButton = async (id) => {
    console.log(id)
    await deleteCpaOfferHandle(id)
    await saveBloggerLost();
  };
  return (
    <Container maxWidth='lg' style={{ overflow: 'auto', padding: '0' }}>
      <Box p={1}>
        <Box display='flex' justifyContent='space-between'>
          {instagramList ? (
            <Button
              onClick={() => setInstagramList(null)}
              color='primary'
              size='large'
            >
              <KeyboardBackspaceIcon /> Return to bloggers table
            </Button>
          ) : <Typography variant='h4' gutterBottom>
            CPA Offers
          </Typography>}
          <Link className={classes.link} to={routeBook.cpaofferCreate}>
            <Button
              style={{ margin: '0 0 50px 0' }}
              variant='outlined'
              color='primary'
              size='large'
            >
              create cpa
              <AddCircleOutlineOutlinedIcon style={{ margin: '0 0 0 10px' }} />
            </Button>
          </Link>
        </Box>
        {bloggersList  && 
          <TablePrimary
            rows={bloggersList?.items}
            tableType={TABLE_TYPES.CPAOFFER_LIST}
            notShowControl={false}
            sortInfo={_sort}
            handlers={{
              handleDeclineButton: handleDeclineButton,
            }}
          />
        }
      </Box>
    </Container>
  );
};
export default withStyles(styles)(Cpaoffers);
