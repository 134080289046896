import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModalStatus } from '../../../redux/store/modal/modalActions';
import { modal } from '../../../redux/selectors/selectors';
import { Modal } from '@material-ui/core';

const ModalContainer = () => {
  const dispatch = useDispatch();

  const modalState = useSelector(modal);
  const { isVisible, content } = modalState;

  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={isVisible}
      onClose={() => dispatch(setModalStatus(null))}
    >
      <img width="auto" height="600px" src={content} alt="screen" />
    </Modal>
  );
};

export default ModalContainer;
