import React, { useEffect } from 'react';
import { Box, Button, Container, Typography } from '@material-ui/core';
import TablePrimary from '../../components/Tables/TablePrimary/TablePrimary';
import { TABLE_TYPES } from '../../components/Tables/TablePrimary/TablePrimaryHead/config';
import { useDispatch, useSelector } from 'react-redux';
import { getModerators } from '../../../redux/store/moderator/moderatorOperation';
import { useHistory } from 'react-router-dom';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { changeConfirmStatus } from '../../../redux/store/confirm/confirmActions';
import { confirmationKeys } from '../../../constants/confirmationKeys';
import CreteModeratorForm from './fortm/creteModerator';

const Moderator = () => {
  const body = useSelector(state => state.moderator.body);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getModerators(10, 0));
  }, [dispatch]);
  const _pagination = page => {
    dispatch(getModerators(10, page * 10));
  };
  const _sort = sort => {
    dispatch(getModerators(10, 0, sort));
  };
  const _remoteItem = id => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.removeModerator,
        actionParams: { id },
      }),
    );
  };
  const _openItem = e => {
    history.push(`/info-moderator/${e}`);
  };
  const _editItem = id => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.createModerator,
        actionParams: {
          form: () => <CreteModeratorForm isEdit={true} id={id} />,
        },
      }),
    );
  };
  const _createModerator = () => {
    dispatch(
      changeConfirmStatus({
        isVisible: true,
        type: confirmationKeys.createModerator,
        actionParams: { form: () => <CreteModeratorForm isEdit={false} /> },
      }),
    );
  };
  return (
    <Container maxWidth="lg"  style={{overflow:'auto',padding:'0'}}>
      <Box p={1} minWidth={'1000px'}>
        <Typography variant="h4" gutterBottom>
          Moderator
        </Typography>
        <Box>
          <Button
            style={{
              margin: '0 0 50px 0',
              border: 'solid 1px #008000',
              color: '#008000',
            }}
            onClick={_createModerator}
            variant="outlined"
            color="primary"
            size="large"
          >
            Create
            <AssignmentTurnedInOutlinedIcon style={{ margin: '0 0 0 10px' }} />
          </Button>
        </Box>
        <TablePrimary
          rows={body.items}
          tableType={TABLE_TYPES.CREATE_MODERATOR}
          pagination={{
            count: body.count,
            length: 10,
            onChangePage: _pagination,
          }}
          handlers={{
            openItem: _openItem,
            removeItem: _remoteItem,
            editItem: _editItem,
          }}
          sortInfo={_sort}
        />
      </Box>
    </Container>
  );
};
export default Moderator;
