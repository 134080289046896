import types from './verificationTypes';

const initialState = {
  requests: {
    items: [],
    count: 0,
  },
  screensForApprove: {
    items: [],
    count: 0,
  },
  verificationForm: {
    locationCities: { 'city-1': {} },
    locationCountries: { 'country-1': {} },
  },
  verificationFormIsReadyToSend: false,
  verificationScreens: {},
};

const verificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_VERIFICATION_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: payload,
      };

    case types.SEND_VERIFICATION_FORM_SUCCESS:
      return initialState;
    case types.GET_SCREENS_FOR_APPROVE_SUCCESS:
      return {
        ...state,
        screensForApprove: payload,
      };
    case types.GET_SCREENS_FOR_APPROVE_BY_ID_SUCCESS:
      return {
        ...state,
        verificationScreens: payload,
      };
    case types.ADD_DATA_TO_VERIFICATION_FORM_OBJECT:
      return {
        ...state,
        verificationForm: { ...state.verificationForm, ...payload },
      };

    case types.CHANGE_VERIFICATION_FORM_STATUS:
      return {
        ...state,
        verificationFormIsReadyToSend: payload,
      };

    case types.CLEAR_VERIFICATION_FORM_DATA:
      return {
        ...state,
        verificationFormIsReadyToSend:
          initialState.verificationFormIsReadyToSend,
        verificationForm: initialState.verificationForm,
        verificationScreens: initialState.verificationScreens,
      };

    default:
      return state;
  }
};

export default verificationReducer;
