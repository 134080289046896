const routeBook = Object.freeze({
  home: '/',
  advertisers: '/advertisers',
  YoutubeBloggers: "/youtubeBloggers",
  bloggers: '/bloggers',
  instagramEdit: '/instagramEdit/:id',
  advertiser: '/advertiser/:id',
  adsReview: '/review/:id',
  campaignsInfo: '/company-info/:id',
  cpaofferCreate: '/cpaoffer/create/',
  cpaoffers: '/cpaoffer/',
  accountStatistics: '/account-statistics/:id',
  campaigns: '/item/:activeStep',
  campaignsCurrent: '/campaigns-verification/:id',
  adsReviewStatistics: '/advertisements/review/:id/statistics',
  transactions: '/transactions',
  withdrawal: '/withdrawal',
  verification: '/verification',
  verificationCurrent: '/verification/:id',
  blog: '/blog',
  blogCreate: '/blog/create',
  blogEdit: '/blog/:id',
  notFound: '/not-found',
  moderator: '/moderators',
  moderatorInfo: '/info-moderator/:id',
  referralInfoUser: '/referrals-info-user',
});

export default routeBook;
