import * as yup from 'yup';

const errors = {
  required: 'This field is required',
  max: number => `Max value ${number}.`,
  min: number => `Min value ${number}.`,
};

// test fn

const checkForTotalCountLocations = data => {
  const total = Object.entries(data).reduce((acc, current) => {
    return !current[1].notShow?acc + current[1].percent:acc;
  }, 0);

  return total <= 100;
};

const checkForTotalCountCharacteristics = data => {
  const total = Object.entries(data).reduce((acc, current) => {
    return acc + current[1];
  }, 0);

  return total === 100;
};

//Schema

export const overallFormSchema = yup.object().shape({
  audienceReach: yup.number().required(errors.required).min(0, errors.min(0)),
  accountQuality: yup
    .number()
    .required(errors.required)
    .min(0)
    .max(10, errors.max(10)),
  recommendedPrice: yup
    .number()
    .required(errors.required)
    .min(0, errors.min(0)),
  storiesViews: yup.number().required(errors.required).min(0, errors.min(0)),
});

const mapRules = (map, rule) =>
  Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {});

export const locationsFormSchema = yup.object().shape({
  locationCities: yup.lazy(map => {
    return yup
      .object(
        mapRules(
          map,
          yup.object({
            cityName: yup
              .mixed().when('notShow',{
                is:(val)=>{ return !val},
                then: yup.string().required(errors.required)
                  .max(100, errors.max(100))
                  .min(0, errors.min(0)),
              }),

            percent: yup
              .mixed().when('notShow',{
                is:(val)=>{ return !val},
                then: yup.number()
                  .required(errors.required)
                  .max(100, errors.max(100))
                  .min(0, errors.min(0)),
              }),



          }),
        ),
      )
      .test('total amount', ageFemale =>
        checkForTotalCountLocations(ageFemale),
      );
  }),
  locationCountries: yup.lazy(map => {
    return yup
      .object(
        mapRules(
          map,
          yup.object({
            countryName: yup
              .string()
              .required(errors.required)
              .max(100, errors.max(100))
              .min(0, errors.min(0)),
            percent: yup
              .number()
              .required(errors.required)
              .max(100, errors.max(100))
              .min(0, errors.min(0)),
          }),
        ),
      )
      .test('total amount', ageFemale =>
        checkForTotalCountLocations(ageFemale),
      );
  }),
});

export const characteristicsFormSchema = yup.object().shape({
  ageFemale: yup
    .object()
    .shape({
      '13-17': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '18-24': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '25-34': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '35-44': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '45-54': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '55-64': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      65: yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
    })
    .test('total amount', ageFemale =>
      checkForTotalCountCharacteristics(ageFemale),
    ),
  ageMale: yup
    .object()
    .shape({
      '13-17': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '18-24': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '25-34': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '35-44': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '45-54': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '55-64': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      65: yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
    })
    .test('total amount', ageMale =>
      checkForTotalCountCharacteristics(ageMale),
    ),
  all: yup
    .object()
    .shape({
      '13-17': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '18-24': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '25-34': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '35-44': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '45-54': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      '55-64': yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
      65: yup
        .number()
        .required(errors.required)
        .max(100, errors.max(100))
        .min(0, errors.min(0)),
    })
    .test('total amount', all => checkForTotalCountCharacteristics(all)),
  genderGeneral: yup
    .object()
    .shape({
      men: yup.number().required().max(100).min(0),
      women: yup.number().required().max(100).min(0),
    })
    .test('total amount', genderGeneral =>
      checkForTotalCountCharacteristics(genderGeneral),
    ),
});

export const validationFormDataSchema = overallFormSchema
  .concat(locationsFormSchema)
  .concat(characteristicsFormSchema);
