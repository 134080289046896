const styles = {
  title: {
    marginBottom: '50px',
  },
  button: {
    background: 'linear-gradient(90deg, #FF502C 0%, #FF1B71 100%), #FF1B71',
    textDecoration: 'none',
    marginBottom: '20px',
  },
  link: {
    textDecoration: 'none',
  },
  container: {
    padding: '40px',
  },
};

export default styles;
