import types from './cpaofferTypes';

const initialState = {

  cpaList: {
    items: [{
      view: {},
      _id: 1,
    }],
    count: 0,
  },
  cpaItem: null,

};

const cpaofferReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_LIST_CPA:
      return { ...state, cpaList: payload };
    case types.GET_ITEM_CPA:
      return { ...state, cpaItem: payload };
    default:
      return state;
  }
};

export default cpaofferReducer;
