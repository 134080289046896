import { Box, Container, Typography } from '@material-ui/core';

import FormCpaOffer from '../../components/Forms/FormCpaOffer/FormCpaOffer';
import React, { useEffect } from 'react';

const CpaoffersCreate = () => {
    useEffect(()=>{
        console.log("cpa")
    })
  return (
    <Container maxWidth="lg"  style={{overflow:'auto'}}>
      <Box p={4}>
        <Typography variant="h4" gutterBottom>
          Add Cpa Offer
        </Typography>
        <FormCpaOffer />
      </Box>
    </Container>
  );
};

export default CpaoffersCreate;
