export const transformStatisticData = (key, array) => {
  return Object.entries(array).reduce(
    (acc, current) => [
      ...acc,
      {
        gender: key,
        ageCategory: current[0],
        score: current[1],
      },
    ],
    [],
  );
};
