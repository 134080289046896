import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Typography, Toolbar, IconButton, Box } from '@material-ui/core';

import {
  loaderSelector,
  personalInfoSelector,
} from '../../../redux/selectors/selectors';
import {
  getUserInformation,
  logout,
} from '../../../redux/store/authentication/authenticationOperations';

import { compareKeyBySelect } from '../../../helpers/compareKeyBySelect.js';
import { typeOfUserRole } from '../../../constants/typeOfUserRole';
import Notification from '../Notification/Notification';
import routeBook from '../../../constants/routeBook';

import styles from './styles';

const AppBar = ({ classes }) => {
  const { firstName, lastName, role } = useSelector(personalInfoSelector);
  const { globalLoader } = useSelector(loaderSelector);
  const dispatch = useDispatch();

  const _onClickLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    dispatch(getUserInformation());
  }, [dispatch]);

  return (
    <Box
      style={{ zIndex: `${globalLoader ? '10002' : '1'}` }}
      className={classes.root}
    >
      <Box p={1} className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Link className={classes.link} to={routeBook.home}>
            <Typography className={classes.logo} variant="h5">
              Bloggerly
            </Typography>
          </Link>
          <Notification />
          <Box>
            <span className={classes.right}>
              {firstName} {lastName},{' '}
              {role && compareKeyBySelect(typeOfUserRole, role)}
            </span>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              onClick={_onClickLogout}
            >
              <ExitToAppIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(AppBar);
