import types from './notificationTypes';

export const getUserNotificationsStart = () => ({
  type: types.GET_USER_NOTIFICATIONS_START,
});

export const getUserNotificationsSuccess = (data = {}) => ({
  type: types.GET_USER_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const getUserNotificationsError = (error = '') => ({
  type: types.GET_USER_NOTIFICATIONS_ERROR,
  payload: error,
});

export const putUserNotificationIsReadStart = () => ({
  type: types.PUT_USER_NOTIFICATION_IS_READ_START,
});

export const putUserNotificationIsReadSuccess = (data = {}) => ({
  type: types.PUT_USER_NOTIFICATION_IS_READ_SUCCESS,
  payload: data,
});

export const putUserNotificationIsReadError = (error = '') => ({
  type: types.PUT_USER_NOTIFICATION_IS_READ_ERROR,
  payload: error,
});
