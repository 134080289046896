import { Controller } from 'react-hook-form';

import { TextField, InputAdornment } from '@material-ui/core';

const Input = ({
  name,
  label,
  control={},
  defaultValue,
  endAdornment = null,
  type = 'text',
  error = false,
                 InputLabelProps
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          error={error}
          label={label}
          type={type}
          InputLabelProps={InputLabelProps}
          variant="outlined"
          endadornment={
            endAdornment ? (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ) : null
          }
          fullWidth={true}
          helperText={error}
        />
      )}
    />
  );
};
export default Input;
