export const confirmationKeys = {
  verificationAccountDeclined: 'verificationAccountDeclined',
  verificationAccountVerified: 'verificationAccountVerified',
  statisticsAccountDeclined: 'statisticsAccountDeclined',
  infoBlogger:'infoBlogger',
  cancellationOfAdRequest: 'cancellationOfAdRequest',
  AdRequestVerified: 'AdRequestVerified',

  AdsReviewVerified: 'AdsReviewVerified',
  cancellationOfAdReviewVerified: 'cancellationOfAdReviewVerified',

  deleteBlog: 'deleteBlog',
  advertiserBan:'advertiserBan',
  advertiserUnbanned:'advertiserUnbanned',

  approveWithdrawal: 'approveWithdrawal',
  rejectedWithdrawal: 'rejectedWithdrawal',

  removeModerator:'removeModerator',
  createModerator:'createModerator',
  editModerator:'editModerator',
  editBlogger:'editBlogger',
  setPercent: 'setPercent',
};
