import { combineReducers } from 'redux';

import blogReducer from '../store/blog/blogReducer';
import cpaofferReducer from '../store/cpaoffer/cpaofferReducer';
import modalReducer from '../store/modal/modalReducer';
import verificationReducer from '../store/verification/verificationReducer';
import notifyReducer from '../store/notify/notifyReducer';
import confirmReducer from '../store/confirm/confirmReducer';
import campaignsReducer from './company/campaignsReducer';
import authenticationReducer from './authentication/authenticationReducer';
import advertisersReducer from './advertisers/advertisersReducer';
import notificationReducer from './notification/notificationReducer';
import moderatorReducer from './moderator/moderatorReduser';
import loaderReducer from './loader/loaderReducer';
import referralsReducer from './referrals/referralsReducer';

export const rootReducer = combineReducers({
  verification: verificationReducer,
  cpaoffer: cpaofferReducer,
  blog: blogReducer,
  modal: modalReducer,
  notify: notifyReducer,
  confirm: confirmReducer,
  advertisers: advertisersReducer,
  campaigns: campaignsReducer,
  authentication: authenticationReducer,
  userNotification: notificationReducer,
  moderator: moderatorReducer,
  loader: loaderReducer,
  referrals:referralsReducer,
});
